.user-sidelink
	padding: r(30px)
	background-color: #fff
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
	border-radius: 10px
	overflow: hidden
	margin-top: 30px
	margin-bottom: 30px
	@media screen and ( max-width: 1023.67px )
		position: fixed
		width: 100%
		height: 100%
		padding-top: 45px
		left: 0
		bottom: -100%
		opacity: 0
		visibility: hidden
		z-index: 1000
		transition: .3s all ease-in-out
		&.active
			opacity: 1
			visibility: visible
			bottom: 0
	ul
		list-style: none
		margin: 0
		padding: 0
		li
			&.active
				a
					color: $main
					text-decoration: underline
			a
				transition: .3s all ease-in-out
				&:hover
					color: $main

			&+li
				margin-top: 15px
				border-top: 1px solid #eee
				padding-top: 15px
.user-sidebar
	background-color: #fff
	padding: r(30px)
	border-radius: 10px
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
	@media screen and ( max-width: 1023.67px )
		margin-bottom: 20px
	.userinfo
		figure
			display: flex
			align-items: center
			img
				border-radius: 50%
				margin-right: 20px
	.title
		color:
	.user-name
		font-size: r(18px)
		font-weight: bold
		color: $main
// .changepasswordpage
// 	.middle-fullwidth
// 		padding-top: 40px
// 		padding-bottom: 40px
.profile-container
	padding-top: 40px
	padding-bottom: 40px
	.table-wrapper
		overflow: auto
		@media screen and ( max-width: 992px )
			table
				width: 900px
	.posttitle
		color:
		font-size: r(22px)
		@media (max-width: 575.98px)
			font-size: r(20px)
	.addr-detail
		line-height: 1.5
		@media screen and ( max-width: 575px )
			margin-top: 15px
	.postname
		margin-bottom: 20px
		font-size: r(30px) !important
		color: #000
	.account-info
		background-color: #fff
		padding: r(30px)
		padding-top: 20px
		border-radius: 10px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
		margin-bottom: 20px
		.info-heading
			display: flex
			align-items: center
			justify-content: space-between
			margin-bottom: 15px
			.info-detail
				line-height: 1.3
			.posttitle
				color: #000
			a
				color: #000
				border-bottom: 1px solid #000
				transition: .3s all ease-in-out
				&:hover
					color: $main
					border-bottom: 1px solid $main
	.coin-info
		background-color: #fff
		padding: r(30px)
		padding-top: 20px
		border-radius: 10px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
		margin-bottom: 20px
	.cart-history
		background-color: #fff
		padding: r(30px)
		padding-top: 20px
		border-radius: 10px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
		margin-bottom: 20px
		.text-right
			a
				color: #000
				border-bottom: 1px solid #000
				transition: .3s all ease-in-out
				&:hover
					color: $main
					border-bottom: 1px solid $main
	.address-info
		background-color: #fff
		padding: r(30px)
		padding-top: 20px
		border-radius: 10px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
		margin-bottom: 20px
		.name
			font-size: 18px
			font-weight: 500
			text-transform: uppercase
			display: flex
			align-items: center
			&::before
				content: ''
				width: 5px
				height: 5px
				border-radius: 50%
				margin-right: 5px
				background-color: $main
		#ctl00_mainContent_btnUpdate
			background-color: $main
			color: white
			padding: 10px 15px
			border: 0
			border-radius: 10px
			outline: none
		.check-group
			display: flex
			margin-bottom: 10px
			input
				margin-right: 10px
				margin-top: 3px
		.address-form
			.form-group
				margin-bottom: 20px
				label
					font-size: r(18px)
					margin-bottom: 5px
					display: block
					color: $main
				input[type="text"]
					font-size: r(14px)
					background-color: white
					border: 0 !important
					border-bottom: 1px solid #d7d7d7 !important
					padding: 12px 0px!important
					width: 100% !important
					height: auto !important
				select
					font-size: r(14px)
					background-color: white
					border: 0 !important
					border-bottom: 1px solid #d7d7d7 !important
					padding: 12px 0px!important
					width: 100% !important
					height: auto !important
		.info-heading
			display: flex
			align-items: center
			justify-content: space-between
			margin-bottom: 15px
			a
				color: #000
				transition: .3s all ease-in-out
				border-bottom: 1px solid #000
				&:hover
					color: $main
					border-bottom: 1px solid $main
		.address-col
			.address
				position: relative
			.name
				@media screen and ( max-width: 575px )
					font-weight: bold
					font-size: r(18px)
			&+.address-col
				padding-top: 1.25rem
				margin-top: 1.25rem
				border-top: 1px dashed #eee
		.type
			color: white
			background-color: $main
			border-radius: 5px
			display: flex
			align-items: center
			justify-content: center
			padding: 5px 10px
			position: absolute
			top: 0px
			right: 5px
			width: fit-content
			font-size: 12px
		.addr-detail
			ul
				list-style: none
				margin: 0
				padding: 0
		.btn-editaddr
			color: $main
			margin-top: 10px
			display: inline-block
			transition: .3s all ease-in-out
			padding: 4px 10px
			border-radius: 5px
			background-color: $main
			font-size: 14px
			&:hover
				color: $main
				background-color: $main
		.btn-deleteaddr
			color: white
			margin-top: 10px
			display: inline-block
			transition: .3s all ease-in-out
			padding: 4px 10px
			border-radius: 5px
			background-color: #d03535
			font-size: 14px
			margin-left: 5px
	#ctl00_mainContent_pnlProfileProperties
		[class*="col"]
			padding-left: 0
			flex: 0 0 100%
			max-width: 100%
			padding-right: 0
	.account-form
		.form-group
			margin-bottom: 20px
		label
			font-size: r(18px)
			margin-bottom: 5px
			display: block
			color: #000
		input[type="text"], input[type="password"]
			font-size: r(14px)
			background-color: white
			border: 0 !important
			border-bottom: 1px solid #d7d7d7 !important
			padding: 12px 0px !important
			width: 100% !important
			height: auto !important
		input[type="submit"]
			font-size: r(18px)
			color: white
			padding: 8px 14px
			border-radius: 5px
			background-color: $main
			margin-top: 10px
			border: 0
		select
			font-size: r(14px)
			background-color: white
			border: 0 !important
			border-bottom: 1px solid #d7d7d7 !important
			padding: 12px 0px !important
			width: 100% !important
			height: auto !important
	.user-page
		.info-heading
			display: flex
			align-items: center
			justify-content: space-between
			.btn-addaddr
				color: $main-hover
				text-decoration: underline
.user-page
	.order-detail
		margin-bottom: 60px
		.carttable
			width: 100%
		.admin-title
			width: fit-content
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
			border-radius: 10px
			background-color: #fff
			padding: 15px 20px
			.status
				margin-bottom: 5px
				display: flex
				align-items: center
				em
					color: #000
					font-size: r(19px)
					margin: 0 5px
				span
					color: #000
			.text
				margin-bottom: 5px
				strong
					font-size: r(22px)
					color: $main
		.text-st
			color: #000 !important
			font-weight: 600 !important
		.second-row-status
			margin-top: 20px
			.box-address-item
				margin-bottom: 30px
				ul
					li
						margin-top: 5px
						&.name
							margin-bottom: 10px
				.title
					font-size: r(18px)
					margin-bottom: r(15px)
					font-weight: 700
					color: $main
			.col-match-height
				height: 100%
			.col
				@media screen and ( max-width: 767.67px )
					flex: 0 0 100%
					&+.col
						margin-top: 20px
			.item
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
				border-radius: 10px
				background-color: #fff
				padding: 15px 20px
				height: 100%
				.text-st
					margin-top: 5px
					margin-bottom: 5px
					display: block
				ul
					padding-left: 0
					list-style: none
					li
						line-height: 1.3
						.status
							color: #000
							font-weight: bold
						span
							margin-right: 5px
							color: #333
							font-weight: normal
							font-size: r(14px)
		.carttable
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
			border-radius: 10px
			background-color: #fff
			padding: 10px
			tr
				&+tr
					td
						border-top: 1px dashed #d7d7d7
				th
					padding: 10px 15px
					white-space: nowrap
					text-align: center
					&:first-child
						text-align: left
				td
					padding: 15px 0px
					&:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5)
						text-align: center
						white-space: nowrap
					.product-item
						padding-left: 15px
						.image
							a
								display: block
								width: auto
								min-width: 100px
								max-width: 100px
								height: 90px
								img
									max-height: 100%
									max-width: 100%
									object-fit: contain
						.title
							text-align: left !important
							padding-right: 50px
							a
								color: $main
								transition: .3s all ease-in-out
								&:hover
									color: $main
									text-decoration: underline
		.total-table
			margin-left: auto
			margin-top: 20px
			width: 100%
			margin-bottom: 30px
			tr
				td
					padding: 5px 0px
					&:first-child
						text-align: left
						font-weight: bold
						color: $main
					&:last-child
						color: #333
						font-size: r(18px)
				&:last-child
					td
						&:last-child
							color: #ed1c24
							font-size: r(30px)
							font-weight: 700
		.btn-back
			color: white
			background-color: $main
			padding: 11px 18px
			border-radius: 10px
			transition: .3s all ease-in-out
			&:hover
				background-color: $main-hover
		.cart-display
			padding: 0px 0px
			margin-bottom: 10px
			.product-item
				@media screen and ( max-width: 960px )
					flex-direction: row!important
					.image
						margin-right: 20px
			@media screen and ( max-width: 768px )
				.wrapper
					overflow: auto
					&::-webkit-scrollbar
						height: 3px
					&::-webkit-scrollbar-track
						background: #f1f1f1
					&::-webkit-scrollbar-thumb
						background: $main
				.carttable
					width: 800px
					td
						padding: 0 15px
.table-admin-stripe
	width: 100%
	margin-top: 20px
	tr
		td
			padding: r(20px) r(10px)
			border: 1px solid #dbdbdb
			border-width: 0 0 1px
			font-size: r(16px)
			&:nth-child(3),&:nth-child(4)
				white-space: nowrap
				// width: 40%
			&:first-child
				a
					font-weight: bold
					color: $main
					transition: .3s all ease-in-out
					&:hover
						color: $main
			.status
				color: white
				border-radius: 10px
				padding: 2px 10px
				display: inline-block
				background-color: $main
			.text
				// overflow: auto
				// white-space: nowrap
				label
					display: inline
.button-account-profile
	display: none
	@media screen and ( max-width: 1023.67px )
		z-index: 1001
		position: fixed
		bottom: 66px
		left: 0
		border-radius: 15px 15px 0 0
		width: 100%
		height: 42px
		display: flex
		align-items: center
		justify-content: space-between
		padding: 0 20px
		background-color: #000
		&.active
			em
				transform: rotate(135deg)
		.title-main
			color: $main
			font-size: 1rem
			font-weight: 700
			text-align: center
		em
			color: $main
			font-size: 24px
			transition: .3s all ease-in-out
.purchase-history-page
	.pagination
		margin-top: 20px
	.postname
		margin-bottom: 20px
		font-size: r(36px)
	.middle-fullwidth
		padding-top: 40px
		padding-bottom: 40px
	.table-wrapper
		overflow: auto
	.cart-history
		margin-bottom: 20px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
		border-radius: 10px
		background-color: #fff
		padding: 20px 1.875rem 1.875rem
		.account-order
			overflow: auto
		table
			width: 100%
			@media screen and ( max-width: 575px )
				width: 750px
			thead
				th
					font-weight: 700
					padding: .75rem .75rem
					border-bottom: 1px solid #dbdbdb
			tbody
				tr
					td
						border: 1px solid #dbdbdb
						border-width: 0 0 1px
						padding: .75rem .75rem
	main
		background-color: rgb(248, 248, 248)
.wishlist-page
	.cart-product-item
		display: flex
		padding: 25px 0px
		border-bottom: 1px dashed #d7d7d7
		@media screen and ( max-width: 575px )
			flex-wrap: wrap
		.cart-product-item-img
			flex: 0 0 120px
			max-width: 120px
			a
				display: flex
				align-items: center
				justify-content: center
				width: 100%
				height: 120px
				img
					max-width: 100%
					max-height: 100%
					object-fit: contain
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
				max-width: 100%
		.cart-product-item-content
			flex: 1
			max-width: 100%
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
				max-width: 100%
			.cart-product-item-name
				font-size: r(18px)
				transition: .3s all ease-in-out
				padding-top: 10px
				@media screen and ( max-width: 992px )
					font-size: r(20px)
				&:hover
					color: $main
	.cart-product-item-detail
		display: flex
		padding-left: 15px
		@media screen and ( max-width: 992px )
			flex-direction: column
			align-items: flex-start
			padding-top: 15px
		.cart-product-item-price
			display: flex
			flex-wrap: wrap
			justify-content: flex-end
			align-items: baseline
			@media screen and ( max-width: 992px )
				margin-top: 10px
				justify-content: flex-start
			p
				font-size: r(18px)
				.ri-delete-bin-line
					font-size: 18px
				&:nth-child(1)
					font-size: 1.2rem
					color: $main
					margin-right: 5px
				&:nth-child(2)
					color: #ed1c24
					&::before
						content: '|'
						padding-left: 5px
						padding-right: 5px
					span
						display: none
				&:last-child
					flex: 0 0 100%
					@media screen and ( max-width: 992px )
						display: flex
						justify-content: flex-start
						margin-top: 10px
						a
							span
								font-size: 18px
	.postname
		margin-bottom: 20px
		font-size: r(36px)
	main
		background-color: rgb(248, 248, 248)
	.middle-fullwidth
		padding-top: 40px
		padding-bottom: 40px
	.cart-history
		margin-bottom: 20px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03)
		border-radius: 10px
		background-color: #fff
		padding: 20px 1.875rem 1.875rem
	.card-title
		display: flex!important
		a
			padding-top: 1px
			margin-right: 5px
			display: none
		.posttitle
			color: #000
			font-size: 1.125rem
			font-weight: 400
.user-page
	.address-info
		margin-bottom: 20px
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09)
		border-radius: 10px
		padding: 20px 30px 30px 30px
		margin-top: 10px
		margin-bottom: 30px
		background-color: #fff
	.check-group
		display: flex
		align-items: center
		label
			margin-left: 15px
			margin-bottom: 0px !important
	.address-form
		.form-group
			margin-bottom: 20px
			label
				font-size: r(18px)
				display: block
				color: #333
			input[type="text"]
				font-size: r(14px)
				background-color: white
				border: 0 !important
				border-bottom: 1px solid #d7d7d7 !important
				padding: 12px 0px!important
				width: 100% !important
				height: auto !important
			select
				font-size: r(14px)
				background-color: white
				border: 0 !important
				border-bottom: 1px solid #d7d7d7 !important
				padding: 12px 0px!important
				width: 100% !important
				height: auto !important
	input[type="submit"]
		font-size: r(18px)
		color: white
		padding: 8px 14px
		border-radius: 5px
		background-color: $main
		margin-top: 10px
		border: 0
.user-sidemenu
	padding-top: 40px
