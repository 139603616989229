.page-lien-he
	.box-contact-main
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.05)
		border-radius: 8px
		background-color: #ffffff
	.box-contact-right
		padding-top: r(38px)
		padding-right: r(30px)
		@media (max-width: 1279.98px)
			padding-top: r(15px)
		@media (max-width: 1023.98px)
			padding: r(30px)
		.title
			margin-bottom: r(20px)
		.wrap-form
			.form-group
				margin-bottom: r(20px)
				position: relative
				&.qparagraph
					span.fa-exclamation-triangle
						right: 15px
				span.fa-exclamation-triangle
					position: absolute
					top: 13px
					right: 30px
					color: #c92525
					margin-top: 5px
					animation: AlertError 0.4s 1 linear!important
				input[type="text"],input[type="email"],textarea
					&:hover, &:focus
						border: 1px solid $main
				input[type="text"],input[type="email"]
					width: 100%
					height: 50px
					border-radius: 8px
					border: 1px solid rgba(227, 236, 240, 0.5)
					transition: .3s all ease-in-out
					background-color: $grey-2
					color: $grey
					padding-left: 20px
					font-size: 14px
				textarea
					width: 100%
					height: 180px
					border-radius: 8px
					border: 1px solid rgba(227, 236, 240, 0.5)
					transition: .3s all ease-in-out
					background-color: $grey-2
					color: #666666
					font-size: 14px
					padding-top: 12px
					padding-left: 20px
			.label
				display: none
			.frm-btnwrap
				@media (min-width: 576px)
					float: right
			.clear
				&::after
					content: ""
					clear: both
					display: table
		.frm-btnwrap .frm-btn input
			@media (max-width: 1279.98px)
				padding-right: 40px
				width: 110px
				padding-top: 2px
	.box-contact-left
		background-image: url('../img/contact/contact-img.png')
		border-radius: 8px 0px 0px 8px
		background-color: $main
		background-repeat: no-repeat
		padding: r(60px) r(50px) r(90px)
		background-size: cover
		@media (max-width: 1279.98px)
			padding: r(30px)
		@media (max-width: 1023.98px)
			padding: r(40px) r(20px)
			border-radius: 8px
		.title
			color: #ffffff
			font-size: r(24px)
			font-weight: 700
			text-transform: uppercase
			margin-bottom: r(20px)
			line-height: 1.4
		.list-address
			li
				display: flex
				align-items: center
				&+li
					margin-top: r(27px)
				.icon
					width: 60px
					flex-shrink: 0
					height: 60px
					background-color: #ffffff
					display: flex
					align-items: center
					justify-content: center
					border-radius: 100%
				.text
					margin-left: 30px
					font-size: 14px
					strong
						text-transform: uppercase
	&.iframe-map
		padding-bottom: r(90px)
		.map
			+img-ratio(793/1410, contain)



