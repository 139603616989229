.loginpage
	background-color: #f5f5f5
	.login-box
		padding-top: 5%
		flex-direction: column
		display: flex
		justify-content: center
	#ctl00_mainContent_login1_LoginCtrl_lnkRegisterExtraLink
		display: none
	.login-logo
		text-align: center
		color: $main
		font-size: r(32px)
		margin-bottom: r(30px)
		font-weight: bold
		text-align: center
		text-transform: uppercase
	.input-group-append
		display: none
	.login-card-body
		max-width: 550px
		background-color: #fff
		padding: r(30px)
		margin: 0 auto
		.input-group
			margin-top: 15px
			input
				outline: none
				border: 2px solid $main
				background-color: #f1f1f1
				padding-left: 20px
				width: 100%
				height: 50px
		.social-login
			display: none
		.icheck-primary
			display: flex
			align-items: center
			padding: 15px 0px
			input
				margin-right: 5px
		input[type="submit"]
			width: 100%
			height: 50px
			text-align: center
			margin-top: r(10px)
			background-color: $main
			color: white
			outline: none
			border: 2px solid transparent
			font-weight: bold
			transition: .3s all ease-in-out
			&:hover
				border: 2px solid $main
				background-color: white
				color: $main
	.flex
		display: flex
	.justify-between
		justify-content: space-between
	.mt-3
		margin-top: r(15px)
	.forget-box
		display: flex
		justify-content: space-between
		margin-top: 5px
		a
			transition: .3s all ease-in-out
			&:hover
				color: $main
				text-decoration: underline
	a
		transition: .3s all ease-in-out
		&:hover
			color: $main
			text-decoration: underline
	.alert-danger
		color: $main
		display: block
		margin-top: 15px
	.cart-login
		display: none
