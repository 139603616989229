.wrap-tab-1
	overflow: auto
	&.spacing-expand
		ul
			@media (min-width: 1024px)
				gap: r(50px)
	ul
		+type-none
		display: flex
		align-items: center
		gap: 30px
		@media (max-width: 1023.98px)
			gap: 15px
		li
			&:first-child
				margin-left: auto
			&:last-child
				margin-right: auto
			&.active
				a
					color: $main
					text-decoration: underline
			a
				color: #666666
				font-size: r(14px)
				transition: .3s all ease-in-out
				white-space: nowrap
				&:hover
					color: $main
					text-decoration: underline
.pagination
	+type-none
	display: flex
	align-items: center
	justify-content: center
	gap: 10px
	margin-bottom: r(80px)
	margin-top: r(30px)
	a,span
		width: 40px
		height: 40px
		display: flex
		align-items: center
		justify-content: center
		background-color: #edf6f1
		transition: .3s all ease-in-out
		color: $text
		font-size: r(14px)
		font-weight: 700
		border-radius: 50%
		line-height: 1
		&:hover
			color: white
			background-color: $main
.input-amount
	display: flex
	align-items: center
	[class*='btn-']
		width: 50px
		height: 50px
		background-color: #edf6f1
		display: flex
		align-items: center
		justify-content: center
		transition: .3s all ease-in-out
		&:hover
			background-color: #dadada
	.btn-dec
		display: flex
		align-items: center
		justify-content: center
		border-radius: 4px 0px 0px 4px
		&::before
			content: ''
			background-image: url(../img/icon/subtract.png)
			width: 14px
			height: 2px
	.btn-inc
		display: flex
		align-items: center
		justify-content: center
		border-radius: 0px 4px 4px 0px
		&::before
			content: ''
			background-image: url(../img/icon/plus.png)
			width: 14px
			height: 14px
	.amount
		height: 50px
		width: 82px
		text-align: center
		border: 0px
		border: 1px solid #edf6f1
		background-color: #f5f5f5
		color: $text
		font-size: r(18px)
		text-transform: uppercase
.wrab-tab-2
	overflow: auto
	ul
		+type-none
		display: flex
		align-items: center
	li
		&:first-child
			a
				border-radius: 4px 0px 0px 4px
		&:last-child
			a
				border-radius: 0px 4px 4px 0px
		&.active
			a
				background-color: $main
				color: white
		a
			color: $text
			font-size: r(14px)
			text-transform: uppercase
			height: 50px
			background-color: #edf6f1
			display: flex
			align-items: center
			justify-content: center
			padding: 0 r(28px)
			white-space: nowrap
.box-navigation
	@media (max-width: 1023.98px)
		position: fixed
		top: 71px
		left: 0
		width: 100%
		height: 100vh
		background-color: #fff
		z-index: 99
		transform: translateY(calc(100% - 111px))
		transition: .3s all ease-in-out
		@media (max-width: 575.98px)
			top: 61px
		&.active
			transform: none
			.title
				em
					transform: rotate(180deg)
	.title
		color: #ffffff
		font-size: r(20px)
		font-weight: 700
		height: 60px
		background-color: $main
		padding: 0 30px
		text-transform: uppercase
		display: flex
		align-items: center
		justify-content: space-between
		border-radius: 8px 8px 0px 0px
		@media (max-width: 1279.98px)
			height: 50px
			padding: 0 15px
		@media (max-width: 1023.98px)
			height: 50px
			border-radius: 0
			padding: 0 15px
		em
			transition: .3s all ease-in-out
			@media (min-width: 1024px)
				display: none
	.list-link
		border-radius: 8px
		border: 1px solid $grey-1
		+type-none
		padding: 0 30px
		@media (max-width: 1279.98px)
			padding: 0 r(15px)
		li
			&.active > a
				color: $blue-1
				em
					color: $blue-1
		> li
			border-bottom: 1px solid $grey-1
			&.dropdown
				&.active
					> a
						color: $blue-1
						em
							transform: rotate(90deg)
			&:last-child
				border: 0px
			a
				color: $text
				font-size: r(14px)
				font-weight: 500
				display: flex
				align-items: center
				padding: 13px 0px
				justify-content: space-between
				transition: .3s all ease-in-out
				em
					transition: .3s all  ease-in-out
				&:hover
					color: $blue-1
			.list-menu-dropdown
				padding-bottom: 11px
				display: none
				transform: translateY(-5px)
				ul
					+type-none
					padding-left: 18px
					li
						&+li
							margin-top: 8px
						a
							padding: 0px 0px
							border: 0
#ctl00_divAltContent2
	display: none
.input-hover-focus
	border: 1px solid transparent !important
	transition: .3s all ease-in-out
	&:hover,&:focus
		border: 1px solid $main !important
.Module-1344 > div
	height: 100%
	.wrap-cart
		height: 100%
.fb_iframe_widget
	> span
		width: 130px !important
		> iframe
			width: 130px !important
.modalBuy
	border-radius: 10px
	width: 100%
	background-color: #fff !important
	max-width: 600px!important
	overflow: visible!important
	padding: 20px !important
	.img
		margin: -65px auto 0
		max-width: 80px
	.title
		padding: 15px 0
		color: $main
		font-size: 1.5625rem
		text-align: center
		font-weight: 600
		text-transform: uppercase
		padding-bottom: 0
		@media (max-width: 575.98px)
			font-size: 1.25rem
	.fancybox-close-small
		display: none
.buy-contact
	font-size: r(30px)
	color: $main
	font-weight: 600 