.search-product-wrapper

	.searchsummary
		font-size: r(24px)
		margin-bottom: 25px
		.searchduration
			display: none
		strong
			&:nth-child(2)
				&::before
					content: '"'
				&::after
					content: '"'
	.product-item-main
		margin-bottom: 50px
	[class*='col']
		margin-bottom: r(30px)
.noresults
	img
		margin: 0 auto
		display: block
		margin-bottom: 30px
	strong
		color: #846b5b
		font-size: r(23px)
		font-weight: 500
		margin-top: r(30px)
		text-align: center
		display: block
	p
		opacity: 0.5
		color: #797070
		font-size: r(17px)
		font-weight: 300
		text-align: center
		margin-top: r(10px)
.productsearchresults
	main
		min-height: 90vh
		background-color: #f4f4f2
		@media (max-width: 1279.98px)
			min-height: 75vh
		@media (max-width: 767.98px)
			min-height: 60vh