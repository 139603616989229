.suggestsearch
	position: absolute
	top: 126%
	left: 0
	width: 100%
	height: 485px
	z-index: 8
	overflow-y: auto
	background: $white
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1)
	@media (max-width: 1023.98px)
		top: 100%
		height: 50vh
	@media (max-width: 575.98px)
		position: fixed
		top: 61px
		left: 0
		height: 325px
		width: 100vw
	.title-search-product,.noresults
		padding: 15px 30px
		border-bottom: 1px solid $grey-1
.item-product-search
	display: flex
	border-bottom: 1px solid $grey-1
	padding: 10px 30px
	.item-img
		flex: 0 0 60px
		a
			+img-ratio(1,contain)
	.item-content
		padding-left: r(20px)
		h3
			font-weight: bold
			font-size: r(18px)
		.wrap-price-cart
			font-size: 14px
			margin-top: r(5px)
			.sale
				color: $main
				margin-right: 15px