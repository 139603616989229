.banner-home
	position: relative
	.wrap-button-slide
		position: absolute
		top: 50%
		left: 50%
		z-index: 99
		transform: translate(-50%,-50%)
		display: flex
		align-items: center
		width: 100%
		justify-content: space-between
		margin-top: 0 !important
	.container
		@media (max-width: 1023.98px)
			max-width: 95%
			padding-left: 0
			padding-right: 0