.checkout-wrapper
	padding: 0
	box-shadow: unset !important
	padding-bottom: 0
	@media (max-width: 575.98px)
		padding: 0
		box-shadow: none !important
	.wrap-button-next-cart
		display: flex
		align-items: center
		.btn-shopping
			max-width: 130px
		.btn-checkout
			max-width: 300px
		@media (max-width: 1023.98px)
			margin-top: 20px
			flex-wrap: wrap
			display: none
			.btn
				max-width: 100%
				width: 100%
				margin-left: 0
				margin-bottom: 15px
	.content-wrapper
		padding: 0 !important
		@media (max-width: 1023.98px)
			padding: 0px !important
		@media (max-width: 575.98px)
			padding: 0 !important
			margin-top: 15px
	.checkout-title
		padding-left: 0px
		padding-right: 0px
		color: #000
		font-size: r(24px)
		font-weight: 400
		text-transform: uppercase
		padding-bottom: 5px
		font-weight: 600
		@media (max-width: 575.98px)
			font-size: r(18px)
	.cart-table-wrapper
		// border: 1px solid #e1e1e1
		background-color: #fff
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03)
		border-radius: 10px
		padding: 0px 15px
		@media (max-width: 575.98px)
			background-color: transparent !important
			box-shadow: unset !important
			padding: 0 0px
		.carttable
			thead
				@media screen and ( max-width: 1023.67px )
					display: none
				tr
					th
						height: 40px
						color:
						font-size: r(16px)
						font-weight: 400
						color: #a2a2a2
						border-bottom: 1px solid $grey-1
						&+th
							text-align: center
						&:first-child
							padding-left: 40px
			tbody
				tr
					& + tr
						border-top: 1px solid #dad6cf
						border-bottom: 0 !important
					@media screen and ( max-width: 1023.67px )
						flex-direction: column
						display: flex
						padding-bottom: 10px
					td
						text-align: center
						@media screen and ( max-width: 1023.67px )
							display: flex
							justify-content: space-between
							align-items: center
						&:nth-child(2),&:nth-child(3),&:nth-child(4)
							@media screen and ( max-width: 1023.67px )
								padding: 5px 5px
						&:first-child
							text-align: left
							width: 40%
							@media screen and ( max-width: 1023.67px )
								width: 100%
						&:nth-child(2)
							width: 24%
							@media screen and ( max-width: 1023.67px )
								width: 100%
						&:nth-child(3)
							width: 14%
							@media screen and ( max-width: 1023.67px )
								width: 100%
						&:last-child
							width: 16.5%
							padding: 0 15px
							@media screen and ( max-width: 1023.67px )
								padding: 0 5px
								width: 100%
			.product-item
				@media screen and ( max-width: 1023.67px )
					width: 100%
				.title
					a
						color: #000
						font-size: r(15px)
						font-weight: 400
						line-height: 1.1
						&:hover
							color: $main
							text-decoration: underline
						@media (max-width: 575.98px)
							font-size: .9rem
							padding-right: 15px
				.image
					@media (max-width: 575.98px)
						margin-top: 0px
					a
						display: flex
						padding: 10px
						width: 140px
						height: 100px
						align-items: center
						justify-content: center
						@media (max-width: 1279.98px)
							width: 90px
						@media (max-width: 575.98px)
							width: 80px
							height: auto
						img
							max-width: 100%
							max-height: 100%
							object-fit: contain
				.remove-item
					display: flex
					align-items: center
					cursor: pointer
					@media screen and ( max-width: 1024.67px )
						align-items: center
					@media (max-width: 1023.98px)
						right: 6px
						position: static
					em
						color: $main-hover
						font-size: 14px
						margin-right: 0 !important
					span
						color: #999
						font-size: 12px
						padding-top: 2px
						margin-left: 5px
						&:hover
							color: $main-hover
			.discount-wrap
				@media screen and ( max-width: 1023.67px )
					display: flex
					justify-content: flex-start
					align-items: center
				*
					display: block
				.now-price
					color: #000000
					font-size: r(18px)
					font-weight: 700
				.old-price
					color: $main
					font-size: r(12px)
					font-weight: 400
					text-decoration: line-through
					@media screen and ( max-width: 1023.67px )
						margin-left: 25px
						position: relative
						padding-right: 0
						&::before
							background-color: #999
							width: 6px
							height: .3px
							content: ""
							position: absolute
							top: 50%
							transform: translateY(-50%)
							left: -15px
			.quantity-wrap
				.input-amount
					display: flex
					align-items: center
					width: fit-content
					input
						width: 50px
						text-align: center
						border: 0
						height: 40px
						border-top: 1px solid #d1d1d1
						border-bottom: 1px solid #d1d1d1
						background-color: $white
						// Style for "1"
						color: #000
						font-size: 14px
						font-weight: 400
						letter-spacing: 1.4px
						@media (max-width: 575.98px)
							width: 45px
							height: 30px
					.btn-dec
						border-radius: 4px 0px 0px 4px
					.btn-inc
						border-radius: 0px 4px 4px 0px
					.btn-dec, .btn-inc
						display: flex
						align-items: center
						justify-content: center
						width: 40px
						height: 40px
						border: 1px solid #d1d1d1
						background-color: white
						transition: .3s all ease-in-out
						@media (max-width: 575.98px)
							width: 30px
							height: 30px
						&:hover
							background-color: #d9d8d8
			.total
				color: #000
				font-size: 1.125rem
				white-space: nowrap
				font-weight: 700
				@media (max-width: 1023.98px)
					font-size: 1.2rem
					color: $main
					font-weight: bold
	.total-money
		color: $main-hover
	.cart-fixed-mobile
		position: fixed
		bottom: 0
		z-index: 99
		left: 0
		box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.04)
		background-color: #fff
		padding: 15px
		width: 100%
		.total
			color: $main
			font-size: r(22px)
			font-weight: 700
			margin-bottom: r(10px)
		.wrap-button-buy
			display: grid
			grid-template-columns: 1fr 2fr
			grid-template-rows: 1fr
			grid-gap: 10px
			.btn-shopping
				background-color: $grey-2
				color: #95907a
				text-transform: uppercase
				display: flex
				align-items: center
				justify-content: center
				height: 40px
				margin-left: 0
				font-weight: bold
				max-width: unset
			.btn-checkout
				background-color: $main
				color: white
				text-transform: uppercase
				display: flex
				align-items: center
				justify-content: center
				height: 40px
				margin-left: 0
				text-transform: uppercase
				font-weight: bold
				max-width: unset
		@media (min-width: 1024px)
			display: none
	.wrap-total-cart
		width: 100%
		@media (max-width: 1023.98px)
			order: 1
		table
			width: 100%
	.wrap-discount-button
		align-items: baseline
		background-color: #fff
		border-radius: 10px
		padding: 15px !important
		flex-wrap: wrap
		@media (max-width: 1023.98px)
			margin-top: r(15px)
		@media (max-width: 575.98px)
			padding: 0px 0 0 0 !important
		.checkout-discount
			margin-top: 0
			margin-bottom: 0
			width: 100%
			@media (max-width: 1023.98px)
				order: 2
		.price-wrapper
			margin-bottom: 15px
			@media (max-width: 1023.98px)
				margin-bottom: 0
			table
				@media screen and ( max-width: 1023.67px )
					width: 100%
				tr
					&:last-child
						@media (max-width: 1023.98px)
							display: none
					td
						padding: 5px 0px
						&:first-child
							color: #000
							font-size: 1rem
							font-weight: 700
							padding-right: 92px
							padding-top: 4px
							@media (max-width: 575.98px)
								font-size: 1.1rem
						&:last-child
							color: #000
							font-size: 1.1rem
							font-weight: 700
							text-align: right
							@media (max-width: 575.98px)
								font-size: 1.1rem
	.mobile-title
		display: none
		@media screen and ( max-width: 1023.67px )
			font-weight: 700
			display: inline-block
.checkout-title
	margin-bottom: r(20px)
	@media (max-width: 1023.98px)
		margin-bottom: r(15px)
	span
		text-align: center
		@media (max-width: 767.98px)
			font-size: 1rem
.cart-display
	.cart-button-checkout
		margin-top: 15px !important
	@media (max-width: 1023.98px)
		padding: r(30px) 0px !important
	.product-item
		@media (max-width: 1023.98px)
			flex-direction: row !important
.number-quantity
	font-size: 1.5rem !important
.cartpage
	background-color: #f1f1f1
	@media (max-width: 575.98px)
		background-color: #fff