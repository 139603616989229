.page-faq-main
	.content-right
		h1
			color: $text
			font-size: r(36px)
			font-weight: 400
			margin-bottom: r(25px)
			@media (max-width: 1023.98px)
				font-size: r(30px)
			@media (max-width: 575.98px)
				font-size: r(26px)
		.list-faq
			+type-none
			margin-top: r(30px)
			li
				&:first-child
					.title
						border-top: 1px solid $grey-1
				.title
					display: flex
					align-items: center
					justify-content: space-between
					border-bottom: 1px solid $grey-1
					padding: 12.5px 0px
					cursor: pointer
					&.active
						border-bottom: 1px solid $main
						em
							transform: rotate(180deg)
					&:hover
						span
							text-shadow: 0 0 .95px $main, 0 0 .95px $main
							color: $main
						em
							color: $main
					span
						color: $text
						font-size: 1rem
						transition: .3s all ease-in-out
					em
						font-size: r(26px)
						transition: .3s all  ease-in-out
				.content
					display: none
					padding: r(15px) r(50px) r(15px) r(30px)
					background-color: rgba(227, 236, 240, 0.5)
					*
						color: #5e5e5e
