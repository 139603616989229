.w-100
	width: 100%
.mx-auto
	margin: 0 auto
.ml-auto
	margin-left: auto
.mr-auto
	margin-right: auto
.d-flex
	display: flex
.flcc
	display: flex
	flex-direction: column
.flc
	flex-direction: column
.flex-row
	flex-direction: row
.flex-wrap
	flex-wrap: wrap
.jcb
	justify-content: space-between
.jcc
	justify-content: center
.ovh
	overflow: hidden
.jcs
	justify-content: flex-start
.jce
	justify-content: flex-end
.aic
	align-items: center
.relative
	position: relative
.ais
	align-items: flex-start
.aie
	align-items: flex-end
.tcc
	text-align: center
.tcr
	text-align: right
.flex-wrap
	flex-wrap: wrap
.button-prev, .button-next
	cursor: pointer
.line-bottom
	border-bottom: 1px solid rgba($main, 0.28)
.text-uppercase
	text-transform: uppercase
.text-capitalize
	text-transform: capitalize
.text-center
	text-align: center
.color-white
	color: white
.color-gray
	color: #999999
.color-text
	color: #333333
.color-main
	color: $main
.f-12
	font-size: 12/16*1rem
.f-14
	font-size: 14/16*1rem
.f-16
	font-size: 1rem
.f-18
	font-size: 18/16*1rem
.f-20
	font-size: 20/16*1rem
.f-22
	font-size: 22/16*1rem
.f-24
	font-size: 24/16*1rem
.f-25
	font-size: 25/16*1rem
.f-28
	font-size: 28/16*1rem
.f-30
	font-size: 30/16*1rem
.f-36
	font-size: 36/16*1rem
.f-38
	font-size: 38/16*1rem
.f-40
	font-size: 40/16*1rem
.f-60
	font-size: 60/16*1rem
.fw-300
	font-weight: 300
.fw-400
	font-weight: 400
.fw-500
	font-weight: 500
.fw-600
	font-weight: 600
.fw-700
	font-weight: 700
.fw-800
	font-weight: 800
.fw-900
	font-weight: 900
.line-30
	line-height: 30px
.p-0
	padding: 0
.pl-0
	padding-left: 0
.pt-1
	padding-top: 1rem
.pt-2
	padding-top: 2rem
.pt-3
	padding-top: 3rem
.pr-0
	padding-right: 0
.m-0
	margin: 0
.mt-5
	margin-top: r(5px)
.mt-10
	margin-top: r(10px)
.mt-20
	margin-top: r(20px)
.mt-30
	margin-top: r(30px)
.mb-40
	margin-bottom: r(40px)
.mb-30
	margin-bottom: r(30px)
.mb-20
	margin-bottom: r(20px)
.text-left
	text-align: left
.transition
	transition: all 1s cubic-bezier(.22, .44, 0, 1)
.type-none
	+type-none()
.mh-100
	min-height: 100vh
.ovh
	overflow: hidden
.tab-overlow
	display: flex
	flex-wrap: nowrap
	white-space: nowrap
	overflow-x: auto
	+type-none
	li
		&:first-child
			margin-left: auto
		&:last-child
			margin-right: auto
.border-gray
	border: 1px solid $grey-1
.background-grey
	background-color: $grey-2