.page-discount-main
	.item-discount
		margin-bottom: r(60px)
		@media (max-width: 1023.98px)
			margin-bottom: r(30px)
		.img
			border-radius: 8px 8px 0px 0px
			@media (min-width: 1024px)
				border-radius: 8px 0px 0px 8px
			a
				+img-ratio(500/700)
		.content
			padding: r(40px) r(46px) r(50px)
			display: flex
			height: 100%
			flex-direction: column
			box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
			border-radius: 0px 0px 8px 8px
			@media (min-width: 1024px)
				border-radius: 0px 8px 8px 0px
			@media (max-width: 1023.98px)
				padding: r(20px)
			.title
				font-size: r(24px)
				font-weight: 700
				transition: .3s all  ease-in-out
				&:hover
					color: $main
				@media (max-width: 1023.98px)
					font-size: r(20px)
				@media (max-width: 767.98px)
					font-size: r(16px)
			.date
				color: #9b938b
				font-size: r(14px)
				font-weight: 500
				padding: 8px 0px 9px
				border-bottom: 2px solid $main
			.desc
				padding: 10px 0px
				margin-bottom: r(30px)
				p + p
					margin-top: 15px
			.btn-left
				margin-top: auto
