.shopping-cart-wrapper
	position: absolute
	top: calc( 100% + 60px )
	width: 370px
	background-color: #fff
	border-radius: 10px
	box-shadow: 0px 0px 20px rgba(#000,0.25)
	overflow: hidden
	padding: 10px 15px 10px
	z-index: 1000
	opacity: 0
	visibility: hidden
	transition: .3s all ease-in-out
	@media (max-width: 1199.98px)
		right: 0
	@media (min-width: 1200px)
		right: calc(100% - 237px)
	@media (max-width: 767.98px)
		width: 100vw
		position: fixed
		top: 61px
		transform: translateY(66px)
	&.active
		top: calc( 100% + 30px )
		opacity: 1
		visibility: visible
		@media (max-width: 767.98px)
			top: 61px
			transform: none
			border-radius: 0
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05)

	span
		color: $white
	.alert-add-success
		display: flex
		align-items: center
		padding: 15px 0px
		@media (min-width: 1024px)
			display: none
		em
			font-size: 24px
			color: $main
			margin-right: 10px
		p
			font-size: 18px
			padding-top: 2px
	.head
		font-weight: 400
		color: #000000
		padding: 10px 10px
		margin-top: 15px
		border-top: 1px solid $main
		.cart-quantity
			display: flex
			align-items: center
			justify-content: space-between
			span
				margin-left: 0
				color: #000000
			.total-cart-items
				background: $white
			.item-quantity
				font-weight: 600
		.cart-number
			font-size: r(18px)
			font-weight: 700
			margin-left: auto
			position: static !important
			p
				display: flex
				align-items: center
			span
				font-weight: 700
				color: $main-hover
				padding-left: 10px
	.body
		max-height: 300px
		overflow: auto
		&::-webkit-scrollbar
			width: 2px
			height: 2px
		.scroll-wrapper.cartpanel
			padding-right: 10px
			@media (max-width: 1023.98px)
				display: none
		.cart-item
			padding: 10px 0
			display: flex
			align-items: center
			position: relative
			.remove-item
				position: absolute
				top: 10px
				right: 5px
				cursor: pointer
				display: flex
				em
					font-size: r(21px)
			.image
				height: 100px
				flex: 0 0 85px
				max-width: 85px
				a
					display: flex
					width: 100%
					height: 100%
					align-items: center
					justify-content: center
					img
						max-width: 100%
						max-height: 100%
						object-fit: contain
			.caption
				margin-left: 10px
				flex: 1
				max-width: 100%
				width: 100%
				.title
					font-weight: 400
					font-size: 1rem
					padding-right: 15px
					margin-bottom: 10px
					a
						line-height: 1.3
						transition: .3s all ease-in-out
						&:hover
							color: $main-hover
					.price
						padding: 3px 0px
						font-weight: 700
				.wrap-price-quantity
					display: flex
					align-items: center
					justify-content: space-between
					width: 100%
				.quantity
					color: $main
					font-size: r(12px)
					white-space: nowrap
					span
						color: $main
			& + .cart-item
				border-top: thin solid #ccc
	.btn-center
		.btn-buy-product
			margin-top: 0
			height: 45px
			@media (max-width: 767.98px)
				max-width: 100%

