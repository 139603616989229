.passwordrecovery
	main
		.alert-success
			text-align: center
			display: block
		.login-box
			margin: 0 auto
			box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06)
			padding: 30px
			border-radius: 20px
			max-width: 400px
			width: 100%
			background-color: #fff
		[class*='col']
			padding-left: 0
			padding-right: 0
			flex: 0 0 100%
			max-width: 100%
		.row
			margin-left: 0
			margin-right: 0
		.col-sm-9
			flex: 0 0 100%
			max-width: 100%
		.input-group-append
			display: none
		.login-logo
			*
				text-align: center
				color: $main
				font-size: r(36px)
		#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_UserName
			outline: none
			border: 2px solid $main
			background-color: #f1f1f1
			padding-left: 20px
			width: 100%
			height: 40px
		.login-box-msg
			margin-bottom: 20px
		#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_CaptchaTextBox
			outline: none
			border: 2px solid $main
			background-color: #f1f1f1
			padding-left: 20px
			width: 100%
			height: 40px
		#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_lblEnterUserName
			white-space: nowrap
			text-align: left
			margin-bottom: 5px
		#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_SubmitButton
			width: 100%
			background-color: $main
			color: white
			margin-top: 15px
			outline: 0
			border: 0
			padding: 10px 0px
		#ctl00_mainContent_lblMailError
			font-weight: 400!important
			font-style: italic
			color: red
			font-family: 'Philosopher', sans-serif!important
			font-size: .9rem
			margin-top: 5px
			padding: 0 15px
		#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl00
			font-weight: 400!important
			font-style: italic
			color: red
			font-family: 'Philosopher', sans-serif!important
			font-size: .9rem
		.rcRefreshImage
			font-size: 0
			margin-left: 10px
			&::before
				content: 'refresh'
				font-family: 'Material Icons'
				font-weight: normal
				font-style: normal
				font-size: 30px
				color: black
		#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl01
			display: flex
			align-items: center
			margin-bottom: 15px
