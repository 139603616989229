.page-document-main
	.list-document
		ul
			li
				a
					cursor: pointer
					display: flex
					align-items: center
					justify-content: space-between
					padding: r(27px) 0px
					border-bottom: 1px solid $grey-1
				&:hover
					.wrap-date-name
						.name
							color: #0f8dc9
				.wrap-date-name
					padding-right: 15px
					.date
						color: #0f8dc9
						font-size: r(14px)
					.name
						color: $text
						font-size: r(14px)
						transition: .3s all  ease-in-out
						margin-left: r(37px)
						@media (max-width: 1023.98px)
							margin-left: r(20px)
