header
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04)
	position: fixed
	top: 0
	left: 0
	width: 100%
	z-index: 999
	background-color: #fff
	border-bottom: 1px solid $grey-1
	.container
		@media (max-width: 1023.98px)
			max-width: 100%
			padding-left: 0
			padding-right: 0
	nav
		height: 120px
		@media (max-width: 1023.98px)
			height: 70px
		@media (max-width: 575.98px)
			height: 60px
		.header-logo
			flex: 0 0 14.751773049%
			border-width: 0px 1px 0px 1px
			z-index: 2
			position: relative
			padding: 0 15px
			background-color: #fff
			@media (max-width: 1023.98px)
				flex: 0 0 150px
			@media (max-width: 575.98px)
				flex: 0 0 80px
				padding: 0 5px
		.header-main-menu
			flex: 1
			border-right: 1px solid $grey-1
			display: flex
			flex-direction: column
			.header-top-menu
				height: 70px
				&::before
					content: ''
					position: absolute
					top: 70px
					left: 0
					z-index: -1
					height: 1px
					width: 100vw
					background-color: $grey-1
					@media (max-width: 1023.98px)
						display: none
				.wrap-search
					padding: 13px
					flex: 0 0 47%
					border-right: 1px solid $grey-1
					@media (max-width: 1279.98px)
						flex: 0 0 25%
					@media (max-width: 1023.98px)
						flex: 1
					@media (max-width: 575.98px)
						padding: 10px
						display: flex
						align-items: center

					.searchbox
						display: flex
						align-items: center
						position: relative
						[class*='Module']
							width: 100%
						@media (max-width: 575.98px)
							justify-content: space-between
							width: 100%
						input
							border: none
							outline: none
							color: $grey
							font-size: r(14px)
							padding-left: 31px
							flex: 1
							@media (max-width: 1279.98px)
								padding-left: 0
							@media (max-width: 575.98px)
								font-size: r(12px)
								max-width: 100px
						button
							width: 46px
							height: 46px
							flex-shrink: 1
							border-radius: 4px
							background-color: $main
							transition: .3s all ease-in-out
							border: none
							outline: none
							transform: translateY(-2px)
							@media (max-width: 575.98px)
								width: 35px
								height: 35px
								transform: none
							em
								color: white
								font-size: r(24px)
								@media (max-width: 575.98px)
									font-size: r(18px)
							&:hover
								filter: brightness(.9)
				.list-main-menu
					flex: 1
					[class*='Module']
						width: 100%
						height: 100%
					@media (max-width: 1023.98px)
						display: none
					ul
						width: 100%
						height: 100%
						li
							border-right: 1px solid $grey-1
							flex: 1
							transition: .3s all ease-in-out
							&.active
								a
									span
										color: $main
							&:hover
								a
									span
										color: $main
							a
								height: 100%
								// padding: 0 20px
								img
									max-height: 24px
									transition: .3s all ease-in-out
								span
									margin-top: 5px
									font-size: 12px
									transition: .3s all ease-in-out
									white-space: nowrap
				.wrap-cart
					position: relative
					@media (max-width: 1279.98px)
						margin-left: auto
					.cart
						padding: 0 20px
						@media (max-width: 575.98px)
							padding: 0 10px
						.quantity
							position: absolute
							color: #ffffff
							font-size: r(12px)
							font-weight: 400
							text-align: center
							width: 20px
							height: 20px
							background-color: $red
							border-radius: 50%
							display: flex
							align-items: center
							justify-content: center
							top: 5px
							right: 20px
							line-height: 1
							@media (max-width: 575.98px)
								width: 16px
								height: 16px
								font-size: r(10px)
								top: 3px
								right: 15px
						img
							max-height: 24px
							@media (max-width: 575.98px)
								max-height: 18px
						span
							margin-top: 5px
							display: inline-block
							white-space: nowrap
							font-size: r(12px)
			.header-bottom-menu
				display: flex
				align-items: center
				flex: 1
				@media (min-width: 1024px)
					padding-left: r(30px)
				@media (min-width: 1280px)
					padding-left: r(45px)
				@media (max-width: 1023.98px)
					display: none
				ul
					display: flex
					align-items: center
					li
						&+li
							margin-left: r(15px)
							@media (min-width: 1280px)
								margin-left: r(42px)
						a
							color: $text-2
							font-size: r(14px)
							font-weight: 600
							letter-spacing: 1.4px
							text-transform: uppercase
							+hover-underline-2(1px, $main, -2px)
							@media (max-width: 1279.98px)
								font-size: r(13px)
.menu-mobile-main
	position: fixed
	top: 71px
	right: 0
	background-color: #fff
	border-left: 1px solid $grey-1
	height: 100vh
	z-index: 9
	width: 350px
	padding: 15px
	transition: .3s all ease-in-out
	transform: translateX(100%)
	overflow: auto
	will-change: transform
	@media (max-width: 575.98px)
		width: 100vw
		top: 61px
	&.isOpen
		transform: none
	@media (min-width: 1024px)
		display: none
	.menu-product-main
		display: flex
		align-items: center
		background-color: $main
		color: white
		padding: 10px 15px
		justify-content: space-between
		transition: .3s all ease-in-out
		border-radius: 4px
		margin-bottom: 15px
		em
			transition: .3s all ease-in-out
		&.active
			background-color: #047c30
			em
				transform: rotate(45deg)
		span
			font-size: 16px
			transition: .3s all ease-in-out
	.list-main-menu
		[class*='Module']
			width: 100%
		&.product-list
			display: none
		ul
			flex-direction: column
			width: 100%
			li
				&.active
					a
						color: $main
				a
					justify-content: start
					width: 100%
					padding: 15px 0px
					border: 1px solid $grey-1
					border-width: 1px 0px 0px 0px
					flex-direction: row
					font-size: 16px
					display: flex
					span
						font-size: 16px
					img
						margin-right: 15px
						display: none
.overlay
	position: fixed
	top: 0
	left: 0
	z-index: 99
	width: 100%
	height: 100%
	pointer-events: none
	background-color: rgba(0, 0, 0, 0.3)
	transition: .3s all ease-in-out
	opacity: 0
	visibility: visible
	&.active
		opacity: 1
		pointer-events: all
		visibility: visible
