.item-product-big
	border-radius: 8px
	overflow: hidden
	position: relative
	&:hover
		.wrap-button-title
			transform: none
			.wrap-button-buy
				opacity: 1
				visibility: visible
	&::before
		content: ''
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.75) 100%)
		position: absolute
		top: 0
		left: 0
		width: 100%
		z-index: 2
		height: 100%
	.img
		+img-ratio(500/390)
		@media (max-width: 575.98px)
			padding-top: 100%
	.wrap-button-title
		position: absolute
		bottom: 30px
		left: 0
		z-index: 3
		width: 100%
		padding: 0 15px
		transition: .3s all ease-in-out
		transform: translateY(40px)
		will-change: transform
		h2
			+line(2)
			@media (max-width: 1279.98px)
				font-size: r(20px)
		.wrap-button-buy
			margin-top: 10px
			opacity: 0
			transition: .3s all ease-in-out
			visibility: hidden
.item-product-main
	background-color: #fff
	border-radius: 8px
	overflow: hidden
	transition: .3s all ease-in-out
	&:hover
		box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.05)
		.content
			transform: translateY(-100px)
			@media (max-width: 575.98px)
				transform: translateY(-90px)
			@media (min-width: 1460px)
				transform: translateY(-70px)
			.wrap-button-buy
				opacity: 1
				visibility: visible
	.img
		a
			+center-image
		.tag-promotion
			position: absolute
			top: 18px
			left: 19px
			display: flex
			align-items: center
			justify-content: center
			width: 40px
			height: 20px
			border-radius: 4px
			background-color: #0f8dc9
			color: #ffffff
			font-size: r(14px)
	.content
		transition: .3s all ease-in-out
		padding: 10px 30px 20px
		background-color: #fff
		position: relative
		@media (max-width: 575.98px)
			padding: 10px 15px 9px
		h3
			a
				height: 46px
				display: block
				color: $text
				font-size: r(16px)
				font-weight: 500
				border-bottom: 1px solid $grey-1
				padding-bottom: 10px
				+line(2)
				@media(min-width: 1024px)
					height: 50px
				@media(min-width: 1280px)
					min-height: 53px
		.price
			color: #666666
			font-size: r(14px)
			line-height: 18px
			margin-top: 10px
		.wrap-button-buy
			opacity: 0
			visibility: hidden
			position: absolute
			top: 100%
			width: 100%
			left: 0
			transition: .3s all ease-in-out
			@media (max-width: 1459.98px)
				flex-direction: column
				padding: 0 15px
				gap: 5px
				.btn
					width: 100% !important
.item-news-main
	position: relative
	&:hover
		.line
			background-color: rgba( #e3ecf0, .5)
	.img
		a
			+img-ratio(280/440)
	.content
		padding: 16px 55px 45px
		display: flex
		flex-direction: column
		align-items: center
		justify-content: space-between
		min-height: 160px
		position: relative
		z-index: 9
		@media (max-width: 1279.98px)
			padding: 10px 20px 30px
		h3
			text-align: center
			&:hover
				text-decoration: underline
			a
				color: $text
				font-size: r(18px)
				text-align: center
				+line(3)
				line-height: 1.3
				@media (max-width: 1279.98px)
					font-size: r(16px)
	.line
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%,-50%)
		width: calc( 100% - 40px )
		height: calc( 100% - 40px )
		border: 4px solid rgba( #e3ecf0, .5)
		transition: .3s all ease-in-out
		@media (max-width: 1279.98px)
			width: calc( 100% - 20px )
			height: calc( 100% - 20px )
		// background-color: rgba( #e3ecf0, .5)
.wrap-title-product
	display: flex
	align-items: center
	justify-content: space-between
	margin-bottom: r(30px)
	.title-product
		font-size: r(26px)
