.home-all-product
	background-color: #edf6f1
	h2
		margin-bottom: 20px
	.btn-sw-1
		background-color: #fff
		&.btn-prev
			margin-right: 15px
		&.btn-next
			margin-left: 15px
	.swiper-slide
		height: auto
