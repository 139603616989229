.page-gioi-thieu
	&.section-1
		position: relative
		@media (min-width: 1280px)
			padding-top: 100px
		.content
			background-color: #edf6f1
			z-index: 2
			padding: r(30px)
			position: relative
			@media (min-width: 1460px)
				padding: r(15px) r(40px) r(58px) r(72px)
			@media (min-width: 1280px)
				padding: r(0px) r(20px) r(20px) r(25px)
			.desc
				p
					line-height: 1.35
				p + p
					margin-top: r(20px)
			&::before
				content: ''
				position: absolute
				top: 0
				right: 0
				background-color: #edf6f1
				height: calc( 100% + 40px )
				z-index: -1
				transform: translateX(0) translateY(0)
				width: 100%
				@media (min-width: 1280px)
					width: 100vw
					transform: translateX(85px) translateY(-40px)
			.title-36
				margin-bottom: r(24px)
		.img
			position: relative
			z-index: 3
			@media (max-width: 1279.98px)
				img
					width: 100%
	&.section-2
		@media (max-width: 767.98px)
			padding-top: 0
		.wrap-content-main
			padding: r(60px) 0px
			.wrap-box-year
				margin: 0 90px
				@media (max-width: 1023.98px)
					margin: 0 0px
			.wrap-year
				display: flex
				flex-direction: column
				flex: 0 0 20.32520%
				max-width: 20.32520%
				background-color: #fff
				@media (max-width: 1023.98px)
					flex: 0 0 100%
					max-width: 100%
					flex-direction: row
				.slide-year
					height: 100%
					max-height: 650px
					@media (max-width: 1023.98px)
						max-width: 80%
					@media (max-width: 767.98px)
						max-width: calc( 100% - 100px)
					.swiper
						height: 100%
						.swiper-wrapper
							height: 100%
				.btn
					width: 100%
					height: 50px
					background-color: $grey-2
					transition: .3s all ease-in-out
					&.btn-prev
						@media (max-width: 1023.98px)
							em
								transform: rotate(-90deg)
					&.btn-next
						@media (max-width: 1023.98px)
							em
								transform: rotate(-90deg)
					em
						font-size: r(50px)
					@media (max-width: 1023.98px)
						height: auto
					&:hover
						background-color: $main
				.slide-year
					.swiper-slide-thumb-active
						.year
							background-color: $main
							color: white
					.year
						color: #666666
						font-size: r(24px)
						font-weight: 700
						text-align: center
						display: flex
						align-items: center
						justify-content: center
						padding: r(20px) 0px
						border-bottom: 1px solid #e0e0e0
						transition: .3s all ease-in-out
						height: 100%
						&:hover
							color: white
							background-color: $main
			.content-img
				flex: 0 0 79.6748%
				max-width: 79.6748%
				@media (max-width: 1023.98px)
					flex: 0 0 100%
					max-width: 100%
				.wrap-img
					.img
						position: relative
						&::before
							content: ''
							position: absolute
							inset: 0
							background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.72) 26%, rgba(0, 0, 0, 0) 100%)
					.content
						position: absolute
						left: 0
						bottom: 0
						color: white
						padding: r(50px) r(50px) r(55px)
						@media (max-width: 1279.98px)
							padding: r(30px)
						@media (max-width: 575.98px)
							display: flex
							height: 100%
							flex-direction: column
						.year
							color: #ffffff
							font-size: r(36px)
							font-weight: 700
							text-transform: uppercase
							line-height: 1.23
							@media (max-width: 1279.98px)
								font-size: r(30px)
							@media (max-width: 1023.98px)
								font-size: r(26px)
						.sub-title
							color: #ffffff
							font-size: r(18px)
							font-weight: 700
							letter-spacing: 0.9px
							text-transform: uppercase
							margin-top: r(15px)
						.desc
							color: #ffffff
							font-size: r(18px)
							margin-top: r(15px)
							@media (max-width: 575.98px)
								flex: 1
								max-height: 100%
								overflow: auto
								padding-right: 15px
	&.section-3
		padding-top: 30px
		padding-bottom: r(150px)
		@media (max-width: 1023.98px)
			padding-bottom: r(100px)
		.item
			@media (max-width: 575.98px)
				margin-bottom: r(30px)
			.img
				border-radius: 8px
				overflow: hidden
				+img-ratio(450/690)
			.content
				.title
					color: $main
					font-size: r(24px)
					text-align: center
					margin-top: r(30px)
					margin-bottom: r(8px)
