.item-gallery-main
	margin-bottom: r(30px)
	&.image
		.img
			&::after
				width: 64px
				height: 64px
				background-image: url('../img/icon/image.png')
	.img
		position: relative
		&::before
			content: ''
			position: absolute
			top: 0
			left: 0
			width: 100%
			pointer-events: none
			z-index: 2
			height: 100%
			background-color: rgba(51, 51, 51, 0.5)
		&::after
			content: ''
			z-index: 3
			background-image: url('../img/icon/play_1.png')
			position: absolute
			width: 56px
			height: 64px
			pointer-events: none
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
		a
			+img-ratio(220/330)
	.title
		a
			color: $text
			font-size: r(18px)
			display: block
			font-weight: 500
			text-align: center
			transition: .3s all ease-in-out
			padding: r(20px) r(20px) 0px
			&:hover
				color: $main
