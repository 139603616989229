.wrap-thong-bao-dat-hang
	padding: 60px 0px
	.thong-bao-dat-hang
		padding: 0 30px 24px
		min-height: 480px
		background-color: white
		border-radius: 10px
		box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)
		@media (max-width: 767.98px)
			padding-left: 10px
			padding-right: 10px
		.title-thong-bao
			display: flex
			align-items: center
			justify-content: center
			padding-top: 14px
			padding-bottom: 6px
			border-bottom: 3px solid $main
			span
				color: $main
				font-size: r(24px)
				font-weight: 700
				text-transform: uppercase
				margin-left: 10px
				display: inline-block
				padding-top: 6px
				@media (max-width: 575.98px)
					font-size: 1.3rem
		.content-thong-bao
			padding-top: 50px
			max-width: 690px
			margin: 0 auto
			width: 100%
			@media (max-width: 1023.98px)
				padding-top: 20px
			*
				color: $main
				font-size: 1rem
				text-align: center
				letter-spacing: 0.8px
			.masodon
				font-size: 18px
				font-weight: 700
				font-style: normal
				letter-spacing: 0.9px
				margin-left: 5px
		.btn-success-checkout
			max-width: 435px
			width: 100%
			height: 40px
			border: 1px solid $main
			background-color: $main
			display: flex
			align-items: center
			justify-content: center
			margin: 0 auto
			margin-top: 28px
			color: $main
			font-size: r(14px)
			font-weight: 700
			text-align: center
			text-transform: uppercase
			cursor: pointer
			transition: .3s all ease-in-out
			&:hover
				letter-spacing: 2px