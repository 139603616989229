.swiper-button-disabled
	opacity: 0.4 !important
.wrap-button-slide
	display: flex
	align-items: center
	justify-content: center
	@media (max-width: 1279.98px)
		margin-top: r(30px)
	&.hidden-ipad
		@media (max-width: 1279.98px)
			display: none
	&.button-dis
		.btn-prev
			right: auto
			left: 0
		.btn-next
			left: auto
			right: 0
	&.none-static
		.btn-prev,.btn-next
			position: absolute !important
			top: 50%
			transform: translateY(-50%)
	.btn-prev,.btn-next
		z-index: 9
		position: static
		@media (min-width: 1280px)
			position: absolute
			top: 50%
			transform: translateY(-50%)
	.btn-prev
		right: 100%
		@media (max-width: 1279.98px)
			margin-right: 5px !important
	.btn-next
		left: 100%
		@media (max-width: 1279.98px)
			margin-left: 5px !important
.pagination-mb
	position: static !important
	margin-top: r(20px)
	@media (min-width: 1280px)
		display: none
	.swiper-pagination-bullet
		width: 10px
		height: 10px
		&.swiper-pagination-bullet-active
			background-color: $main
