.page-news-detail
	padding-bottom: r(100px)
	.wrap-title-header
		.wrap-date-share
			margin-top: r(5px)
			border-bottom: 1px solid $grey-1
			padding-bottom: r(23px)
			.date
				color: $grey
				font-weight: 500
	.content-main
		padding: r(30px) 0px
		p
			margin-bottom: r(20px)
			line-height: 1.5
		h2,h3,h4,h5
			margin-bottom: r(20px)
		h2
			font-size: r(18px)
			font-weight: 700
	.box-other-news
		border-radius: 8px
		border: 1px solid $grey-1
		background-color: $grey-2
		padding: 28px 39px
		@media (max-width: 1279.98px)
			padding: 25px 20px
		@media (max-width: 1023.98px)
			padding: 10px 15px
		.title-36
			margin-bottom: 8px
		ul
			+type-none
			li.item-news-other
				padding: r(20px) 0px
				display: flex
				border-top: 1px solid $grey-1
				.img
					flex: 0 0 127px
					@media (max-width: 1279.98px)
						flex: 0 0 100px
					a
						+img-ratio(80/127)
						border-radius: 8px
				.content
					padding-left: r(25px)
					.date
						color: $grey
						font-size: r(14px)
					.title
						color: $text
						font-size: r(14px)
						margin-top: r(10px)
						+line(2)
						transition: .3s all  ease-in-out
						@media (max-width: 1279.98px)
							margin-top: r(5px)
						&:hover
							color: $main
							text-decoration: underline
