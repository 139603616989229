$main: #009035
$main-hover: #026928
$body: white
$text: #333333
$text-2: #111111
$grey: #7f8084
$grey-1: #e5e5e5
$red: #e30016
$grey-2: #f5f5f5
$blue-1: #0F8DC9
$white: white