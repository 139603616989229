@function r($size)
	@return ($size/16px)*1rem

@function pow($base, $exponent)
	$result: 1
	@for $_ from 1 through $exponent
		$result: $result * $base
	@return $result + px

=prefix($property, $value, $prefixes)
	@each $prefix in $prefixes
		-#{$prefix}-#{$property}: $value
		#{$property}: $value
=img-ratio($ratio,$fit:cover)
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: $ratio *100%
	img,iframe,video
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: $fit
		transition: .3s ease-in-out all
=center-image()
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: 100%
	img,iframe,video
		position: absolute
		transform: translate(-50%,-50%)
		top: 50%
		left: 50%
=bg-body($color)
	#{if(&, '&.bg-body','.bg-body')}
		background-color: $color
=dc()
	display: flex
	justify-content: center
	align-items: center
=li($name, $ratio)
	+ $name
		margin-top: $ratio
=line($line)
	overflow: hidden
	display: -webkit-box
	text-overflow: ellipsis
	-webkit-line-clamp: $line
	-webkit-box-orient: vertical
=hover-underline($ratio, $color)
	position: relative
	transition: background-size .6s ease
	background-image: linear-gradient(180deg,transparent $ratio, $color 0)
	background-repeat: no-repeat
	background-size: 0 100%
	&:hover
		background-size: 100% 100%
=gg-speed($auto, $grid)
	content-visibility: $auto
	contain-intrinsic-size: $grid
=text-vertical($vertical )
	writing-mode: vertical-rl
	// writing-mode: vertical-$vertical
=type-none
	list-style-type: none
	margin: 0
	padding: 0
=scroll
	&::-webkit-scrollbar
		width: 5px
	&::-webkit-scrollbar-track
		background: $body
	&::-webkit-scrollbar-thumb
		background: $main
	&::-webkit-scrollbar-thumb:hover
		background: $main
=text-gray
	font-size: r(20px)
	font-weight: 400
	color: $gray
=hover-underline-2($height, $color, $space:0)
	position: relative
	transition: color .4s cubic-bezier(.58,.3,.005,1)
	&:hover
		color: $main
		&::after
			transform: scale3d(1,1,1)
			transition: transform .4s cubic-bezier(.58,.3,.005,1)
			transform-origin: 0 50%
	&::after
		content: ""
		height: $height
		width: 100%
		position: absolute
		bottom: $space
		left: 0
		background-color: $color
		transform: scale3d(0,1,1)
		transition: transform .4s cubic-bezier(.58,.3,.005,1)
		transform-origin: 100% 50%
