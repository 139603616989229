.menu.cross.menu--1.hambuger
	display: none
	@media (max-width: 1023.98px)
		flex: 0 0 44px
		width: 44px
		height: 40px
		background-color: $main
		display: flex
		align-items: center
		border-radius: 4px
		justify-content: center
		position: relative
		z-index: 2
	.wrap
		display: block
		cursor: pointer
		position: absolute
		width: 100px
		height: 100px
		z-index: -1
	path
		fill: none
		stroke: #ffffff
		stroke-width: 4
		stroke-linecap: round
		stroke-linejoin: round
		--length: 24
		--offset: -38
		stroke-dasharray: var(--length) var(--total-length)
		stroke-dashoffset: var(--offset)
		transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-webkit-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-moz-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-ms-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-o-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
	&.active
		svg .line--1,
		svg .line--3
			--length: 22.627416998
			--offset: -93.7
		svg .line--2
			--length: 0
			--offset: -50
	.line--1, .line--3
		--total-length: 126.64183044433594
	.line--2
		--total-length: 70
.wrap-hambuger
	display: flex
	align-items: center
	overflow: hidden
	padding: 0 20px
	border-left: 1px solid $grey-1
	@media (max-width: 575.98px)
		padding: 0 r(10px)
	@media (min-width: 1024px)
		display: none
// Hambuger html
// 	.hambuger.menu.cross.menu--1
// 		.wrap
// 			svg(viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg')
// 				path.line--1(d='M0 40h62c13 0 6 28-4 18L35 35')
// 				path.line--2(d='M0 50h70')
// 				path.line--3(d='M0 60h62c13 0 6-28-4-18L35 65')
