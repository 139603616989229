.resetpassword
	.alert-success
		text-align: center
		display: block
	.wrap-recover
		margin: 0 auto
		box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06)
		padding: 30px
		border-radius: 20px
		max-width: 400px
		width: 100%
		background-color: #fff
	[class*='col']
		padding-left: 0
		padding-right: 0
		flex: 0 0 100%
		max-width: 100%
	.row
		margin-left: 0
		margin-right: 0
	.col-sm-9
		flex: 0 0 100%
		max-width: 100%
	input[type="submit"]
		width: 100%
		height: 40px
		text-align: center
		margin-top: r(10px)
		background-color: $main
		color: white
		outline: none
		border: 2px solid transparent
		font-weight: bold
		transition: .3s all ease-in-out
		&:hover
			border: 2px solid $main
			background-color: white
			color: $main
	input[type="password"]
		outline: none
		border: 2px solid #98272b
		background-color: #f1f1f1
		padding-left: 20px
		width: 100%
		height: 40px
		margin-top: 5px
	.form-group
		margin-top: 15px
	.panel-heading
		text-align: center
		*
			color: $main
