.home-why-chosse
	.container
		@media (min-width: 1024px)
			padding: 0 r(30px)
		@media (min-width: 1280px)
			padding: 0 r(90px)
	.text-center
		max-width: 739px
		margin: 0 auto
		.title
			font-size: r(36px)
			@media (max-width: 575.98px)
				font-size: 1.7rem
		.desc
			margin-top: r(25px)
	.row
		margin-top: r(30px)
		@media (min-width: 768px)
			margin-top: r(90px)
		.icon
			margin-bottom: r(30px)
	.wrap
		@media (min-width: 1024px)
			padding: 0 r(30px)
		@media (min-width: 1280px)
			padding: 0 r(60px)
		p
			color: #ffffff
			font-size: r(18px)
			font-weight: 500
			@media (max-width: 575.98px)
				font-size: 1rem
	.row
		.col-lg-4
			position: relative
			@media (max-width: 1279.98px)
				margin-bottom: r(20px)
			&:nth-child(2)
				@media (min-width: 1280px)
					&::before,&::after
						content: ''
						position: absolute
						width: 2px
						height: 80px
						background-color: #ffffff
						opacity: 0.28
					&::before
						left: 10px
						top: -10px
					&::after
						right: 10px
						top: -10px

