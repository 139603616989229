.page-product-list
	background-color: $grey-2
	.item-product-list
		padding-bottom: r(30px)
		margin-bottom: r(30px)
		@media (min-width: 1024px)
			margin-bottom: r(45px)
			padding-bottom: r(60px)
		&:not(:last-child)
			border-bottom: 2px solid $grey-1
		&:last-child
			padding-bottom: r(20px)
	.btn-prev
		margin-right: r(30px)
	.btn-next
		margin-left: r(30px)
	.btn-sw-1
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1)
		background-color: #fff
