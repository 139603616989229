.page-video-banner
	background-color: #f5f5f5
	border-top: 1px solid $grey-1
	border-bottom: 1px solid $grey-1
	padding: 30px 0px 8px
	@media (max-width: 1023.98px)
		padding: r(20px) 0px
	.box-video-left
		position: relative
		&::before
			content: ''
			width: 1px
			height: 110px
			background-color: $grey-1
			position: absolute
			top: -10px
			right: 35px
			@media (max-width: 1023.98px)
				display: none
		.icon
			em
				color: $main
				font-size: r(16px)
				font-weight: 300
		.text
			padding-left: 14px
			@media (max-width: 1023.98px)
				margin-bottom: r(20px)
			span
				color: #666666
				font-size: r(18px)
			p
				color: $text-2
				font-size: r(24px)
				font-weight: 500
				line-height: 1.2
	.box-video-right
		@media (min-width: 1024px)
			padding-left: r(20px)
			padding-right: r(20px)
		@media (min-width: 1280px)
			padding-left: r(30px)
			padding-right: r(80px)
		@media (min-width: 1440px)
			padding-right: r(140px)
			padding-left: r(40px)
		.video
			&:hover
				.img
					&::before
						background-color: rgba(0, 0, 0, 0.2)
			.img
				margin-bottom: 20px
				width: 90px
				height: 60px
				margin: 0 auto
				@media (max-width: 575.98px)
					width: 110px
					height: 80px
					img
						width: 100%
						height: 100%
						object-fit: cover
				&::before
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					background-color: rgba(0, 0, 0, 0.5)
					transition: .3s all ease-in-out
					z-index: 2
				.play-video
					position: absolute
					top: 50%
					left: 50%
					z-index: 3
					transform: translate(-50%,-50%)
					em
						font-size: r(24px)
						color: white
			h3
				font-size: r(13px)
				text-align: center
				transition: .3s all ease-in-out
				margin-top: 10px
				&:hover
					color: $main
		.wrap-button-slide
			@media (max-width: 1023.98px)
				display: none
			.btn-prev,.btn-next
				top: 40%
			.btn-prev
				margin-right: 22px
			.btn-next
				margin-left: 22px
		.pagination-mb
			margin-top: r(20px)
			@media (min-width: 1024px)
				display: none