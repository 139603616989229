.btn
	cursor: pointer
	user-select: none
	&.btn-view-all
		width: r(194px)
		height: r(50px)
		border-radius: 4px
		background-color: $main
		display: flex
		align-items: center
		justify-content: center
		transition: .3s all ease-in-out
		@media (max-width: 575.98px)
			width: 130px
			height: 40px
		&:hover
			box-shadow: 2px 2px 5px $main
			em
				animation: pulse .3s infinite alternate linear
		span
			color: #ffffff
			font-size: r(14px)
			letter-spacing: 1.4px
			text-transform: uppercase
			line-height: 1
			@media (max-width: 575.98px)
				font-size: r(12px)
		em
			font-size: r(15px)
			color: white
			margin-left: 10px
	&.btn-sw-1
		width: 63px
		height: 63px
		border-radius: 50%
		display: flex
		align-items: center
		justify-content: center
		background-color: #f2f1f1
		transition: .3s all ease-in-out
		@media (max-width: 1279.98px)
			width: 50px
			height: 50px
		@media (max-width: 767.98px)
			width: 40px
			height: 40px
		em
			font-size: r(20px)
			transition: .3s all ease-in-out
		img
			@media (max-width: 767.98px)
				transform: scale(.7)
		&:hover
			background-color: $main
			em
				color: white
			img
				filter: brightness(0) invert(1)
	&.btn-sw-2
		color: #cccccc
		transition: .3s all ease-in-out
		em
			font-size: r(32px)
		&:hover
			color: $main
	&.btn-sw-3
		display: flex
		align-items: center
		justify-content: center
		em
			color: $grey-1
			font-size: r(32px)
	&.btn-detail
		height: 40px
		border-radius: 8px
		background-color: $grey-1
		padding: 0 8px
		display: flex
		align-items: center
		justify-content: center
		transition: .3s all ease-in-out
		@media (max-width: 575.98px)
			height: 35px
		&:hover
			box-shadow: 2px 2px 5px $grey-1
			img
				animation: pulse .3s infinite alternate linear
		span
			font-size: r(14px)
			color: $text
			text-transform: uppercase
			white-space: nowrap
			letter-spacing: 1.4px
			@media (max-width: 1279.98px)
				font-size: r(12px)
		img
			margin-left: 8px
	&.btn-buy-now
		border-radius: 4px
		background-color: $main
		em
			font-size: r(24px)
			color: white
			margin-right: r(23px)
		span
			text-transform: uppercase
			font-size: 14px
	&.btn-contact
		height: 40px
		border-radius: 8px
		padding: 0 8px
		background-color: $main
		display: flex
		align-items: center
		justify-content: center
		transition: .3s all ease-in-out
		will-change: box-shadow
		color: white
		@media (max-width: 575.98px)
			height: 35px
		&:hover
			box-shadow: 2px 2px 5px $main
			img
				animation: pulse .3s infinite alternate linear
		span
			font-size: r(14px)
			text-transform: uppercase
			letter-spacing: 1.4px
			white-space: nowrap
			color: white
			@media (max-width: 1279.98px)
				font-size: r(12px)
			@media (max-width: 399.98px)
				letter-spacing: 1px
		img, em
			margin-left: 8px
			color: white
			@media (max-width: 399.98px)
				font-size: 12px
				margin-left: 4px
	&.btn-shopping
		width: 100%
		max-width: 195px
		height: 40px
		border-radius: 3px
		padding: 0 15px
		background-color: $grey-2
		color: $main
		display: flex
		transition: .3s all  ease-in-out
		align-items: center
		justify-content: center
		white-space: nowrap
		font-weight: 700
		font-size: r(16px)
		@media (max-width: 575.98px)
			font-size: .9rem
		@media screen and ( min-width: 1025px )
			&:hover
				transform: scale(1.05) translateZ(0)
	&.btn-checkout
		display: flex
		transition: .3s all  ease-in-out
		align-items: center
		justify-content: center
		border-radius: 3px
		background-color: $main
		color: white
		width: 100%
		font-weight: 700
		max-width: 195px
		height: 45px
		padding: 0 20px
		margin-left: 20px
		white-space: nowrap
		@media (max-width: 575.98px)
			font-size: .9rem
		@media screen and ( min-width: 1025px )
			&:hover
				box-shadow: 2px 2px 5px $main
	&.btn-buy-product
		max-width: 386px
		height: 50px
		border-radius: 4px
		background-color: $main
		display: inline-flex
		align-items: center
		justify-content: center
		margin-top: r(30px)
		width: 100%
		transition: .3s all ease-in-out
		&:hover
			box-shadow: 2px 2px 5px $main
			em
				animation: lagging .5s infinite linear alternate
		span
			font-size: r(14px)
			color: white
			letter-spacing: 0.1em
		em
			font-size: r(24px)
			color: white
			margin-right: r(23px)
	&.btn-view-more
		display: inline-flex
		align-items: center
		padding: 10px 15px
		border-radius: 8px
		background-color: $main
		transition: .3s all ease-in-out
		&:hover
			box-shadow: 2px 2px 5px $main
			&::after
				animation: pulse .3s infinite alternate linear
		&::after
			content: ''
			display: inline-block
			width: 19px
			margin-left: 10px
			height: 13px
			background-image: url('../img/icon/arrow-right-white.png')
		span
			color: #ffffff
			font-size: r(14px)
			letter-spacing: 1.4px
			text-transform: uppercase
.wrap-button-buy
	gap: 10px
.frm-btnwrap
	.frm-btn
		position: relative
		width: fit-content
		&:hover
			input
				box-shadow: 2px 2px 5px $main
			&::before
				animation: pulse .3s infinite alternate linear
		&::before
			content: ''
			background-image: url('../img/icon/arrow-right.png')
			display: inline-block
			position: absolute
			top: 15px
			right: 30px
			width: 20px
			height: 20px
		input
			width: 160px
			height: 50px
			border-radius: 8px
			background-color: $main
			color: #ffffff
			font-size: r(14px)
			letter-spacing: 1.4px
			outline: none
			border: 0
			text-transform: uppercase
			transition: .3s all ease-in-out
