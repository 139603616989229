.global-breadcrumb
	width: 100%
	background-color: rgba(227, 236, 240, 0.5)
	.breadcrumb
		display: flex
		align-items: center
		background-color: transparent
		flex-wrap: wrap
		padding: 10px 0
		list-style: none
		margin-bottom: 0
		li
			position: relative
			padding: 0 7px
			display: flex
			align-items: center
			a
				font-size: 14px
				color: $grey
				font-weight: 500
				text-decoration: none
				@media(max-width: 991.98px)
					font-size: 10px
			&.active
				a
					color: $main-hover
			&:hover
				a
					color: $main-hover
		li+li
			&::before
				position: absolute
				top: 38%
				left: -4px
				transform: translateY(-50%)
				font-family: Material Icons
				font-size: 18px
				content: "/"
				color: #999999

	@media(min-width: 768.98px)
		margin-bottom: 0

	@media(min-width: 320px)
		margin-bottom: 0
