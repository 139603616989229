.home-subscribe
	background-color: #e3ecf0
	min-height: 250px
	.img-product
		transform: translateY(-50px) translateX(-22px)
	.des
		color: #666666
		@media (max-width: 1279.98px)
			min-height: 45px
	.list-social
		display: flex
		align-items: center
		gap: 10px
		margin-top: 26px
		@media (max-width: 1279.98px)
			margin-top: 23px
		@media (max-width: 767.98px)
			margin-top: 0
		li
			a
				width: 50px
				height: 50px
				box-shadow: 9px 12px 30px rgba(0, 0, 0, 0.05)
				border-radius: 8px
				display: flex
				align-items: center
				justify-content: center
				background-color: #ffffff
				transition: .3s all ease-in-out
				img
					transition: .3s all ease-in-out
				&:hover
					background-color: $main
					img
						filter: brightness(0) invert(1)
	.wrap-subscribe
		padding-bottom: 40px
		width: 100%
		@media (max-width: 767.98px)
			padding-bottom: 20px
	.box-padding
		@media (min-width: 1024px)
			padding: r(40px) 0px
	.subscribe
		display: flex
		align-items: center
		[class*='Module']
			width: 100%
		.subscribefrm
			width: 100%
			display: flex
			align-items: center
			margin-top: r(25px)
			@media (max-width: 767.98px)
				margin-top: r(0px)
		input
			flex: 1
			height: 50px
			box-shadow: 9px 12px 30px rgba(0, 0, 0, 0.05)
			border-radius: 8px 0 0 8px
			background-color: #ffffff
			color: #666666
			border: none
			outline: none
			font-size: r(14px)
			padding-left: 25px
		button
			flex: 0 0 50px
			height: 50px
			display: flex
			align-items: center
			justify-content: center
			background-color: $main
			border: none
			border-radius: 0px 8px 8px 0px
			outline: none
			transition: .3s all ease-in-out
			font-size: 0
			&::before
				content: ''
				background-image: url('../img/icon/right2.png')
				filter: brightness(0) invert(1)
				width: 19px
				height: 13px
				display: inline-block
			img
				filter: brightness(0) invert(1)
			&:hover
				background-color: $main-hover
	.wrap-social
		padding-left: 62px
		padding-bottom: 38px
		@media (max-width: 1279.98px)
			padding-left: 30px
		@media (max-width: 767.98px)
			padding-left: 0
