.checkout-login-section
	padding: 50px 0px
	.checkout-guest
		.form-group
			margin-top: 0 !important
	.cart-login
		max-width: 495px
		padding: 23px
		margin: 0 auto
		box-shadow: 0 0 20px rgba(33,33,33,.2)
		background-color: #fff
		border-radius: 20px
		border: 2px solid $main
		.login-heading
			font-size: r(24px)
			font-weight: 700
			line-height: r(18px)
			text-transform: uppercase
			padding-bottom: 15px
			border-bottom: solid 3px #ebebeb
			margin-bottom: 31px
		.login-form
			.select-group
				margin-bottom: 15px
				ul
					+type-none()
					li
						display: flex
						align-items: center
						span
							display: flex
							align-items: center
						input[type="radio"]
							display: none
							&:checked ~ label
								&::before
									content: "radio_button_checked"
						label
							line-height: r(30px)
							display: flex
							align-items: center
							&::before
								padding-right: 8px
								font-family: Material Icons
								font-size: 18px
								text-transform: none
								content: "radio_button_unchecked"
			.module-title
				font-size: r(18px)
				line-height: r(22px)
				font-weight: 800
				text-transform: uppercase
				padding-bottom: 18px
			.form-group
				width: 100%
				a
					transition: .3s all  ease-in-out
					&:hover
						color: $main-hover
				label
					display: flex
					align-items: center
					padding-bottom: 10px
					span
						color: red
				input
					width: 100%
					height: 50px
					border: none
					border-radius: 5px
					background: #f0f0f0
					@extend .input-hover-focus
				&.form-bottom
					display: flex
					align-items: center
					justify-content: space-between
					span
						font-size: r(14px)
						line-height: r(22px)
						.signup
							color: #068fdd
					.forget
						font-size: r(14px)
						line-height: r(22px)
				&:nth-child(2)
					margin-top: 30px
					margin-bottom: 22px
			.btn-continue
				width: 100%
				height: 40px
				border-radius: 0px
				border: 1px solid $main !important
				background-color: $main
				color: $main
				font-size: r(14px)
				font-weight: 700
				margin-top: 5px
				text-transform: uppercase
				transition: .3s all ease-in-out
				border-radius: 5px
				&:hover
					font-size: r(16px)
					transform: scale(1.02) translateZ(0)
					letter-spacing: 1px
					color: $main
					background-color: $main
