.page-product-detail
	&.box-product-infomation
		.box-product-wrap
			background-color: #fff
			padding-bottom: r(50px)
			.title-product-wrap
				padding: 14px
				border-bottom: 1px solid #ebe5df
				flex-wrap: wrap
				@media (max-width: 1023.98px)
					flex-direction: column
					align-items: flex-start
				h1
					font-size: r(30px)
					@media (max-width: 575.98px)
						font-size: 1.8rem
				.wrap-share
					@media (max-width: 1023.98px)
						margin-top: r(10px)
		.product-wrap
			margin-top: r(30px)
			.product-img-wrap
				position: sticky
				top: 135px
				.product-img-main
					.img
						transition: .3s all ease-in-out
						a
							+img-ratio(445/675, contain)
				.product-img-thumb
					padding: 0 r(41px) 0px r(58px)
					margin-top: r(47px)
					@media (max-width: 1023.98px)
						padding: 0 r(30px)
					@media (max-width: 767.98px)
						padding: 0 r(15px)
					.wrap-button-slide
						@media (max-width: 1279.98px)
							display: none
					.pagination-mb
						margin-top: 10px
						@media (max-width: 1023.98px)
							margin-bottom: r(30px)
						.swiper-pagination-bullet
							width: 8px
							height: 8px
					.swiper-slide-thumb-active
						.img
							border: 1px solid $main
					.wrap-button-slide
						.btn-prev
							left: 28px
						.btn-next
							right: 12px
					.img
						border: 1px solid $grey-1
						display: flex
						align-items: center
						justify-content: center
						transition: .3s all ease-in-out
						+img-ratio(83/110, contain)
						img
							padding: 8px
			.product-info-main
				padding-right: r(45px)
				@media (max-width: 1023.98px)
					padding: 0 r(26px)
					margin-top: r(30px)
				@media (max-width: 767.98px)
					padding: 0 r(15px)
				.box-price
					width: 100%
					padding: 15px 20px 33px
					border-radius: 4px
					background-color: #edf6f1
					margin-bottom: r(30px)
					@media (max-width: 1279.98px)
						padding-bottom: r(15px)
						margin-bottom: r(15px)
					span
						color: $main
					.wrap-price
						.price
							color: $text
							font-size: r(24px)
							font-weight: 700
							@media (max-width: 767.98px)
								font-size: r(20px)
						.sale
							height: 25px
							background-color: #0f8dc9
							padding: 0 r(20px)
							color: #ffffff
							font-size: r(12px)
							display: flex
							align-items: center
							justify-content: center
							margin-left: r(61px)
							@media (max-width: 767.98px)
								margin-left: r(10px)
					.price-retail
						margin-top: 10px
					.wrap-sku-brand
						margin-top: 10px
						.brand
							margin-left: 23px
							position: relative
							&::before
								content: ''
								position: absolute
								top: 4px
								left: -11px
								height: 9px
								width: 2px
								background-color: #000
				.wrap-infomation
					.info
						.title
							margin-bottom: r(5px)
						.wrap-table
							margin-bottom: r(34px)
							@media (max-width: 1279.98px)
								margin-bottom: r(10px)
							table
								tr
									td
										color: $text
										font-size: r(14px)
										padding: 4.5px 0px
										&:last-child
											padding-left: r(50px)
										&:first-child
											color: $text-2
											font-weight: 600
											white-space: nowrap
					.product-quantity
						@media (max-width: 1600px)
							flex-direction: column
							align-items: flex-start
						span
							margin-right: 23px
							@media (max-width: 1600px)
								margin-bottom: 15px
					.policy
						box-shadow: -1px 3px 25px rgba(0, 0, 0, 0.06)
						border-radius: 4px
						background-color: white
						padding: 0px 20px
						max-width: calc( 100% - 50px)
						margin-left: auto
						@media (max-width: 1600px)
							max-width: 100%
						@media (max-width: 1279.98px)
							margin-top: r(30px)
						ul
							@media (max-width: 1279.98px)
								display: flex
								align-items: center
								flex-wrap: wrap
							li
								padding: 10px 0px
								@media (max-width: 1279.98px)
									flex: 0 0 50%
								@media (max-width: 767.98px)
									flex: 0 0 100%
									&:not(:last-child)
										border-bottom: 1px solid #ebe5df
								@media (min-width: 1280px)
									&:not(:last-child)
										border-bottom: 1px solid #ebe5df
								@media (min-width: 768px)
									&:nth-child(1), &:nth-child(2)
										border-bottom: 1px solid #ebe5df
								.icon
									margin-right: 17px
									flex: 0 0 35px
	&.box-tab-content
		padding-top: 10px
		.box-product
			background-color: #ffffff
			padding: r(31px)
			@media (min-width: 1024px)
				padding-right: 0
			.tabslet-content
				margin-top: r(26px)
				h2
					color: $text
					font-size: r(36px)
					font-weight: 400
					margin-bottom: r(10px)
				p
					margin-bottom: r(20px)
				.content
					padding-right: r(40px)
	&.box-product-other
		padding-top: r(0px)
		.btn-prev
			margin-right: r(30px)
		.btn-next
			margin-left: r(30px)
		.btn-sw-1
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1)
			background-color: #fff
			&:hover
				background-color: $main
