.page-system-store
	.wrap-form-search
		max-width: 1184px
		display: flex
		align-items: center
		gap: 30px
		margin-top: r(15px)
		@media (max-width: 1023.98px)
			gap: 15px
		@media (max-width: 575.98px)
			flex-direction: column
		.form-group
			flex: 1
			@media (max-width: 575.98px)
				width: 100%
			select
				width: 100%
				height: 50px
				border-radius: 4px
				outline: none
				border: 0
				appearance: none
				padding-left: 25px
				background-color: #f8f8f8
				background-image: url('../img/icon/arrow-select.png')
				background-repeat: no-repeat
				background-position: right 20px center
		.frm-btnwrap
			flex: 0 0 194px
			@media (max-width: 575.98px)
				flex: 0 0 100%
				width: 100%
			.frm-btn
				width: 100%
				input
					width: 100%
					padding-right: 15px
	.wrap-image-map
		margin-top: r(60px)
		margin-bottom: r(90px)
		@media (max-width: 1023.98px)
			flex-wrap: wrap
			max-height: unset
			margin-top: r(40px)
		.image
			flex: 0 0 59.786476868%
			@media (max-width: 1023.98px)
				flex: 0 0 100%
			.map
				height: 100%
				iframe
					width: 100%
					height: 100%
					@media (max-width: 1023.98px)
						height: 50vh
					@media (max-width: 767.98px)
						height: 30vh
		.list-store
			flex: 0 0 40.213523%
			overflow: auto
			max-height: 642px
			height: 100%
			background-color: #edf6f1
			@media (min-width: 1024px)
				min-height: 642px
			@media (max-width: 1023.98px)
				flex: 0 0 100%
				max-height: 43vh
			&::-webkit-scrollbar
				width: 5px
				border-radius: 0
			&::-webkit-scrollbar-track
				background: #d9d9da
				border-radius: 0
			&::-webkit-scrollbar-thumb
				background: #5e5e5e
				border-radius: 0
			> ul
				> li
					padding: r(27px) r(34px)
					background-color: #edf6f1
					transition: .3s all ease-in-out
					border-bottom: 1px solid #ebe5df
					@media (max-width: 1279.98px)
						padding: r(25px)
					&:hover
						background-color: $main
						.title
							color: white
						.list-address
							li
								span
									color: white
								img
									filter: brightness(1)
					.title
						color: $text
						font-weight: 600
						text-transform: uppercase
						margin-bottom: r(17px)
						transition: .3s all ease-in-out
						@media (max-width: 1279.98px)
							margin-bottom: r(10px)
					.list-address
						li
							display: flex
							align-items: center
							&+li
								margin-top: r(8px)
							span
								transition: .3s all ease-in-out
							img
								margin-right: 10px
								filter: brightness(0)
								transition: .3s all ease-in-out
								transition: .3s all ease-in-out
