.page-faq-main
	.content-right
		.content
			p
				margin-bottom: r(25px)
			h2
				color: $text
				font-size: r(20px)
				font-weight: 700
				margin-top: r(10px)
				margin-bottom: r(20px)
			ul
				li
					&+li
						margin-top: r(15px)