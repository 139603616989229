footer
	padding-top: r(120px)
	> .footer-wrap
		background-color: #f5f5f5
	.wrap-footer
		padding: r(50px) 0px
		@media (max-width: 1023.98px)
			padding: r(20px) 0px
		[class*='col']
			@media (max-width: 1023.98px)
				margin-bottom: r(30px)
	.title-footer
		margin-bottom: r(19px)
	.wrap-footer-1
		max-width: 390px
	.list-footer
		li
			&+li
				margin-top: 5px
			a
				color: #666666
				letter-spacing: 0.8px
				transition: .3s all ease-in-out
				&:hover
					color: $main
					text-decoration: underline
	.desc
		p
			color: #666666
			font-size: r(14px)
			letter-spacing: 0.7px
		img
			margin-top: r(25px)
	.copyright
		height: 60px
		background-color: #0f8dc9
		.container
			@media (max-width: 575.98px)
				flex-direction: column
				justify-content: center
		span
			color: $grey-1
			font-size: r(12px)
			letter-spacing: 0.6px
		ul
			li
				&+li
					&::before
						content: '.'
						color: white
						padding: 0 10px
						@media (max-width: 575.98px)
							padding: 0 3px
				a
					color: $grey-1
					font-size: r(12px)
					letter-spacing: 0.6px
					&:hover
						text-decoration: underline
