.page-news-list
	padding-bottom: r(60px)
	.wrap-news-post
		margin-bottom: r(30px)
		.title-nav
			margin-bottom: r(30px)
			h2
				font-weight: 400
		.item-news-main
			margin-bottom: r(10px)
			.content
				padding: 13px 47px 36px
				min-height: 120px
				@media (max-width: 1279.98px)
					padding: 10px 30px 17px
				h3
					a
						font-size: r(14px)
						font-weight: 400
						@media (max-width: 1279.98px)
							font-size: 1.1rem
