.check-out
	@media (max-width: 1023.98px)
		padding-top: r(30px) !important
		padding-bottom: r(30px) !important
	.button-mobile-cart
		display: none
		@media (max-width: 1023.98px)
			display: flex
			.wrap-button-next-cart
				width: 100%
			.cart-button
				display: grid
				grid-template-columns: 1fr 2fr
				grid-template-rows: 1fr
				grid-gap: 10px
	.cart-wrapper
		background: #ffffff
		box-shadow: 0 5px 25px 0 rgba(0,0,0,.05)
		padding-top: 23px
		padding-left: 30px
		padding-right: 30px
		padding-bottom: 50px

		.admin-title
			margin-bottom: r(30px)
			border-bottom: 3px solid #ebebeb
			padding-bottom: r(10px)
			.text
				display: flex
				align-items: center
				font-size: r(24px)
				font-weight: 700
				line-height: r(18px)
				p
					padding-right: 10px
					text-transform: uppercase
				span
					padding-right: 10px
					color: red
				@media screen and ( max-width: 991px)
					font-size: .8rem
		.wrapper
			width: 100%
			border: 1px solid #e1e1e1
			border-radius: 5px

			.carttable
				width: 100%
				thead
					tr
						th
							background: #f0f0f0
							line-height: 40px
							height: 50px
							color: #333
							font-size: r(20px)
							font-weight: 700
							text-align: center
							text-transform: uppercase
							&:first-child
								padding-left: 2rem
								text-align: left
						&:first-child
							th
								&:first-child
									border-top-left-radius: 5px
						&:last-child
							th
								&:last-child
									border-top-right-radius: 5px
					@media screen and ( max-width: 991px)
						display: none
				tbody
					tr
						td
							padding: 1rem
							width: 20%
							font-weight: 500
							.product-item
								display: flex
								flex-direction: row
								align-items: center
								justify-content: flex-start
								margin-bottom: 0
								.image
									width: 80px
									a
										+img-ratio(1)
								.caption
									margin-left: r(60px)
									margin-bottom: 1rem
									font-size: 1rem
									line-height: r(26px)
									.title
										a
											text-align: center
											display: block
											margin-bottom: .25rem
											color: #333
											font-size: 1rem
									.remove-item
										display: flex
										align-items: center
										justify-content: flex-star
										.lnr
											display: block
											margin-right: .5rem
											height: 16px
											color: #ec1f27
											font-size: 16px
										span
											color: #999
											font-size: 12px
											padding-top: 2px
											margin-left: 5px
											&:hover
												color: $main-hover
								@media screen and ( max-width: 375px)
									flex-direction: column
							.price
								display: flex
								flex-direction: column
								align-items: center
								justify-content: center
								.current
									color: #333
									font-size: r(18px)
									line-height: 22px
									font-weight: 700
									margin-bottom: 5px
								.old-wrap
									display: flex
									.old-price
										padding-right: 12px
										font-size: 1rem
										color: red
										line-height: 22px
										text-decoration: line-through
									.discount
										padding-left: 12px
										position: relative
										font-weight: 600
										font-size: 1rem
										line-height: 22px
										color: red
										&::before
											content: ''
											position: absolute
											left: 0
											width: 1px
											height: 100%
											background: #e1e1e1
							.quantity-wrapper
								.item-quantity
									width: 100%
									.input-group
										border-width: 1px
										border-color: rgb(225, 225, 225)
										border-style: solid
										background-color: rgb(255, 255, 255)
										width: 160px
										height: 40px
										margin: 0 auto
										display: flex
										align-items: center
										border-radius: 5px
										input
											border: none
											width: 80px
											height: 100%
											text-align: center
										.btn-dec,
										.btn-inc
											min-width: 40px
											height: 40px
											background: #e5e5e5
											font-size: r(24px)
											font-weight: 700
											display: flex
											align-items: center
											justify-content: center
											cursor: pointer
										.btn-dec
											border-top-left-radius: 5px
											border-bottom-left-radius: 5px
										.btn-inc
											border-top-right-radius: 5px
											border-bottom-right-radius: 5px
										.form-control
											padding: 0
											border: none
											text-align: center
											&:focus
												box-shadow: none
										@media screen and ( max-width: 991.98px )
											width: 100%
											input
												width: 100%
							.total
								color: #333
								font-size: r(18px)
								font-weight: 700
								text-align: center
							&:first-child
								width: 40%
						&:first-child
							td
								&:last-child
									border-bottom-left-radius: 5px
						&:last-child
							td
								padding-bottom: 2rem
								&:last-child
									border-bottom-right-radius: 5px
						@media screen and ( max-width: 991px)
							display: flex
							flex-direction: column
							flex-wrap: wrap
							td
								padding: .9rem
								width: 100%
								.price
									align-items: flex-start
								&:first-child
									width: 100%
								.total
									text-align: left
							&:last-child
								td
									padding-bottom: .9rem
		.total-wrapper
			display: flex
			flex-direction: column
			align-items: flex-end
			justify-content: center
			padding-top: r(30px)
			.total-table
				width: 100%
				tbody
					tr
						td
							padding: 0 .5rem
							font-size: r(18px)
							font-weight: 700
							line-height: 40px
						&:last-child
							td
								&:last-child
									color: red
			.btn-right
				padding-top: r(50px)
				display: flex
				align-items: center
				justify-content: flex-end
				.btn-primary
					transition: .3s all ease-in-out
					width: 280px
					padding: r(13px)
					display: flex
					align-items: center
					justify-content: center
					border-radius: 5px
					font-size: 1rem
					text-transform: uppercase
					.lnr
						padding-right: 10px
					span
						font-size: 1rem
						font-weight: 500
					&.red
						background: red
						color: $white
						.lnr
							padding-right: 0
							padding-left: 10px
						&:hover
							background: #ebebeb
							.lnr,
							span
								color: #000
					&.gray-6
						background: #ebebeb
						@media screen and ( max-width: 375px)
							margin-bottom: .9rem
						.lnr,
						span
						&:hover
							background: $main
							.lnr,
							span
								color: $white
				a:not(:last-child)
					margin-right: r(20px)
				@media screen and ( max-width: 575px)
					flex-direction: column
					max-width: auto
					a:not(:last-child)
						margin-right: 0
			@media screen and ( max-width: 991px)
				align-items: flex-start
	&.step-2
		.btn-checkout
			color: white
			text-transform: uppercase
			font-weight: 700
		.payment-form
			@media (max-width: 1023.98px)
				&+.payment-form
					margin-top: 30px
		.Module-194
			.ModuleContent
				position: sticky
				top: 150px
		.button-mobile-cart
			@media (max-width: 1023.98px)
				position: fixed
				bottom: 0
				left: 0
				width: 100%
				background-color: #fff
				z-index: 99
				padding: 5px 15px
				flex-wrap: wrap
				.wrap-button-next-cart
					margin-top: 0 !important
					padding: 5px 0px
					.btn
						width: 100% !important
						max-width: 100% !important
				.btn-checkout
					margin-left: 0px !important
				.total-price-cart
					flex: 0 0 100%
					max-width: 100%
					.price
						font-weight: bold
						font-size: r(24px)
						display: flex
						align-items: center
						justify-content: flex-end
						padding: 10px 0px
					.total
						color: $main
						margin-left: 15px
				.btn-right
					flex: 0 0 100%
					max-width: 100%
		label.error
			display: block
			color: red
			font-size: .8rem
			position: absolute
			top: -18px
			right: 0px
			animation: AlertError 0.4s 1 linear!important
			@media (max-width: 767.98px)
				bottom: -20px
				top: auto
		#divCompany
			width: 100%
		.wrap-button-next-cart
			@media (max-width: 767.98px)
				margin-top: 10px
			.btn
				@media (max-width: 1199.98px)
					width: 195px
				@media (max-width: 575.98px)
					width: 100%
		.select-icon
			position: relative
			&::before
				content: "\f078"
				color: #000
				font-size: 1rem
				font-weight: 400
				font-family: "Font Awesome 5 Pro"
				position: absolute
				top: 50%
				transform: translateY(-50%)
				right: 20px
				z-index: 2
		.check-text
			color: #000
			font-size: r(14px)
			font-weight: 400
			margin-top: 2px
		.xuat-hoa-don-input
			padding: 0 15px
			margin-top: r(20px)
			width: 100%
			margin-bottom: 22px
			@media (max-width: 1023.98px)
				margin-bottom: 0
			label
				color: #000
				font-size: 1rem
				font-weight: 400
				display: flex
				&::before
					content: 'check_box_outline_blank'
					font-family: 'Material Icons'
					color: #000
					margin-top: -2px
					margin-right: 11px
					font-size: 18px
					font-weight: 400
			input
				display: none
				&:checked ~ label::before
					content: 'check_box'
		#divCompany
			width: 100%
			padding: 0 15px
		.massive-wrapper
			background: $white
			box-shadow: 0 0 20px rgba(33,33,33,.1)
			padding: 21px 27px 54px
			background: #fff
			border-radius: 10px
			@media (max-width: 575.98px)
				box-shadow: none
				padding: 0px 0px 0px
			.carttitle
				margin-bottom: r(25px)
			.receive-address-btn
				@media (max-width: 1023.98px)
					display: none
			.receive-address
				.cart-form
					.group
						.form-group
							display: flex
							align-items: center
							justify-content: space-between
							@media (max-width: 767.98px)
								flex-wrap: wrap
							.label
								@media (max-width: 767.98px)
									margin-bottom: 9px
								label
									font-size: 1rem
									line-height: 22px
									span
										color: red
								@media screen and ( max-width: 1279.98px )
									flex: 1 0 30%
								@media (max-width: 767.98px)
									flex: 0 0 100%
							.input
								width: 490px
								height: 50px
								position: relative
								@media screen and ( max-width: 1279.98px )
									flex: 1 0 70%
								@media (max-width: 767.98px)
									flex: 0 0 100%
								input,
								select
									width: 100%
									height: 100%
									border: none
									background: #f0f0f0
									padding-left: 15px
									border-radius: 5px
								select
									-webkit-appearance: none
									-moz-appearance: none
									-ms-appearance: none
									appearance: none
									position: relative
									background-image: url('../img/icons/select.png')
									background-repeat: no-repeat
									background-position: 95%
									&::-ms-expand
										display: none
								label

							.textarea
								width: 490px
								height: 70px
								position: relative
								@media screen and ( max-width: 1279.98px )
									flex: 1 0 70%
								textarea
									width: 100%
									height: 100%
									border-radius: 5px
									border: none
									background: #f0f0f0
									padding-left: 15px
									padding-top: 15px
									&::-webkit-resizer
										background-color: transparent
									&::-webkit-resizer
										background-image: url('../img/icons/textarea.png')
										background-repeat: no-repeat
						.form-group + .form-group
							margin-top: 20px
						&.other-address
							margin-top: 51px
							@media (max-width: 1023.98px)
								margin-top: r(35px)
							.check-group
								margin-bottom: 30px
								#btn-ttmh
									.click-more-address
										color: #000
										font-weight: 700
										position: relative
										display: inline-block
										&::before
											content: ''
											position: absolute
											top: 112%
											left: 0
											height: 1px
											background-color: #000
											width: 100%
							.user-info
								display: none
								margin-bottom: 30px
			.payment-info
				padding-top: 30px
				@media (max-width: 1199.98px)
					padding-top: r(45px)
				.cart-form
					padding-bottom: 37px
					@media (max-width: 1023.98px)
						padding-bottom: 0px
					.form-group
						display: flex
						align-items: center
						input[type="radio"]
							cursor: pointer
							appearance: none
							background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAB/klEQVRoQ+2XT2rCUBDGR4lJILhK1YW4UHHx8Ai9QAulvUG7ak/QnsKeoF21N2gptBfoEeQtRF2IC7VZiZA/EsqzCkW0SXh5dAwTeIvATPJ983svM8lBxq9cxv0BGTx0wkSQCCKvAG1R5IAi5RHByBIhDyCCyAFFyiOCkSVCHkAEkQOKlEcEI0uEPIAIygLq9XrGcrm8BIAzADheP+8TAN7CMHxqt9u+7Dv+yldKkHN+CwAd27bBNM3VEpfruqvlOI64vWOM3asyqcwg5/zdsqyTcrkMhmHs1O/7PkwmE1gsFh+MsVMVJpUYFOQsy+rUarVYmkejkTCphGTqBrvdrp7P571GowG6rscy6HkeDIdD0DTNbLVaXqykmEGpG+ScX9u2/VAqlWJK+AmbzWbiTN4wxh4TJUYEqzD4Uq1Wz4vFYiKd8/kcxuPxK2PsIlHiPxj8ajabdqFQSKQzCALo9/sOY+woUSIZTLNcAMA5z/wWzfZHZj2aufV6fW+D3940ouEPBgMIw9BIe3RL/SsqxGe60W/obEa1SqWyt+GLBj+dTg9vVPtlMrvD9sbkenS72vW7pGnac9qj2fb5VnIGU+48Uo8jg1LlQ5BMBBFAkJJABKXKhyCZCCKAICWBCEqVD0EyEUQAQUoCEZQqH4JkIogAgpQEIihVPgTJmSf4DWNIwDkYSBQTAAAAAElFTkSuQmCC')
							background-position: 50%
							background-repeat: no-repeat
							width: 20px
							height: 20px
							&:checked
								background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAACUElEQVRoQ+2XP4gaURDGv23OtPs2vY1op1iqlYKQYEwaQSutEhAbhaTxtPJPcwFtVEgq7QSbi5EEBK3UUrRTbOzj2zaXZoNLDkLw3Ag7sC5vu4XHzHzfb97OrASbP5LN9UEIvHbCgqAgaHEHRItaHJBheYKgoUUWPyAIWhyQYXmCoKFFFj8gCFockGF5gqChRRY/IAiaAMjBGEtrmvYKQOhPvJkkSV85510Av0zI8WQIUoKMsfeSJN3l83n4fD54vV69kPV6jdVqhWazCU3TPnDOP1KJJBMoy/K3SCTyolarwePxnKx/u92iWCxiMpl8V1X1JYVIEoFHcuFw+G4wGOg1LxYL9Ho9jMdj/T0ajSKdTiMQCOjviUQC0+mUhCSFwBtFUR7m8zncbjfK5TJardZJOLlcDpVKBZvNBqFQCIfD4RmABzNJmi6QMfa2UCh8KpVKOrlYLHa23tFopJOsVqtoNBrvOOefLS1QluX7brf7Oh6PI5vNot/vn603lUqh3W5jOBwik8l8UVX1jdUF/lgul4rT6YTL5QLn/Gy9jDHsdjvs93v4/f6DqqrPhcALHDD9Dl7aoslkEp1O53pa1PYfGQAORVF+zmYzfcD/z5g4DvxgMHgcEw6zVzfTW/R4Pew+6PVPwOOqVq/X9YF/6jkO+Nvb2+tb1R7F2HrZ/ovYDWMs88TvUs/s1ezfTiG5gxeMKfKjQiC5xcQJBEFig8nDC4LkFhMnEASJDSYPLwiSW0ycQBAkNpg8vCBIbjFxAkGQ2GDy8IIgucXECWxP8Dd10wJIhynNlgAAAABJRU5ErkJggg==')
						label
							width: calc( 100% -40px )
							background: $white
							border: solid 1px #e1e1e1
							border-radius: 5px
							margin-left: 23px
							padding: 15px 15px 15px 25px
							.wrap
								display: flex
								align-items: center
								.des
									margin-right: 20px
									color: #7c7c7c
								select
									width: 200px
									border: 0
									border-bottom: 1px solid #e1e1e1
					.form-group + .form-group
						margin-top: 20px
					.payment-method
						.title-ck
							span
								margin-bottom: 4px
								text-transform: uppercase
								display: block
						.method-info
							table
								tr
									@media (max-width: 767.98px)
										display: flex
										flex-direction: column
									td
										@media (max-width: 767.98px)
											flex: 0 0 100%
											max-width: 100%
										&:first-child
											padding-right: 27px
											color: #000
						.select-group
							display: flex
							align-items: center
							input[type="radio"]
								cursor: pointer
								appearance: none
								background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAB/klEQVRoQ+2XT2rCUBDGR4lJILhK1YW4UHHx8Ai9QAulvUG7ak/QnsKeoF21N2gptBfoEeQtRF2IC7VZiZA/EsqzCkW0SXh5dAwTeIvATPJ983svM8lBxq9cxv0BGTx0wkSQCCKvAG1R5IAi5RHByBIhDyCCyAFFyiOCkSVCHkAEkQOKlEcEI0uEPIAIygLq9XrGcrm8BIAzADheP+8TAN7CMHxqt9u+7Dv+yldKkHN+CwAd27bBNM3VEpfruqvlOI64vWOM3asyqcwg5/zdsqyTcrkMhmHs1O/7PkwmE1gsFh+MsVMVJpUYFOQsy+rUarVYmkejkTCphGTqBrvdrp7P571GowG6rscy6HkeDIdD0DTNbLVaXqykmEGpG+ScX9u2/VAqlWJK+AmbzWbiTN4wxh4TJUYEqzD4Uq1Wz4vFYiKd8/kcxuPxK2PsIlHiPxj8ajabdqFQSKQzCALo9/sOY+woUSIZTLNcAMA5z/wWzfZHZj2aufV6fW+D3940ouEPBgMIw9BIe3RL/SsqxGe60W/obEa1SqWyt+GLBj+dTg9vVPtlMrvD9sbkenS72vW7pGnac9qj2fb5VnIGU+48Uo8jg1LlQ5BMBBFAkJJABKXKhyCZCCKAICWBCEqVD0EyEUQAQUoCEZQqH4JkIogAgpQEIihVPgTJmSf4DWNIwDkYSBQTAAAAAElFTkSuQmCC')
								background-position: 50%
								background-repeat: no-repeat
								width: 20px
								height: 20px
								@media screen and ( max-width: 767.67px )
									display: none
								&:checked
									background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAACUElEQVRoQ+2XP4gaURDGv23OtPs2vY1op1iqlYKQYEwaQSutEhAbhaTxtPJPcwFtVEgq7QSbi5EEBK3UUrRTbOzj2zaXZoNLDkLw3Ag7sC5vu4XHzHzfb97OrASbP5LN9UEIvHbCgqAgaHEHRItaHJBheYKgoUUWPyAIWhyQYXmCoKFFFj8gCFockGF5gqChRRY/IAiaAMjBGEtrmvYKQOhPvJkkSV85510Av0zI8WQIUoKMsfeSJN3l83n4fD54vV69kPV6jdVqhWazCU3TPnDOP1KJJBMoy/K3SCTyolarwePxnKx/u92iWCxiMpl8V1X1JYVIEoFHcuFw+G4wGOg1LxYL9Ho9jMdj/T0ajSKdTiMQCOjviUQC0+mUhCSFwBtFUR7m8zncbjfK5TJardZJOLlcDpVKBZvNBqFQCIfD4RmABzNJmi6QMfa2UCh8KpVKOrlYLHa23tFopJOsVqtoNBrvOOefLS1QluX7brf7Oh6PI5vNot/vn603lUqh3W5jOBwik8l8UVX1jdUF/lgul4rT6YTL5QLn/Gy9jDHsdjvs93v4/f6DqqrPhcALHDD9Dl7aoslkEp1O53pa1PYfGQAORVF+zmYzfcD/z5g4DvxgMHgcEw6zVzfTW/R4Pew+6PVPwOOqVq/X9YF/6jkO+Nvb2+tb1R7F2HrZ/ovYDWMs88TvUs/s1ezfTiG5gxeMKfKjQiC5xcQJBEFig8nDC4LkFhMnEASJDSYPLwiSW0ycQBAkNpg8vCBIbjFxAkGQ2GDy8IIgucXECWxP8Dd10wJIhynNlgAAAABJRU5ErkJggg==')
								&:checked ~ label
									border: 1px solid $main
							label
								width: 100%
								margin-left: 23px
								border: solid 1px #e1e1e1
								transition: .3s all ease-in-out
								@media (max-width: 767.98px)
									margin-left: 0px
								.method-info
									width: 100%
									display: flex
									align-items: center
									@media (max-width: 767.98px)
										flex-wrap: wrap
									.img
										height: 100px
										display: flex
										align-items: center
										justify-content: center
										border-right: solid 1px #e1e1e1
										flex: 0 0 100px
										max-width: 100%
										@media (max-width: 767.98px)
											flex: 0 0 100%
											max-width: 100%
									.text-wrap
										padding: 15px 0px
										padding-left: 30px
										flex: 1
										@media (max-width: 767.98px)
											flex: 0 0 100%
											max-width: 100%
										@media (max-width: 575.98px)
											padding: 0 10px !important
											padding-bottom: 10px !important
						&:last-child
							.select-group
								label
									.method-info
										.img
											height: 130px
											@media (max-width: 575.98px)
												height: 90px
										.text-wrap
											padding-top: 18px
											padding-bottom: 10px
						&:first-child
							.text-wrap
								.text
									@media (max-width: 767.98px)
										text-align: center
						+ .payment-method
							margin-top: 20px
			.receive-address-btn
				.btn-right
					display: flex
					justify-content: flex-end
					.btn-primary
						display: flex
						align-items: center
						justify-content: center
						width: 200px
						height: 50px
						border-radius: 5px
						text-transform: uppercase
						transition: all .5s
						em
							font-size: r(20px)
						&.gray-6
							background: #ebebeb
							em
								padding-right: 10px
							&:hover
								background: red
								color: $white
						&.red
							background: red
							color: $white
							margin-left: 20px
							em
								padding-left: 10px
							&:hover
								background: #ebebeb
								color: #000
		.minicart
			padding-bottom: 11px
			@media (max-width: 1023.98px)
				margin-top: 30px
			.carttitle
				@media (max-width: 575.98px)
					margin-bottom: 0
				a
					em
						color: $main
						font-size: r(18px)
						font-weight: 400
			.carttable
				.product
					display: flex
					align-items: center
					padding: 20px 0
					border-bottom: thin solid #ebebeb
					.productimg
						flex: 0 0 72px
						align-items: center
						justify-content: center
						display: flex
						a
							display: block
							width: 100%
							height: 65px
							@media (max-width: 1024.98px)
								height: 100px
							@media (max-width: 767.98px)
								width: 100%
								height: auto
						img
							width: 100%
							height: 100%
							object-fit: contain
							@media screen and ( max-width: 375px )
								padding: 10px 5px
					.content
						display: flex
						margin-left: 15px
						justify-content: space-between
						width: 100%
						@media screen and ( max-width: 991px )
							flex: 1
						@media screen and ( max-width: 576px )
							flex-direction: column
						.productdetail
							@media (max-width: 1199.98px)
								display: flex
								align-items: center
						.productname
							font-size: r(16px)
							font-weight: 400
							max-width: 200px
							padding-top: 5px
							@media (max-width: 1199.98px)
								font-size: r(14px)
						.amount
							font-size: r(16px)
							display: flex
							justify-content: center
							align-items: flex-end
							white-space: nowrap
							flex-direction: column
							font-weight: 400
							@media (max-width: 1199.98px)
								font-size: r(14px)
							@media screen and ( max-width: 576px )
								margin-top: 0px
								flex-direction: row
								justify-content: flex-start
								.price
									margin-right: 5px
								.count
									margin-right: 5px
							.total
								font-weight: 800
								font-size: r(18px)
								@media (max-width: 1199.98px)
									font-size: r(16px)
								@media screen and ( max-width: 576px )
									position: relative
									margin-left: auto
				.bottom
					padding: 15px 0px
					.text
						display: flex
						justify-content: space-between
						align-items: center
						&:last-child
							.value
								color: $main
						.label
							font-size: r(18px)
							font-weight: 700
							line-height: 40px
						.value
							font-size: r(18px)
							font-weight: 800
							line-height: 26px
							&.discount-total,
							&.shipping-total
								font-weight: normal
							&.order-total
								width: auto
								padding: 0
								font-size: r(18px)
								line-height: 40px
								margin: 0

.checkout-complete-section
	.completed-wrapper
		width: 100%
		padding-top: 14px
		padding-left: 26px
		padding-right: 34px
		padding-bottom: 50px
		background: $white
		.title
			display: flex
			align-items: center
			font-size: r(18px)
			line-height: 24px
			color: #000
			font-weight: 700
			padding-bottom: 8px
			text-transform: uppercase
			border-bottom: solid 3px #ebebeb
		img
			padding-right: 18px
		.article-content
			display: flex
			flex-direction: column
			justify-content: center
			width: 100%
			padding-top: 50px
			.content
				padding-bottom: 30px
				p
					line-height: 22px
					.email
						color: #00c1ef
		.cart-group-button
			margin-bottom: 50px
			.btn-next
				width: 280px
				height: 50px
				border-radius: 5px
				background: #ebebeb
				display: flex
				align-items: center
				justify-content: center
				text-transform: uppercase
				transition: all .3s
				em
					font-size: r(20px)
				span
					padding-left: 10px
				@media screen and ( min-width: 1025px )
					&:hover
						background: $main
						color: $white
.checkout-discount
	margin-top: r(30px)
	margin-bottom: r(30px)
	.admin-title
		display: flex
		align-items: center
		justify-content: space-between
		font-size: r(18px)
		line-height: 18px
		font-weight: 700
		color: #000
		text-transform: uppercase
		padding-bottom: 15px
		margin-bottom: 30px
		border-bottom: solid 3px #ebebeb

	.carttable
		.normal-text
			font-size: r(16px)
			margin-bottom: 15px
			line-height: 22px
	.setting-control
		display: flex
		flex-wrap: wrap
		margin-bottom: 20px
		height: 40px
		@media (max-width: 1023.98px)
			margin-top: 15px
			margin-bottom: 0
		input
			max-width: 100%
			flex: 1
			width: 100%
			height: 100%
			border-radius: 5px
			font-size: r(14px)
			border: none
			padding: 5px 15px
			border: 1px solid $grey-2
			order: 1
			@media (max-width: 1279.98px)
				font-size: r(16px)
		button
			flex: 0 0 130px
			width: 100%
			border: none
			height: 100%
			margin-left: 15px
			order: 2
			color: $main
			background-color: #f3f3f3
			font-size: r(14px)
			text-align: center
			border-radius: 5px
			letter-spacing: 1.4px
			text-transform: uppercase
			font-weight: 700
			transition: .3s all ease-in-out
			@media (max-width: 1279.98px)
				flex: 0 0 90px
			@media (max-width: 575.98px)
				flex: 0 0 100px
			&:hover
				background: $main
				letter-spacing: 2px
				color: $main
		.code
			font-size: 18px
			padding: 5px 15px
			height: 40px
			border: 1px solid $main
			margin-right: 15px
			font-weight: 700
			color: $main
		.coupon-apply-error
			max-width: 100%
			flex: 0 0 100%
			width: 100%
			font-size: 12px
			font-weight: 400
			color: red
			font-style: italic
			order: 3
	.check-text
		margin-bottom: 20px
		color: red
.carttitle
	display: flex
	align-items: center
	color: $main
	font-size: r(24px)
	font-weight: 500
	justify-content: space-between
	text-transform: uppercase
	padding-bottom: 5px
	margin-bottom: r(10px)
	border-bottom: solid 1px $grey-2
	@media (max-width: 767.98px)
		font-size: r(20px)
.search-product-wrapper
	.product-item
		.pro-img
			a
				+img-ratio(.4)
.recover-password
	.login-box
		padding: 30px
		background: $white
		box-shadow: 0 0 20px rgba(33,33,33,.2)
	.card
		.login-card-body
			.login-box-msg
				padding-bottom: 7px
				font-size: r(18px)
				font-weight: 700
			.input-group
				position: relative
				width: 100%
				margin-bottom: 20px
				input
					width: 100%
					height: 50px
					padding-left: 50px
					border: none
					border-radius: 5px
					background: #f0f0f0
				.input-group-icon
					position: absolute
					top: 50%
					left: 10px
					color: red
					font-size: r(20px)
					display: flex
					align-items: center
					justify-content: center
					transform: translateY(-50%)
				.fa
					position: absolute
					left: 0
					bottom: -20px
					color: red
				.captcha
					.RadCaptcha
						margin-bottom: 20px
						span
							color: red
						div
							div
								display: flex
								align-items: center
								margin-bottom: 20px
								.rcRefreshImage
									margin-left: 20px
									font-size: 0
									&::after
										content: 'replay'
										font-family: Material Icons
										font-size: r(30px)
										color: red
			.buttom
				width: 100%
				input
					width: 100%
					height: 50px
					border: none
					background: red
					color: $white
					text-transform: uppercase
