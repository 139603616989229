body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }

@media (min-width: 1460px) {
  .container {
    max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  ::-webkit-scrollbar-track {
    background-color: rgba(43, 43, 43, 0.08); }
  ::-webkit-scrollbar {
    width: 6px;
    height: 2px; }
  ::-webkit-scrollbar-thumb {
    background: #009035; } }

::selection {
  background: #009035;
  color: white; }

html {
  font-family: 'Manrope', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  color: #333333;
  margin-top: 0;
  margin-bottom: 0; }

body, html {
  font-size: 14px; }
  @media (min-width: 1024px) {
    body, html {
      font-size: 15px; } }
  @media (min-width: 1280px) {
    body, html {
      font-size: 16px; } }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

main {
  padding-top: 120px; }
  @media (max-width: 1023.98px) {
    main {
      padding-top: 70px; } }
  @media (max-width: 575.98px) {
    main {
      padding-top: 60px; } }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  height: auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img:hover img {
    transform: scale(1.05) translateZ(0); }
  .zoom-img img {
    transition: .3s all ease-in-out; }

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(40px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fade-in2 {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px); }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0px); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(45px);
    filter: blur(5); }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0); } }

@keyframes blurText {
  0% {
    filter: blur(2px); }
  100% {
    filter: blur(2px); } }

@keyframes loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes loading1-circle {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -600; } }

#load {
  width: 50px;
  display: inline-block;
  animation: loading 2s linear infinite; }

#loading-inner {
  stroke-dashoffset: 1;
  stroke-dasharray: 300;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  animation: loading1-circle 2s linear infinite;
  stroke: #009035;
  fill: transparent; }

@keyframes AlertError {
  0% {
    transform: translateX(30px); }
  20% {
    transform: translateX(-30px); }
  40% {
    transform: translateX(15px); }
  60% {
    transform: translateX(-15px); }
  80% {
    transform: translateX(8px); }
  100% {
    transform: translateX(0px); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.8); } }

@keyframes lagging {
  0% {
    transform: rotate(-10deg); }
  50% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(-10deg); } }

.w-100 {
  width: 100%; }

.mx-auto {
  margin: 0 auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.d-flex {
  display: flex; }

.flcc {
  display: flex;
  flex-direction: column; }

.flc {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.jcb {
  justify-content: space-between; }

.jcc {
  justify-content: center; }

.ovh {
  overflow: hidden; }

.jcs {
  justify-content: flex-start; }

.jce {
  justify-content: flex-end; }

.aic {
  align-items: center; }

.relative {
  position: relative; }

.ais {
  align-items: flex-start; }

.aie {
  align-items: flex-end; }

.tcc {
  text-align: center; }

.tcr {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.button-prev, .button-next {
  cursor: pointer; }

.line-bottom {
  border-bottom: 1px solid rgba(0, 144, 53, 0.28); }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.color-white {
  color: white; }

.color-gray {
  color: #999999; }

.color-text {
  color: #333333; }

.color-main {
  color: #009035; }

.f-12 {
  font-size: 0.75rem; }

.f-14 {
  font-size: 0.875rem; }

.f-16 {
  font-size: 1rem; }

.f-18 {
  font-size: 1.125rem; }

.f-20 {
  font-size: 1.25rem; }

.f-22 {
  font-size: 1.375rem; }

.f-24 {
  font-size: 1.5rem; }

.f-25 {
  font-size: 1.5625rem; }

.f-28 {
  font-size: 1.75rem; }

.f-30 {
  font-size: 1.875rem; }

.f-36 {
  font-size: 2.25rem; }

.f-38 {
  font-size: 2.375rem; }

.f-40 {
  font-size: 2.5rem; }

.f-60 {
  font-size: 3.75rem; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.fw-800 {
  font-weight: 800; }

.fw-900 {
  font-weight: 900; }

.line-30 {
  line-height: 30px; }

.p-0 {
  padding: 0; }

.pl-0 {
  padding-left: 0; }

.pt-1 {
  padding-top: 1rem; }

.pt-2 {
  padding-top: 2rem; }

.pt-3 {
  padding-top: 3rem; }

.pr-0 {
  padding-right: 0; }

.m-0 {
  margin: 0; }

.mt-5 {
  margin-top: 0.3125rem; }

.mt-10 {
  margin-top: 0.625rem; }

.mt-20 {
  margin-top: 1.25rem; }

.mt-30 {
  margin-top: 1.875rem; }

.mb-40 {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 1.875rem; }

.mb-20 {
  margin-bottom: 1.25rem; }

.text-left {
  text-align: left; }

.transition {
  transition: all 1s cubic-bezier(0.22, 0.44, 0, 1); }

.type-none {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.mh-100 {
  min-height: 100vh; }

.ovh {
  overflow: hidden; }

.tab-overlow {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .tab-overlow li:first-child {
    margin-left: auto; }
  .tab-overlow li:last-child {
    margin-right: auto; }

.border-gray {
  border: 1px solid #e5e5e5; }

.background-grey {
  background-color: #f5f5f5; }

.btn {
  cursor: pointer;
  user-select: none; }
  .btn.btn-view-all {
    width: 12.125rem;
    height: 3.125rem;
    border-radius: 4px;
    background-color: #009035;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all ease-in-out; }
    @media (max-width: 575.98px) {
      .btn.btn-view-all {
        width: 130px;
        height: 40px; } }
    .btn.btn-view-all:hover {
      box-shadow: 2px 2px 5px #009035; }
      .btn.btn-view-all:hover em {
        animation: pulse .3s infinite alternate linear; }
    .btn.btn-view-all span {
      color: #ffffff;
      font-size: 0.875rem;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      line-height: 1; }
      @media (max-width: 575.98px) {
        .btn.btn-view-all span {
          font-size: 0.75rem; } }
    .btn.btn-view-all em {
      font-size: 0.9375rem;
      color: white;
      margin-left: 10px; }
  .btn.btn-sw-1 {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f1f1;
    transition: .3s all ease-in-out; }
    @media (max-width: 1279.98px) {
      .btn.btn-sw-1 {
        width: 50px;
        height: 50px; } }
    @media (max-width: 767.98px) {
      .btn.btn-sw-1 {
        width: 40px;
        height: 40px; } }
    .btn.btn-sw-1 em {
      font-size: 1.25rem;
      transition: .3s all ease-in-out; }
    @media (max-width: 767.98px) {
      .btn.btn-sw-1 img {
        transform: scale(0.7); } }
    .btn.btn-sw-1:hover {
      background-color: #009035; }
      .btn.btn-sw-1:hover em {
        color: white; }
      .btn.btn-sw-1:hover img {
        filter: brightness(0) invert(1); }
  .btn.btn-sw-2 {
    color: #cccccc;
    transition: .3s all ease-in-out; }
    .btn.btn-sw-2 em {
      font-size: 2rem; }
    .btn.btn-sw-2:hover {
      color: #009035; }
  .btn.btn-sw-3 {
    display: flex;
    align-items: center;
    justify-content: center; }
    .btn.btn-sw-3 em {
      color: #e5e5e5;
      font-size: 2rem; }
  .btn.btn-detail {
    height: 40px;
    border-radius: 8px;
    background-color: #e5e5e5;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all ease-in-out; }
    @media (max-width: 575.98px) {
      .btn.btn-detail {
        height: 35px; } }
    .btn.btn-detail:hover {
      box-shadow: 2px 2px 5px #e5e5e5; }
      .btn.btn-detail:hover img {
        animation: pulse .3s infinite alternate linear; }
    .btn.btn-detail span {
      font-size: 0.875rem;
      color: #333333;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: 1.4px; }
      @media (max-width: 1279.98px) {
        .btn.btn-detail span {
          font-size: 0.75rem; } }
    .btn.btn-detail img {
      margin-left: 8px; }
  .btn.btn-buy-now {
    border-radius: 4px;
    background-color: #009035; }
    .btn.btn-buy-now em {
      font-size: 1.5rem;
      color: white;
      margin-right: 1.4375rem; }
    .btn.btn-buy-now span {
      text-transform: uppercase;
      font-size: 14px; }
  .btn.btn-contact {
    height: 40px;
    border-radius: 8px;
    padding: 0 8px;
    background-color: #009035;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all ease-in-out;
    will-change: box-shadow;
    color: white; }
    @media (max-width: 575.98px) {
      .btn.btn-contact {
        height: 35px; } }
    .btn.btn-contact:hover {
      box-shadow: 2px 2px 5px #009035; }
      .btn.btn-contact:hover img {
        animation: pulse .3s infinite alternate linear; }
    .btn.btn-contact span {
      font-size: 0.875rem;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      white-space: nowrap;
      color: white; }
      @media (max-width: 1279.98px) {
        .btn.btn-contact span {
          font-size: 0.75rem; } }
      @media (max-width: 399.98px) {
        .btn.btn-contact span {
          letter-spacing: 1px; } }
    .btn.btn-contact img, .btn.btn-contact em {
      margin-left: 8px;
      color: white; }
      @media (max-width: 399.98px) {
        .btn.btn-contact img, .btn.btn-contact em {
          font-size: 12px;
          margin-left: 4px; } }
  .btn.btn-shopping {
    width: 100%;
    max-width: 195px;
    height: 40px;
    border-radius: 3px;
    padding: 0 15px;
    background-color: #f5f5f5;
    color: #009035;
    display: flex;
    transition: .3s all  ease-in-out;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-weight: 700;
    font-size: 1rem; }
    @media (max-width: 575.98px) {
      .btn.btn-shopping {
        font-size: .9rem; } }
    @media screen and (min-width: 1025px) {
      .btn.btn-shopping:hover {
        transform: scale(1.05) translateZ(0); } }
  .btn.btn-checkout {
    display: flex;
    transition: .3s all  ease-in-out;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: #009035;
    color: white;
    width: 100%;
    font-weight: 700;
    max-width: 195px;
    height: 45px;
    padding: 0 20px;
    margin-left: 20px;
    white-space: nowrap; }
    @media (max-width: 575.98px) {
      .btn.btn-checkout {
        font-size: .9rem; } }
    @media screen and (min-width: 1025px) {
      .btn.btn-checkout:hover {
        box-shadow: 2px 2px 5px #009035; } }
  .btn.btn-buy-product {
    max-width: 386px;
    height: 50px;
    border-radius: 4px;
    background-color: #009035;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.875rem;
    width: 100%;
    transition: .3s all ease-in-out; }
    .btn.btn-buy-product:hover {
      box-shadow: 2px 2px 5px #009035; }
      .btn.btn-buy-product:hover em {
        animation: lagging .5s infinite linear alternate; }
    .btn.btn-buy-product span {
      font-size: 0.875rem;
      color: white;
      letter-spacing: 0.1em; }
    .btn.btn-buy-product em {
      font-size: 1.5rem;
      color: white;
      margin-right: 1.4375rem; }
  .btn.btn-view-more {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #009035;
    transition: .3s all ease-in-out; }
    .btn.btn-view-more:hover {
      box-shadow: 2px 2px 5px #009035; }
      .btn.btn-view-more:hover::after {
        animation: pulse .3s infinite alternate linear; }
    .btn.btn-view-more::after {
      content: '';
      display: inline-block;
      width: 19px;
      margin-left: 10px;
      height: 13px;
      background-image: url("../img/icon/arrow-right-white.png"); }
    .btn.btn-view-more span {
      color: #ffffff;
      font-size: 0.875rem;
      letter-spacing: 1.4px;
      text-transform: uppercase; }

.wrap-button-buy {
  gap: 10px; }

.frm-btnwrap .frm-btn {
  position: relative;
  width: fit-content; }
  .frm-btnwrap .frm-btn:hover input {
    box-shadow: 2px 2px 5px #009035; }
  .frm-btnwrap .frm-btn:hover::before {
    animation: pulse .3s infinite alternate linear; }
  .frm-btnwrap .frm-btn::before {
    content: '';
    background-image: url("../img/icon/arrow-right.png");
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 30px;
    width: 20px;
    height: 20px; }
  .frm-btnwrap .frm-btn input {
    width: 160px;
    height: 50px;
    border-radius: 8px;
    background-color: #009035;
    color: #ffffff;
    font-size: 0.875rem;
    letter-spacing: 1.4px;
    outline: none;
    border: 0;
    text-transform: uppercase;
    transition: .3s all ease-in-out; }

.menu.cross.menu--1.hambuger {
  display: none; }
  @media (max-width: 1023.98px) {
    .menu.cross.menu--1.hambuger {
      flex: 0 0 44px;
      width: 44px;
      height: 40px;
      background-color: #009035;
      display: flex;
      align-items: center;
      border-radius: 4px;
      justify-content: center;
      position: relative;
      z-index: 2; } }
  .menu.cross.menu--1.hambuger .wrap {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: -1; }
  .menu.cross.menu--1.hambuger path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -ms-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1); }
  .menu.cross.menu--1.hambuger.active svg .line--1,
  .menu.cross.menu--1.hambuger.active svg .line--3 {
    --length: 22.627416998;
    --offset: -93.7; }
  .menu.cross.menu--1.hambuger.active svg .line--2 {
    --length: 0;
    --offset: -50; }
  .menu.cross.menu--1.hambuger .line--1, .menu.cross.menu--1.hambuger .line--3 {
    --total-length: 126.64183044433594; }
  .menu.cross.menu--1.hambuger .line--2 {
    --total-length: 70; }

.wrap-hambuger {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 20px;
  border-left: 1px solid #e5e5e5; }
  @media (max-width: 575.98px) {
    .wrap-hambuger {
      padding: 0 0.625rem; } }
  @media (min-width: 1024px) {
    .wrap-hambuger {
      display: none; } }

@font-face {
  font-family: "remixicon";
  src: url("../fonts/remixicon.eot");
  src: url("../fonts/remixicon.woff2") format("woff2");
  src: url("../fonts/remixicon.woff") format("woff");
  src: url("../fonts/remixicon.ttf") format("truetype");
  font-display: swap; }

[class^="ri-"], [class*=" ri-"] {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ri-arrow-up-line::before {
  content: "\EA78"; }

.ri-subtract-line::before {
  content: "\f1af"; }

.ri-add-line::before {
  content: "\ea13"; }

.ri-play-circle-line::before {
  content: "\F009"; }

.ri-arrow-down-s-fill::before {
  content: "\EA4D"; }

.ri-close-fill::before {
  content: "\EB98"; }

.ri-checkbox-circle-fill:before {
  content: "\EB80"; }

.ri-arrow-down-s-line::before {
  content: "\EA4E"; }

.ri-arrow-left-s-fill::before {
  content: "\EA60"; }

.ri-arrow-right-s-fill::before {
  content: "\EA6C"; }

.ri-menu-line::before {
  content: "\EF3E"; }

.ri-home-3-line::before {
  content: "\EE1B"; }

.ri-align-left::before {
  content: "\EA27"; }

.ri-close-line::before {
  content: "\EB99"; }

@font-face {
  font-family: 'Linearicons-Free';
  src: url("../fonts/Linearicons-Free.ttf") format("truetype");
  src: url("../fonts/Linearicons-Free.eot") format("embedded-opentype");
  src: url("../fonts/Linearicons-Free.woff2") format("woff2");
  src: url("../fonts/Linearicons-Free.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.lnr {
  font-family: 'Linearicons-Free';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lnr-chevron-left::before {
  content: "\e875"; }

.lnr-chevron-right::before {
  content: "\e876"; }

.lnr-undo::before {
  content: "\e860"; }

.lnr-magnifier::before {
  content: '\e86f'; }

.lnr-bubble::before {
  content: '\e83f'; }

.lnr-envelope::before {
  content: '\e818';
  transform: translateY(-2px); }

.lnr-phone-handset::before {
  content: '\e830'; }

.lnr-chevron-down::before {
  content: '\e874'; }

.item-product-big {
  border-radius: 8px;
  overflow: hidden;
  position: relative; }
  .item-product-big:hover .wrap-button-title {
    transform: none; }
    .item-product-big:hover .wrap-button-title .wrap-button-buy {
      opacity: 1;
      visibility: visible; }
  .item-product-big::before {
    content: '';
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.75) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 100%; }
  .item-product-big .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 128.20513%; }
    .item-product-big .img img, .item-product-big .img iframe, .item-product-big .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
    @media (max-width: 575.98px) {
      .item-product-big .img {
        padding-top: 100%; } }
  .item-product-big .wrap-button-title {
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 0 15px;
    transition: .3s all ease-in-out;
    transform: translateY(40px);
    will-change: transform; }
    .item-product-big .wrap-button-title h2 {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      @media (max-width: 1279.98px) {
        .item-product-big .wrap-button-title h2 {
          font-size: 1.25rem; } }
    .item-product-big .wrap-button-title .wrap-button-buy {
      margin-top: 10px;
      opacity: 0;
      transition: .3s all ease-in-out;
      visibility: hidden; }

.item-product-main {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: .3s all ease-in-out; }
  .item-product-main:hover {
    box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.05); }
    .item-product-main:hover .content {
      transform: translateY(-100px); }
      @media (max-width: 575.98px) {
        .item-product-main:hover .content {
          transform: translateY(-90px); } }
      @media (min-width: 1460px) {
        .item-product-main:hover .content {
          transform: translateY(-70px); } }
      .item-product-main:hover .content .wrap-button-buy {
        opacity: 1;
        visibility: visible; }
  .item-product-main .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%; }
    .item-product-main .img a img, .item-product-main .img a iframe, .item-product-main .img a video {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }
  .item-product-main .img .tag-promotion {
    position: absolute;
    top: 18px;
    left: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    border-radius: 4px;
    background-color: #0f8dc9;
    color: #ffffff;
    font-size: 0.875rem; }
  .item-product-main .content {
    transition: .3s all ease-in-out;
    padding: 10px 30px 20px;
    background-color: #fff;
    position: relative; }
    @media (max-width: 575.98px) {
      .item-product-main .content {
        padding: 10px 15px 9px; } }
    .item-product-main .content h3 a {
      height: 46px;
      display: block;
      color: #333333;
      font-size: 1rem;
      font-weight: 500;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 10px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      @media (min-width: 1024px) {
        .item-product-main .content h3 a {
          height: 50px; } }
      @media (min-width: 1280px) {
        .item-product-main .content h3 a {
          min-height: 53px; } }
    .item-product-main .content .price {
      color: #666666;
      font-size: 0.875rem;
      line-height: 18px;
      margin-top: 10px; }
    .item-product-main .content .wrap-button-buy {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      transition: .3s all ease-in-out; }
      @media (max-width: 1459.98px) {
        .item-product-main .content .wrap-button-buy {
          flex-direction: column;
          padding: 0 15px;
          gap: 5px; }
          .item-product-main .content .wrap-button-buy .btn {
            width: 100% !important; } }

.item-news-main {
  position: relative; }
  .item-news-main:hover .line {
    background-color: rgba(227, 236, 240, 0.5); }
  .item-news-main .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 63.63636%; }
    .item-news-main .img a img, .item-news-main .img a iframe, .item-news-main .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .item-news-main .content {
    padding: 16px 55px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 160px;
    position: relative;
    z-index: 9; }
    @media (max-width: 1279.98px) {
      .item-news-main .content {
        padding: 10px 20px 30px; } }
    .item-news-main .content h3 {
      text-align: center; }
      .item-news-main .content h3:hover {
        text-decoration: underline; }
      .item-news-main .content h3 a {
        color: #333333;
        font-size: 1.125rem;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.3; }
        @media (max-width: 1279.98px) {
          .item-news-main .content h3 a {
            font-size: 1rem; } }
  .item-news-main .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc( 100% - 40px);
    height: calc( 100% - 40px);
    border: 4px solid rgba(227, 236, 240, 0.5);
    transition: .3s all ease-in-out; }
    @media (max-width: 1279.98px) {
      .item-news-main .line {
        width: calc( 100% - 20px);
        height: calc( 100% - 20px); } }

.wrap-title-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.875rem; }
  .wrap-title-product .title-product {
    font-size: 1.625rem; }

.main-section {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .main-section {
      padding: 40px 0; } }
  @media (min-width: 1024px) {
    .main-section {
      padding: 45px 0; } }
  @media (min-width: 1200px) {
    .main-section {
      padding: 50px 0; } }

.swiper-button-disabled {
  opacity: 0.4 !important; }

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1279.98px) {
    .wrap-button-slide {
      margin-top: 1.875rem; } }
  @media (max-width: 1279.98px) {
    .wrap-button-slide.hidden-ipad {
      display: none; } }
  .wrap-button-slide.button-dis .btn-prev {
    right: auto;
    left: 0; }
  .wrap-button-slide.button-dis .btn-next {
    left: auto;
    right: 0; }
  .wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%); }
  .wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
    z-index: 9;
    position: static; }
    @media (min-width: 1280px) {
      .wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  .wrap-button-slide .btn-prev {
    right: 100%; }
    @media (max-width: 1279.98px) {
      .wrap-button-slide .btn-prev {
        margin-right: 5px !important; } }
  .wrap-button-slide .btn-next {
    left: 100%; }
    @media (max-width: 1279.98px) {
      .wrap-button-slide .btn-next {
        margin-left: 5px !important; } }

.pagination-mb {
  position: static !important;
  margin-top: 1.25rem; }
  @media (min-width: 1280px) {
    .pagination-mb {
      display: none; } }
  .pagination-mb .swiper-pagination-bullet {
    width: 10px;
    height: 10px; }
    .pagination-mb .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #009035; }

.title-36 {
  color: #333333;
  font-size: 2.25rem;
  font-weight: 400; }
  @media (max-width: 1279.98px) {
    .title-36 {
      font-size: 1.875rem; } }
  @media (max-width: 1023.98px) {
    .title-36 {
      font-size: 1.625rem; } }

.wrap-tab-1 {
  overflow: auto; }
  @media (min-width: 1024px) {
    .wrap-tab-1.spacing-expand ul {
      gap: 3.125rem; } }
  .wrap-tab-1 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 30px; }
    @media (max-width: 1023.98px) {
      .wrap-tab-1 ul {
        gap: 15px; } }
    .wrap-tab-1 ul li:first-child {
      margin-left: auto; }
    .wrap-tab-1 ul li:last-child {
      margin-right: auto; }
    .wrap-tab-1 ul li.active a {
      color: #009035;
      text-decoration: underline; }
    .wrap-tab-1 ul li a {
      color: #666666;
      font-size: 0.875rem;
      transition: .3s all ease-in-out;
      white-space: nowrap; }
      .wrap-tab-1 ul li a:hover {
        color: #009035;
        text-decoration: underline; }

.pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 5rem;
  margin-top: 1.875rem; }
  .pagination a, .pagination span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #edf6f1;
    transition: .3s all ease-in-out;
    color: #333333;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 50%;
    line-height: 1; }
    .pagination a:hover, .pagination span:hover {
      color: white;
      background-color: #009035; }

.input-amount {
  display: flex;
  align-items: center; }
  .input-amount [class*='btn-'] {
    width: 50px;
    height: 50px;
    background-color: #edf6f1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all ease-in-out; }
    .input-amount [class*='btn-']:hover {
      background-color: #dadada; }
  .input-amount .btn-dec {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0px 0px 4px; }
    .input-amount .btn-dec::before {
      content: '';
      background-image: url(../img/icon/subtract.png);
      width: 14px;
      height: 2px; }
  .input-amount .btn-inc {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px; }
    .input-amount .btn-inc::before {
      content: '';
      background-image: url(../img/icon/plus.png);
      width: 14px;
      height: 14px; }
  .input-amount .amount {
    height: 50px;
    width: 82px;
    text-align: center;
    border: 0px;
    border: 1px solid #edf6f1;
    background-color: #f5f5f5;
    color: #333333;
    font-size: 1.125rem;
    text-transform: uppercase; }

.wrab-tab-2 {
  overflow: auto; }
  .wrab-tab-2 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; }
  .wrab-tab-2 li:first-child a {
    border-radius: 4px 0px 0px 4px; }
  .wrab-tab-2 li:last-child a {
    border-radius: 0px 4px 4px 0px; }
  .wrab-tab-2 li.active a {
    background-color: #009035;
    color: white; }
  .wrab-tab-2 li a {
    color: #333333;
    font-size: 0.875rem;
    text-transform: uppercase;
    height: 50px;
    background-color: #edf6f1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.75rem;
    white-space: nowrap; }

@media (max-width: 1023.98px) {
  .box-navigation {
    position: fixed;
    top: 71px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 99;
    transform: translateY(calc(100% - 111px));
    transition: .3s all ease-in-out; } }
  @media (max-width: 1023.98px) and (max-width: 575.98px) {
    .box-navigation {
      top: 61px; } }

@media (max-width: 1023.98px) {
    .box-navigation.active {
      transform: none; }
      .box-navigation.active .title em {
        transform: rotate(180deg); } }

.box-navigation .title {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 700;
  height: 60px;
  background-color: #009035;
  padding: 0 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px; }
  @media (max-width: 1279.98px) {
    .box-navigation .title {
      height: 50px;
      padding: 0 15px; } }
  @media (max-width: 1023.98px) {
    .box-navigation .title {
      height: 50px;
      border-radius: 0;
      padding: 0 15px; } }
  .box-navigation .title em {
    transition: .3s all ease-in-out; }
    @media (min-width: 1024px) {
      .box-navigation .title em {
        display: none; } }

.box-navigation .list-link {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 0 30px; }
  @media (max-width: 1279.98px) {
    .box-navigation .list-link {
      padding: 0 0.9375rem; } }
  .box-navigation .list-link li.active > a {
    color: #0F8DC9; }
    .box-navigation .list-link li.active > a em {
      color: #0F8DC9; }
  .box-navigation .list-link > li {
    border-bottom: 1px solid #e5e5e5; }
    .box-navigation .list-link > li.dropdown.active > a {
      color: #0F8DC9; }
      .box-navigation .list-link > li.dropdown.active > a em {
        transform: rotate(90deg); }
    .box-navigation .list-link > li:last-child {
      border: 0px; }
    .box-navigation .list-link > li a {
      color: #333333;
      font-size: 0.875rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 13px 0px;
      justify-content: space-between;
      transition: .3s all ease-in-out; }
      .box-navigation .list-link > li a em {
        transition: .3s all  ease-in-out; }
      .box-navigation .list-link > li a:hover {
        color: #0F8DC9; }
    .box-navigation .list-link > li .list-menu-dropdown {
      padding-bottom: 11px;
      display: none;
      transform: translateY(-5px); }
      .box-navigation .list-link > li .list-menu-dropdown ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-left: 18px; }
        .box-navigation .list-link > li .list-menu-dropdown ul li + li {
          margin-top: 8px; }
        .box-navigation .list-link > li .list-menu-dropdown ul li a {
          padding: 0px 0px;
          border: 0; }

#ctl00_divAltContent2 {
  display: none; }

.input-hover-focus, .checkout-login-section .cart-login .login-form .form-group input {
  border: 1px solid transparent !important;
  transition: .3s all ease-in-out; }
  .input-hover-focus:hover, .checkout-login-section .cart-login .login-form .form-group input:hover, .input-hover-focus:focus, .checkout-login-section .cart-login .login-form .form-group input:focus {
    border: 1px solid #009035 !important; }

.Module-1344 > div {
  height: 100%; }
  .Module-1344 > div .wrap-cart {
    height: 100%; }

.fb_iframe_widget > span {
  width: 130px !important; }
  .fb_iframe_widget > span > iframe {
    width: 130px !important; }

.modalBuy {
  border-radius: 10px;
  width: 100%;
  background-color: #fff !important;
  max-width: 600px !important;
  overflow: visible !important;
  padding: 20px !important; }
  .modalBuy .img {
    margin: -65px auto 0;
    max-width: 80px; }
  .modalBuy .title {
    padding: 15px 0;
    color: #009035;
    font-size: 1.5625rem;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 0; }
    @media (max-width: 575.98px) {
      .modalBuy .title {
        font-size: 1.25rem; } }
  .modalBuy .fancybox-close-small {
    display: none; }

.buy-contact {
  font-size: 1.875rem;
  color: #009035;
  font-weight: 600; }

.banner-home {
  position: relative; }
  .banner-home .wrap-button-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 0 !important; }
  @media (max-width: 1023.98px) {
    .banner-home .container {
      max-width: 95%;
      padding-left: 0;
      padding-right: 0; } }

#page-banner {
  width: 100%;
  position: relative; }
  #page-banner .banner__pages {
    width: 100%;
    height: 49vh; }
    #page-banner .banner__pages img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    @media screen and (max-width: 1024px) {
      #page-banner .banner__pages {
        height: 30vh; } }

.global-breadcrumb {
  width: 100%;
  background-color: rgba(227, 236, 240, 0.5); }
  .global-breadcrumb .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-wrap: wrap;
    padding: 10px 0;
    list-style: none;
    margin-bottom: 0; }
    .global-breadcrumb .breadcrumb li {
      position: relative;
      padding: 0 7px;
      display: flex;
      align-items: center; }
      .global-breadcrumb .breadcrumb li a {
        font-size: 14px;
        color: #7f8084;
        font-weight: 500;
        text-decoration: none; }
        @media (max-width: 991.98px) {
          .global-breadcrumb .breadcrumb li a {
            font-size: 10px; } }
      .global-breadcrumb .breadcrumb li.active a {
        color: #026928; }
      .global-breadcrumb .breadcrumb li:hover a {
        color: #026928; }
    .global-breadcrumb .breadcrumb li + li::before {
      position: absolute;
      top: 38%;
      left: -4px;
      transform: translateY(-50%);
      font-family: Material Icons;
      font-size: 18px;
      content: "/";
      color: #999999; }
  @media (min-width: 768.98px) {
    .global-breadcrumb {
      margin-bottom: 0; } }
  @media (min-width: 320px) {
    .global-breadcrumb {
      margin-bottom: 0; } }

.wrap-form span.fa-exclamation-triangle {
  font-size: 0.875rem;
  color: red; }
  .wrap-form span.fa-exclamation-triangle::before {
    margin-right: 5px; }

.wrap-form .form-row {
  display: flex;
  margin: 0 -15px; }
  @media screen and (max-width: 768px) {
    .wrap-form .form-row {
      flex-wrap: wrap; } }
  .wrap-form .form-row .form-group {
    flex: 0 0 50%;
    padding: 0 15px; }
    @media screen and (max-width: 768px) {
      .wrap-form .form-row .form-group {
        flex: 0 0 100%; } }
    .wrap-form .form-row .form-group input[type="text"] {
      width: 100%; }

span.fa-exclamation-triangle {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }
  span.fa-exclamation-triangle::before {
    display: none !important; }

.frm-btn-reset {
  display: none !important; }

.frm-captcha {
  display: flex;
  float: left;
  width: calc(100% - 233px);
  padding-right: 20px; }
  @media screen and (max-width: 1280px) {
    .frm-captcha {
      width: calc(100% - 110px); } }
  @media (max-width: 767.98px) {
    .frm-captcha {
      float: none;
      padding-right: 0;
      flex: 1;
      width: 100%; } }
  @media screen and (max-width: 767.67px) {
    .frm-captcha {
      flex-direction: column; } }
  .frm-captcha .label {
    display: none !important; }
  .frm-captcha .frm-captcha-input {
    order: 2;
    flex: 1; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .frm-captcha-input {
        order: 1;
        margin-bottom: 20px; } }
  .frm-captcha .RadCaptcha_Default {
    position: relative;
    width: auto !important; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .RadCaptcha_Default {
        flex: 0 0 100%;
        order: 2; } }
  .frm-captcha #ctl00_mainContent_ctl01_captcha_ctl00 {
    position: absolute;
    top: 120%;
    color: red; }
  .frm-captcha #ctl00_mainContent_ctl01_txtCaptcha {
    width: 100%;
    height: 50px;
    margin-top: 0;
    max-width: 291px;
    border: 1px solid rgba(227, 236, 240, 0.5);
    background-color: #F5F5F5;
    transition: .3s all  ease-in-out; }
    .frm-captcha #ctl00_mainContent_ctl01_txtCaptcha:hover, .frm-captcha #ctl00_mainContent_ctl01_txtCaptcha:focus {
      border: 1px solid #009035; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha #ctl00_mainContent_ctl01_txtCaptcha {
        width: 100%; } }
  .frm-captcha #ctl00_mainContent_ctl01_captcha_ctl01 {
    display: flex;
    align-items: center; }
    .frm-captcha #ctl00_mainContent_ctl01_captcha_ctl01 img {
      width: 158px !important;
      height: 50px !important;
      border: 1px solid rgba(227, 236, 240, 0.5);
      box-sizing: border-box;
      border-radius: 8px; }
    .frm-captcha #ctl00_mainContent_ctl01_captcha_ctl01 .rcRefreshImage {
      font-size: 0;
      margin-left: 10px;
      height: auto !important;
      margin-right: 10px; }
      .frm-captcha #ctl00_mainContent_ctl01_captcha_ctl01 .rcRefreshImage::after {
        display: none !important; }
      .frm-captcha #ctl00_mainContent_ctl01_captcha_ctl01 .rcRefreshImage::before {
        content: "replay";
        color: #0F8DC9;
        font-weight: 400;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 30px;
        line-height: 1; }

.subscribefrm .fa-exclamation-triangle {
  top: 110% !important;
  right: 0 !important;
  width: auto !important;
  text-indent: 0 !important; }
  .subscribefrm .fa-exclamation-triangle::after {
    display: none; }

.resetpassword .alert-success {
  text-align: center;
  display: block; }

.resetpassword .wrap-recover {
  margin: 0 auto;
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 20px;
  max-width: 400px;
  width: 100%;
  background-color: #fff; }

.resetpassword [class*='col'] {
  padding-left: 0;
  padding-right: 0;
  flex: 0 0 100%;
  max-width: 100%; }

.resetpassword .row {
  margin-left: 0;
  margin-right: 0; }

.resetpassword .col-sm-9 {
  flex: 0 0 100%;
  max-width: 100%; }

.resetpassword input[type="submit"] {
  width: 100%;
  height: 40px;
  text-align: center;
  margin-top: 0.625rem;
  background-color: #009035;
  color: white;
  outline: none;
  border: 2px solid transparent;
  font-weight: bold;
  transition: .3s all ease-in-out; }
  .resetpassword input[type="submit"]:hover {
    border: 2px solid #009035;
    background-color: white;
    color: #009035; }

.resetpassword input[type="password"] {
  outline: none;
  border: 2px solid #98272b;
  background-color: #f1f1f1;
  padding-left: 20px;
  width: 100%;
  height: 40px;
  margin-top: 5px; }

.resetpassword .form-group {
  margin-top: 15px; }

.resetpassword .panel-heading {
  text-align: center; }
  .resetpassword .panel-heading * {
    color: #009035; }

.signup-page {
  background-color: #fff;
  padding: 60px 0px; }
  .signup-page .signup-panel {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 450px;
    width: 100%; }
  .signup-page .col-xs-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important; }
  .signup-page .headtitle {
    font-size: 2.25rem;
    color: #009035;
    margin-bottom: 15px;
    text-align: center; }
  .signup-page label {
    font-size: 1.125rem; }
  .signup-page input[type="text"], .signup-page input[type="password"] {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page select {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page .form-group {
    margin-bottom: 15px; }
  .signup-page #ctl00_mainContent_RegisterUser___CustomNav0_StepNextButtonButton {
    width: 100%;
    background-color: #009035;
    color: white;
    margin-top: 10px;
    outline: 0;
    border: 0;
    padding: 5px 0px; }

.loginpage {
  background-color: #f5f5f5; }
  .loginpage .login-box {
    padding-top: 5%;
    flex-direction: column;
    display: flex;
    justify-content: center; }
  .loginpage #ctl00_mainContent_login1_LoginCtrl_lnkRegisterExtraLink {
    display: none; }
  .loginpage .login-logo {
    text-align: center;
    color: #009035;
    font-size: 2rem;
    margin-bottom: 1.875rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase; }
  .loginpage .input-group-append {
    display: none; }
  .loginpage .login-card-body {
    max-width: 550px;
    background-color: #fff;
    padding: 1.875rem;
    margin: 0 auto; }
    .loginpage .login-card-body .input-group {
      margin-top: 15px; }
      .loginpage .login-card-body .input-group input {
        outline: none;
        border: 2px solid #009035;
        background-color: #f1f1f1;
        padding-left: 20px;
        width: 100%;
        height: 50px; }
    .loginpage .login-card-body .social-login {
      display: none; }
    .loginpage .login-card-body .icheck-primary {
      display: flex;
      align-items: center;
      padding: 15px 0px; }
      .loginpage .login-card-body .icheck-primary input {
        margin-right: 5px; }
    .loginpage .login-card-body input[type="submit"] {
      width: 100%;
      height: 50px;
      text-align: center;
      margin-top: 0.625rem;
      background-color: #009035;
      color: white;
      outline: none;
      border: 2px solid transparent;
      font-weight: bold;
      transition: .3s all ease-in-out; }
      .loginpage .login-card-body input[type="submit"]:hover {
        border: 2px solid #009035;
        background-color: white;
        color: #009035; }
  .loginpage .flex {
    display: flex; }
  .loginpage .justify-between {
    justify-content: space-between; }
  .loginpage .mt-3 {
    margin-top: 0.9375rem; }
  .loginpage .forget-box {
    display: flex;
    justify-content: space-between;
    margin-top: 5px; }
    .loginpage .forget-box a {
      transition: .3s all ease-in-out; }
      .loginpage .forget-box a:hover {
        color: #009035;
        text-decoration: underline; }
  .loginpage a {
    transition: .3s all ease-in-out; }
    .loginpage a:hover {
      color: #009035;
      text-decoration: underline; }
  .loginpage .alert-danger {
    color: #009035;
    display: block;
    margin-top: 15px; }
  .loginpage .cart-login {
    display: none; }

.passwordrecovery main .alert-success {
  text-align: center;
  display: block; }

.passwordrecovery main .login-box {
  margin: 0 auto;
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 20px;
  max-width: 400px;
  width: 100%;
  background-color: #fff; }

.passwordrecovery main [class*='col'] {
  padding-left: 0;
  padding-right: 0;
  flex: 0 0 100%;
  max-width: 100%; }

.passwordrecovery main .row {
  margin-left: 0;
  margin-right: 0; }

.passwordrecovery main .col-sm-9 {
  flex: 0 0 100%;
  max-width: 100%; }

.passwordrecovery main .input-group-append {
  display: none; }

.passwordrecovery main .login-logo * {
  text-align: center;
  color: #009035;
  font-size: 2.25rem; }

.passwordrecovery main #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_UserName {
  outline: none;
  border: 2px solid #009035;
  background-color: #f1f1f1;
  padding-left: 20px;
  width: 100%;
  height: 40px; }

.passwordrecovery main .login-box-msg {
  margin-bottom: 20px; }

.passwordrecovery main #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_CaptchaTextBox {
  outline: none;
  border: 2px solid #009035;
  background-color: #f1f1f1;
  padding-left: 20px;
  width: 100%;
  height: 40px; }

.passwordrecovery main #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_lblEnterUserName {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 5px; }

.passwordrecovery main #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_SubmitButton {
  width: 100%;
  background-color: #009035;
  color: white;
  margin-top: 15px;
  outline: 0;
  border: 0;
  padding: 10px 0px; }

.passwordrecovery main #ctl00_mainContent_lblMailError {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem;
  margin-top: 5px;
  padding: 0 15px; }

.passwordrecovery main #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl00 {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }

.passwordrecovery main .rcRefreshImage {
  font-size: 0;
  margin-left: 10px; }
  .passwordrecovery main .rcRefreshImage::before {
    content: 'refresh';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 30px;
    color: black; }

.passwordrecovery main #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl01 {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }

.search-page {
  padding: 40px 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 1024px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f349';
      font-family: "Material Design Icons";
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(2), .search-page .searchcontrols .form-inline .form-group:nth-child(3) {
      display: none; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(4) {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 11; }
      .search-page .searchcontrols .form-inline .form-group:nth-child(4)::before {
        content: "\F0CD";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-family: remixicon !important;
        color: #009035; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 20px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.page-faq-main .content-right .content p {
  margin-bottom: 1.5625rem; }

.page-faq-main .content-right .content h2 {
  color: #333333;
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem; }

.page-faq-main .content-right .content ul li + li {
  margin-top: 0.9375rem; }

.page-gioi-thieu.section-1 {
  position: relative; }
  @media (min-width: 1280px) {
    .page-gioi-thieu.section-1 {
      padding-top: 100px; } }
  .page-gioi-thieu.section-1 .content {
    background-color: #edf6f1;
    z-index: 2;
    padding: 1.875rem;
    position: relative; }
    @media (min-width: 1460px) {
      .page-gioi-thieu.section-1 .content {
        padding: 0.9375rem 2.5rem 3.625rem 4.5rem; } }
    @media (min-width: 1280px) {
      .page-gioi-thieu.section-1 .content {
        padding: 0rem 1.25rem 1.25rem 1.5625rem; } }
    .page-gioi-thieu.section-1 .content .desc p {
      line-height: 1.35; }
    .page-gioi-thieu.section-1 .content .desc p + p {
      margin-top: 1.25rem; }
    .page-gioi-thieu.section-1 .content::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background-color: #edf6f1;
      height: calc( 100% + 40px);
      z-index: -1;
      transform: translateX(0) translateY(0);
      width: 100%; }
      @media (min-width: 1280px) {
        .page-gioi-thieu.section-1 .content::before {
          width: 100vw;
          transform: translateX(85px) translateY(-40px); } }
    .page-gioi-thieu.section-1 .content .title-36 {
      margin-bottom: 1.5rem; }
  .page-gioi-thieu.section-1 .img {
    position: relative;
    z-index: 3; }
    @media (max-width: 1279.98px) {
      .page-gioi-thieu.section-1 .img img {
        width: 100%; } }

@media (max-width: 767.98px) {
  .page-gioi-thieu.section-2 {
    padding-top: 0; } }

.page-gioi-thieu.section-2 .wrap-content-main {
  padding: 3.75rem 0px; }
  .page-gioi-thieu.section-2 .wrap-content-main .wrap-box-year {
    margin: 0 90px; }
    @media (max-width: 1023.98px) {
      .page-gioi-thieu.section-2 .wrap-content-main .wrap-box-year {
        margin: 0 0px; } }
  .page-gioi-thieu.section-2 .wrap-content-main .wrap-year {
    display: flex;
    flex-direction: column;
    flex: 0 0 20.32520%;
    max-width: 20.32520%;
    background-color: #fff; }
    @media (max-width: 1023.98px) {
      .page-gioi-thieu.section-2 .wrap-content-main .wrap-year {
        flex: 0 0 100%;
        max-width: 100%;
        flex-direction: row; } }
    .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year {
      height: 100%;
      max-height: 650px; }
      @media (max-width: 1023.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year {
          max-width: 80%; } }
      @media (max-width: 767.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year {
          max-width: calc( 100% - 100px); } }
      .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year .swiper {
        height: 100%; }
        .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year .swiper .swiper-wrapper {
          height: 100%; }
    .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .btn {
      width: 100%;
      height: 50px;
      background-color: #f5f5f5;
      transition: .3s all ease-in-out; }
      @media (max-width: 1023.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .btn.btn-prev em {
          transform: rotate(-90deg); } }
      @media (max-width: 1023.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .btn.btn-next em {
          transform: rotate(-90deg); } }
      .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .btn em {
        font-size: 3.125rem; }
      @media (max-width: 1023.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .btn {
          height: auto; } }
      .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .btn:hover {
        background-color: #009035; }
    .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year .swiper-slide-thumb-active .year {
      background-color: #009035;
      color: white; }
    .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year .year {
      color: #666666;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.25rem 0px;
      border-bottom: 1px solid #e0e0e0;
      transition: .3s all ease-in-out;
      height: 100%; }
      .page-gioi-thieu.section-2 .wrap-content-main .wrap-year .slide-year .year:hover {
        color: white;
        background-color: #009035; }
  .page-gioi-thieu.section-2 .wrap-content-main .content-img {
    flex: 0 0 79.6748%;
    max-width: 79.6748%; }
    @media (max-width: 1023.98px) {
      .page-gioi-thieu.section-2 .wrap-content-main .content-img {
        flex: 0 0 100%;
        max-width: 100%; } }
    .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .img {
      position: relative; }
      .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .img::before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.72) 26%, rgba(0, 0, 0, 0) 100%); }
    .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content {
      position: absolute;
      left: 0;
      bottom: 0;
      color: white;
      padding: 3.125rem 3.125rem 3.4375rem; }
      @media (max-width: 1279.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content {
          padding: 1.875rem; } }
      @media (max-width: 575.98px) {
        .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content {
          display: flex;
          height: 100%;
          flex-direction: column; } }
      .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content .year {
        color: #ffffff;
        font-size: 2.25rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.23; }
        @media (max-width: 1279.98px) {
          .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content .year {
            font-size: 1.875rem; } }
        @media (max-width: 1023.98px) {
          .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content .year {
            font-size: 1.625rem; } }
      .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content .sub-title {
        color: #ffffff;
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        margin-top: 0.9375rem; }
      .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content .desc {
        color: #ffffff;
        font-size: 1.125rem;
        margin-top: 0.9375rem; }
        @media (max-width: 575.98px) {
          .page-gioi-thieu.section-2 .wrap-content-main .content-img .wrap-img .content .desc {
            flex: 1;
            max-height: 100%;
            overflow: auto;
            padding-right: 15px; } }

.page-gioi-thieu.section-3 {
  padding-top: 30px;
  padding-bottom: 9.375rem; }
  @media (max-width: 1023.98px) {
    .page-gioi-thieu.section-3 {
      padding-bottom: 6.25rem; } }
  @media (max-width: 575.98px) {
    .page-gioi-thieu.section-3 .item {
      margin-bottom: 1.875rem; } }
  .page-gioi-thieu.section-3 .item .img {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 65.21739%; }
    .page-gioi-thieu.section-3 .item .img img, .page-gioi-thieu.section-3 .item .img iframe, .page-gioi-thieu.section-3 .item .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .page-gioi-thieu.section-3 .item .content .title {
    color: #009035;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1.875rem;
    margin-bottom: 0.5rem; }

.page-system-store .wrap-form-search {
  max-width: 1184px;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 0.9375rem; }
  @media (max-width: 1023.98px) {
    .page-system-store .wrap-form-search {
      gap: 15px; } }
  @media (max-width: 575.98px) {
    .page-system-store .wrap-form-search {
      flex-direction: column; } }
  .page-system-store .wrap-form-search .form-group {
    flex: 1; }
    @media (max-width: 575.98px) {
      .page-system-store .wrap-form-search .form-group {
        width: 100%; } }
    .page-system-store .wrap-form-search .form-group select {
      width: 100%;
      height: 50px;
      border-radius: 4px;
      outline: none;
      border: 0;
      appearance: none;
      padding-left: 25px;
      background-color: #f8f8f8;
      background-image: url("../img/icon/arrow-select.png");
      background-repeat: no-repeat;
      background-position: right 20px center; }
  .page-system-store .wrap-form-search .frm-btnwrap {
    flex: 0 0 194px; }
    @media (max-width: 575.98px) {
      .page-system-store .wrap-form-search .frm-btnwrap {
        flex: 0 0 100%;
        width: 100%; } }
    .page-system-store .wrap-form-search .frm-btnwrap .frm-btn {
      width: 100%; }
      .page-system-store .wrap-form-search .frm-btnwrap .frm-btn input {
        width: 100%;
        padding-right: 15px; }

.page-system-store .wrap-image-map {
  margin-top: 3.75rem;
  margin-bottom: 5.625rem; }
  @media (max-width: 1023.98px) {
    .page-system-store .wrap-image-map {
      flex-wrap: wrap;
      max-height: unset;
      margin-top: 2.5rem; } }
  .page-system-store .wrap-image-map .image {
    flex: 0 0 59.786476868%; }
    @media (max-width: 1023.98px) {
      .page-system-store .wrap-image-map .image {
        flex: 0 0 100%; } }
    .page-system-store .wrap-image-map .image .map {
      height: 100%; }
      .page-system-store .wrap-image-map .image .map iframe {
        width: 100%;
        height: 100%; }
        @media (max-width: 1023.98px) {
          .page-system-store .wrap-image-map .image .map iframe {
            height: 50vh; } }
        @media (max-width: 767.98px) {
          .page-system-store .wrap-image-map .image .map iframe {
            height: 30vh; } }
  .page-system-store .wrap-image-map .list-store {
    flex: 0 0 40.213523%;
    overflow: auto;
    max-height: 642px;
    height: 100%;
    background-color: #edf6f1; }
    @media (min-width: 1024px) {
      .page-system-store .wrap-image-map .list-store {
        min-height: 642px; } }
    @media (max-width: 1023.98px) {
      .page-system-store .wrap-image-map .list-store {
        flex: 0 0 100%;
        max-height: 43vh; } }
    .page-system-store .wrap-image-map .list-store::-webkit-scrollbar {
      width: 5px;
      border-radius: 0; }
    .page-system-store .wrap-image-map .list-store::-webkit-scrollbar-track {
      background: #d9d9da;
      border-radius: 0; }
    .page-system-store .wrap-image-map .list-store::-webkit-scrollbar-thumb {
      background: #5e5e5e;
      border-radius: 0; }
    .page-system-store .wrap-image-map .list-store > ul > li {
      padding: 1.6875rem 2.125rem;
      background-color: #edf6f1;
      transition: .3s all ease-in-out;
      border-bottom: 1px solid #ebe5df; }
      @media (max-width: 1279.98px) {
        .page-system-store .wrap-image-map .list-store > ul > li {
          padding: 1.5625rem; } }
      .page-system-store .wrap-image-map .list-store > ul > li:hover {
        background-color: #009035; }
        .page-system-store .wrap-image-map .list-store > ul > li:hover .title {
          color: white; }
        .page-system-store .wrap-image-map .list-store > ul > li:hover .list-address li span {
          color: white; }
        .page-system-store .wrap-image-map .list-store > ul > li:hover .list-address li img {
          filter: brightness(1); }
      .page-system-store .wrap-image-map .list-store > ul > li .title {
        color: #333333;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 1.0625rem;
        transition: .3s all ease-in-out; }
        @media (max-width: 1279.98px) {
          .page-system-store .wrap-image-map .list-store > ul > li .title {
            margin-bottom: 0.625rem; } }
      .page-system-store .wrap-image-map .list-store > ul > li .list-address li {
        display: flex;
        align-items: center; }
        .page-system-store .wrap-image-map .list-store > ul > li .list-address li + li {
          margin-top: 0.5rem; }
        .page-system-store .wrap-image-map .list-store > ul > li .list-address li span {
          transition: .3s all ease-in-out; }
        .page-system-store .wrap-image-map .list-store > ul > li .list-address li img {
          margin-right: 10px;
          filter: brightness(0);
          transition: .3s all ease-in-out;
          transition: .3s all ease-in-out; }

.page-discount-main .item-discount {
  margin-bottom: 3.75rem; }
  @media (max-width: 1023.98px) {
    .page-discount-main .item-discount {
      margin-bottom: 1.875rem; } }
  .page-discount-main .item-discount .img {
    border-radius: 8px 8px 0px 0px; }
    @media (min-width: 1024px) {
      .page-discount-main .item-discount .img {
        border-radius: 8px 0px 0px 8px; } }
    .page-discount-main .item-discount .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 71.42857%; }
      .page-discount-main .item-discount .img a img, .page-discount-main .item-discount .img a iframe, .page-discount-main .item-discount .img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .page-discount-main .item-discount .content {
    padding: 2.5rem 2.875rem 3.125rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px; }
    @media (min-width: 1024px) {
      .page-discount-main .item-discount .content {
        border-radius: 0px 8px 8px 0px; } }
    @media (max-width: 1023.98px) {
      .page-discount-main .item-discount .content {
        padding: 1.25rem; } }
    .page-discount-main .item-discount .content .title {
      font-size: 1.5rem;
      font-weight: 700;
      transition: .3s all  ease-in-out; }
      .page-discount-main .item-discount .content .title:hover {
        color: #009035; }
      @media (max-width: 1023.98px) {
        .page-discount-main .item-discount .content .title {
          font-size: 1.25rem; } }
      @media (max-width: 767.98px) {
        .page-discount-main .item-discount .content .title {
          font-size: 1rem; } }
    .page-discount-main .item-discount .content .date {
      color: #9b938b;
      font-size: 0.875rem;
      font-weight: 500;
      padding: 8px 0px 9px;
      border-bottom: 2px solid #009035; }
    .page-discount-main .item-discount .content .desc {
      padding: 10px 0px;
      margin-bottom: 1.875rem; }
      .page-discount-main .item-discount .content .desc p + p {
        margin-top: 15px; }
    .page-discount-main .item-discount .content .btn-left {
      margin-top: auto; }

.page-lien-he .box-contact-main {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #ffffff; }

.page-lien-he .box-contact-right {
  padding-top: 2.375rem;
  padding-right: 1.875rem; }
  @media (max-width: 1279.98px) {
    .page-lien-he .box-contact-right {
      padding-top: 0.9375rem; } }
  @media (max-width: 1023.98px) {
    .page-lien-he .box-contact-right {
      padding: 1.875rem; } }
  .page-lien-he .box-contact-right .title {
    margin-bottom: 1.25rem; }
  .page-lien-he .box-contact-right .wrap-form .form-group {
    margin-bottom: 1.25rem;
    position: relative; }
    .page-lien-he .box-contact-right .wrap-form .form-group.qparagraph span.fa-exclamation-triangle {
      right: 15px; }
    .page-lien-he .box-contact-right .wrap-form .form-group span.fa-exclamation-triangle {
      position: absolute;
      top: 13px;
      right: 30px;
      color: #c92525;
      margin-top: 5px;
      animation: AlertError 0.4s 1 linear !important; }
    .page-lien-he .box-contact-right .wrap-form .form-group input[type="text"]:hover, .page-lien-he .box-contact-right .wrap-form .form-group input[type="text"]:focus, .page-lien-he .box-contact-right .wrap-form .form-group input[type="email"]:hover, .page-lien-he .box-contact-right .wrap-form .form-group input[type="email"]:focus, .page-lien-he .box-contact-right .wrap-form .form-group textarea:hover, .page-lien-he .box-contact-right .wrap-form .form-group textarea:focus {
      border: 1px solid #009035; }
    .page-lien-he .box-contact-right .wrap-form .form-group input[type="text"], .page-lien-he .box-contact-right .wrap-form .form-group input[type="email"] {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      border: 1px solid rgba(227, 236, 240, 0.5);
      transition: .3s all ease-in-out;
      background-color: #f5f5f5;
      color: #7f8084;
      padding-left: 20px;
      font-size: 14px; }
    .page-lien-he .box-contact-right .wrap-form .form-group textarea {
      width: 100%;
      height: 180px;
      border-radius: 8px;
      border: 1px solid rgba(227, 236, 240, 0.5);
      transition: .3s all ease-in-out;
      background-color: #f5f5f5;
      color: #666666;
      font-size: 14px;
      padding-top: 12px;
      padding-left: 20px; }
  .page-lien-he .box-contact-right .wrap-form .label {
    display: none; }
  @media (min-width: 576px) {
    .page-lien-he .box-contact-right .wrap-form .frm-btnwrap {
      float: right; } }
  .page-lien-he .box-contact-right .wrap-form .clear::after {
    content: "";
    clear: both;
    display: table; }
  @media (max-width: 1279.98px) {
    .page-lien-he .box-contact-right .frm-btnwrap .frm-btn input {
      padding-right: 40px;
      width: 110px;
      padding-top: 2px; } }

.page-lien-he .box-contact-left {
  background-image: url("../img/contact/contact-img.png");
  border-radius: 8px 0px 0px 8px;
  background-color: #009035;
  background-repeat: no-repeat;
  padding: 3.75rem 3.125rem 5.625rem;
  background-size: cover; }
  @media (max-width: 1279.98px) {
    .page-lien-he .box-contact-left {
      padding: 1.875rem; } }
  @media (max-width: 1023.98px) {
    .page-lien-he .box-contact-left {
      padding: 2.5rem 1.25rem;
      border-radius: 8px; } }
  .page-lien-he .box-contact-left .title {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
    line-height: 1.4; }
  .page-lien-he .box-contact-left .list-address li {
    display: flex;
    align-items: center; }
    .page-lien-he .box-contact-left .list-address li + li {
      margin-top: 1.6875rem; }
    .page-lien-he .box-contact-left .list-address li .icon {
      width: 60px;
      flex-shrink: 0;
      height: 60px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%; }
    .page-lien-he .box-contact-left .list-address li .text {
      margin-left: 30px;
      font-size: 14px; }
      .page-lien-he .box-contact-left .list-address li .text strong {
        text-transform: uppercase; }

.page-lien-he.iframe-map {
  padding-bottom: 5.625rem; }
  .page-lien-he.iframe-map .map {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.24113%; }
    .page-lien-he.iframe-map .map img, .page-lien-he.iframe-map .map iframe, .page-lien-he.iframe-map .map video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      transition: .3s ease-in-out all; }

.page-product-detail.box-product-infomation .box-product-wrap {
  background-color: #fff;
  padding-bottom: 3.125rem; }
  .page-product-detail.box-product-infomation .box-product-wrap .title-product-wrap {
    padding: 14px;
    border-bottom: 1px solid #ebe5df;
    flex-wrap: wrap; }
    @media (max-width: 1023.98px) {
      .page-product-detail.box-product-infomation .box-product-wrap .title-product-wrap {
        flex-direction: column;
        align-items: flex-start; } }
    .page-product-detail.box-product-infomation .box-product-wrap .title-product-wrap h1 {
      font-size: 1.875rem; }
      @media (max-width: 575.98px) {
        .page-product-detail.box-product-infomation .box-product-wrap .title-product-wrap h1 {
          font-size: 1.8rem; } }
    @media (max-width: 1023.98px) {
      .page-product-detail.box-product-infomation .box-product-wrap .title-product-wrap .wrap-share {
        margin-top: 0.625rem; } }

.page-product-detail.box-product-infomation .product-wrap {
  margin-top: 1.875rem; }
  .page-product-detail.box-product-infomation .product-wrap .product-img-wrap {
    position: sticky;
    top: 135px; }
    .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-main .img {
      transition: .3s all ease-in-out; }
      .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-main .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 65.92593%; }
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-main .img a img, .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-main .img a iframe, .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-main .img a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
          transition: .3s ease-in-out all; }
    .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb {
      padding: 0 2.5625rem 0px 3.625rem;
      margin-top: 2.9375rem; }
      @media (max-width: 1023.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb {
          padding: 0 1.875rem; } }
      @media (max-width: 767.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb {
          padding: 0 0.9375rem; } }
      @media (max-width: 1279.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .wrap-button-slide {
          display: none; } }
      .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .pagination-mb {
        margin-top: 10px; }
        @media (max-width: 1023.98px) {
          .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .pagination-mb {
            margin-bottom: 1.875rem; } }
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .pagination-mb .swiper-pagination-bullet {
          width: 8px;
          height: 8px; }
      .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .swiper-slide-thumb-active .img {
        border: 1px solid #009035; }
      .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .wrap-button-slide .btn-prev {
        left: 28px; }
      .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .wrap-button-slide .btn-next {
        right: 12px; }
      .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .img {
        border: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s all ease-in-out;
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 75.45455%; }
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .img img, .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .img iframe, .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
          transition: .3s ease-in-out all; }
        .page-product-detail.box-product-infomation .product-wrap .product-img-wrap .product-img-thumb .img img {
          padding: 8px; }
  .page-product-detail.box-product-infomation .product-wrap .product-info-main {
    padding-right: 2.8125rem; }
    @media (max-width: 1023.98px) {
      .page-product-detail.box-product-infomation .product-wrap .product-info-main {
        padding: 0 1.625rem;
        margin-top: 1.875rem; } }
    @media (max-width: 767.98px) {
      .page-product-detail.box-product-infomation .product-wrap .product-info-main {
        padding: 0 0.9375rem; } }
    .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price {
      width: 100%;
      padding: 15px 20px 33px;
      border-radius: 4px;
      background-color: #edf6f1;
      margin-bottom: 1.875rem; }
      @media (max-width: 1279.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price {
          padding-bottom: 0.9375rem;
          margin-bottom: 0.9375rem; } }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price span {
        color: #009035; }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-price .price {
        color: #333333;
        font-size: 1.5rem;
        font-weight: 700; }
        @media (max-width: 767.98px) {
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-price .price {
            font-size: 1.25rem; } }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-price .sale {
        height: 25px;
        background-color: #0f8dc9;
        padding: 0 1.25rem;
        color: #ffffff;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 3.8125rem; }
        @media (max-width: 767.98px) {
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-price .sale {
            margin-left: 0.625rem; } }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .price-retail {
        margin-top: 10px; }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-sku-brand {
        margin-top: 10px; }
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-sku-brand .brand {
          margin-left: 23px;
          position: relative; }
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .box-price .wrap-sku-brand .brand::before {
            content: '';
            position: absolute;
            top: 4px;
            left: -11px;
            height: 9px;
            width: 2px;
            background-color: #000; }
    .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .info .title {
      margin-bottom: 0.3125rem; }
    .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .info .wrap-table {
      margin-bottom: 2.125rem; }
      @media (max-width: 1279.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .info .wrap-table {
          margin-bottom: 0.625rem; } }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .info .wrap-table table tr td {
        color: #333333;
        font-size: 0.875rem;
        padding: 4.5px 0px; }
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .info .wrap-table table tr td:last-child {
          padding-left: 3.125rem; }
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .info .wrap-table table tr td:first-child {
          color: #111111;
          font-weight: 600;
          white-space: nowrap; }
    @media (max-width: 1600px) {
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .product-quantity {
        flex-direction: column;
        align-items: flex-start; } }
    .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .product-quantity span {
      margin-right: 23px; }
      @media (max-width: 1600px) {
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .product-quantity span {
          margin-bottom: 15px; } }
    .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy {
      box-shadow: -1px 3px 25px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      background-color: white;
      padding: 0px 20px;
      max-width: calc( 100% - 50px);
      margin-left: auto; }
      @media (max-width: 1600px) {
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy {
          max-width: 100%; } }
      @media (max-width: 1279.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy {
          margin-top: 1.875rem; } }
      @media (max-width: 1279.98px) {
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap; } }
      .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li {
        padding: 10px 0px; }
        @media (max-width: 1279.98px) {
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li {
            flex: 0 0 50%; } }
        @media (max-width: 767.98px) {
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li {
            flex: 0 0 100%; }
            .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li:not(:last-child) {
              border-bottom: 1px solid #ebe5df; } }
        @media (min-width: 1280px) {
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li:not(:last-child) {
            border-bottom: 1px solid #ebe5df; } }
        @media (min-width: 768px) {
          .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li:nth-child(1), .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li:nth-child(2) {
            border-bottom: 1px solid #ebe5df; } }
        .page-product-detail.box-product-infomation .product-wrap .product-info-main .wrap-infomation .policy ul li .icon {
          margin-right: 17px;
          flex: 0 0 35px; }

.page-product-detail.box-tab-content {
  padding-top: 10px; }
  .page-product-detail.box-tab-content .box-product {
    background-color: #ffffff;
    padding: 1.9375rem; }
    @media (min-width: 1024px) {
      .page-product-detail.box-tab-content .box-product {
        padding-right: 0; } }
    .page-product-detail.box-tab-content .box-product .tabslet-content {
      margin-top: 1.625rem; }
      .page-product-detail.box-tab-content .box-product .tabslet-content h2 {
        color: #333333;
        font-size: 2.25rem;
        font-weight: 400;
        margin-bottom: 0.625rem; }
      .page-product-detail.box-tab-content .box-product .tabslet-content p {
        margin-bottom: 1.25rem; }
      .page-product-detail.box-tab-content .box-product .tabslet-content .content {
        padding-right: 2.5rem; }

.page-product-detail.box-product-other {
  padding-top: 0rem; }
  .page-product-detail.box-product-other .btn-prev {
    margin-right: 1.875rem; }
  .page-product-detail.box-product-other .btn-next {
    margin-left: 1.875rem; }
  .page-product-detail.box-product-other .btn-sw-1 {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff; }
    .page-product-detail.box-product-other .btn-sw-1:hover {
      background-color: #009035; }

.page-product-list {
  background-color: #f5f5f5; }
  .page-product-list .item-product-list {
    padding-bottom: 1.875rem;
    margin-bottom: 1.875rem; }
    @media (min-width: 1024px) {
      .page-product-list .item-product-list {
        margin-bottom: 2.8125rem;
        padding-bottom: 3.75rem; } }
    .page-product-list .item-product-list:not(:last-child) {
      border-bottom: 2px solid #e5e5e5; }
    .page-product-list .item-product-list:last-child {
      padding-bottom: 1.25rem; }
  .page-product-list .btn-prev {
    margin-right: 1.875rem; }
  .page-product-list .btn-next {
    margin-left: 1.875rem; }
  .page-product-list .btn-sw-1 {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff; }

@media (max-width: 575.98px) {
  .page-product-all .row {
    margin-left: -7.5px;
    margin-right: -7.5px; }
    .page-product-all .row .col-6 {
      padding-left: 7.5px;
      padding-right: 7.5px; } }

.page-faq-main .content-right h1 {
  color: #333333;
  font-size: 2.25rem;
  font-weight: 400;
  margin-bottom: 1.5625rem; }
  @media (max-width: 1023.98px) {
    .page-faq-main .content-right h1 {
      font-size: 1.875rem; } }
  @media (max-width: 575.98px) {
    .page-faq-main .content-right h1 {
      font-size: 1.625rem; } }

.page-faq-main .content-right .list-faq {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 1.875rem; }
  .page-faq-main .content-right .list-faq li:first-child .title {
    border-top: 1px solid #e5e5e5; }
  .page-faq-main .content-right .list-faq li .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 12.5px 0px;
    cursor: pointer; }
    .page-faq-main .content-right .list-faq li .title.active {
      border-bottom: 1px solid #009035; }
      .page-faq-main .content-right .list-faq li .title.active em {
        transform: rotate(180deg); }
    .page-faq-main .content-right .list-faq li .title:hover span {
      text-shadow: 0 0 0.95px #009035, 0 0 0.95px #009035;
      color: #009035; }
    .page-faq-main .content-right .list-faq li .title:hover em {
      color: #009035; }
    .page-faq-main .content-right .list-faq li .title span {
      color: #333333;
      font-size: 1rem;
      transition: .3s all ease-in-out; }
    .page-faq-main .content-right .list-faq li .title em {
      font-size: 1.625rem;
      transition: .3s all  ease-in-out; }
  .page-faq-main .content-right .list-faq li .content {
    display: none;
    padding: 0.9375rem 3.125rem 0.9375rem 1.875rem;
    background-color: rgba(227, 236, 240, 0.5); }
    .page-faq-main .content-right .list-faq li .content * {
      color: #5e5e5e; }

.page-document-main .list-document ul li a {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6875rem 0px;
  border-bottom: 1px solid #e5e5e5; }

.page-document-main .list-document ul li:hover .wrap-date-name .name {
  color: #0f8dc9; }

.page-document-main .list-document ul li .wrap-date-name {
  padding-right: 15px; }
  .page-document-main .list-document ul li .wrap-date-name .date {
    color: #0f8dc9;
    font-size: 0.875rem; }
  .page-document-main .list-document ul li .wrap-date-name .name {
    color: #333333;
    font-size: 0.875rem;
    transition: .3s all  ease-in-out;
    margin-left: 2.3125rem; }
    @media (max-width: 1023.98px) {
      .page-document-main .list-document ul li .wrap-date-name .name {
        margin-left: 1.25rem; } }

.wrap-search-main {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px 0px;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: .4s all ease-in-out;
  z-index: 99;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  height: 100%; }
  .wrap-search-main.active {
    opacity: 1;
    visibility: visible; }
    .wrap-search-main.active .close {
      top: 100px;
      opacity: 1; }
    .wrap-search-main.active .searchbox button em {
      transform: translateY(0px);
      opacity: 1;
      transition-delay: .5s; }
    .wrap-search-main.active .searchbox input {
      transform: translateX(0px);
      opacity: 1; }
    .wrap-search-main.active .searchbox::before {
      width: 100%;
      opacity: 1; }
  .wrap-search-main .close {
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    cursor: pointer;
    opacity: 0;
    transition: .3s all ease-in-out;
    transition-delay: .4s; }
    .wrap-search-main .close em {
      color: white;
      font-size: 40px; }
  .wrap-search-main .searchbox {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; }
    .wrap-search-main .searchbox [class*="Module"] {
      width: 100%; }
    .wrap-search-main .searchbox::before {
      content: '';
      width: 0%;
      opacity: 0;
      height: 1px;
      position: absolute;
      left: 0;
      top: calc( 100% + 5px);
      background-color: #fff;
      transition-delay: .1s;
      transition: .75s all ease-in-out; }
    .wrap-search-main .searchbox input {
      flex: 1;
      border: 0;
      background-color: transparent;
      color: white;
      transition: .6s all ease-in-out;
      transition-delay: .2s;
      transform: translateX(-30px);
      opacity: 0; }
      .wrap-search-main .searchbox input::placeholder {
        color: white; }
    .wrap-search-main .searchbox button {
      outline: none;
      border: 0;
      background-color: transparent;
      display: flex;
      padding: 0; }
      .wrap-search-main .searchbox button em {
        color: white;
        opacity: 0;
        transform: translateY(-20px);
        transition: .3s all ease-in-out; }

.shopping-cart-wrapper {
  position: absolute;
  top: calc( 100% + 60px);
  width: 370px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 10px 15px 10px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease-in-out; }
  @media (max-width: 1199.98px) {
    .shopping-cart-wrapper {
      right: 0; } }
  @media (min-width: 1200px) {
    .shopping-cart-wrapper {
      right: calc(100% - 237px); } }
  @media (max-width: 767.98px) {
    .shopping-cart-wrapper {
      width: 100vw;
      position: fixed;
      top: 61px;
      transform: translateY(66px); } }
  .shopping-cart-wrapper.active {
    top: calc( 100% + 30px);
    opacity: 1;
    visibility: visible; }
    @media (max-width: 767.98px) {
      .shopping-cart-wrapper.active {
        top: 61px;
        transform: none;
        border-radius: 0;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); } }
  .shopping-cart-wrapper span {
    color: white; }
  .shopping-cart-wrapper .alert-add-success {
    display: flex;
    align-items: center;
    padding: 15px 0px; }
    @media (min-width: 1024px) {
      .shopping-cart-wrapper .alert-add-success {
        display: none; } }
    .shopping-cart-wrapper .alert-add-success em {
      font-size: 24px;
      color: #009035;
      margin-right: 10px; }
    .shopping-cart-wrapper .alert-add-success p {
      font-size: 18px;
      padding-top: 2px; }
  .shopping-cart-wrapper .head {
    font-weight: 400;
    color: #000000;
    padding: 10px 10px;
    margin-top: 15px;
    border-top: 1px solid #009035; }
    .shopping-cart-wrapper .head .cart-quantity {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .shopping-cart-wrapper .head .cart-quantity span {
        margin-left: 0;
        color: #000000; }
      .shopping-cart-wrapper .head .cart-quantity .total-cart-items {
        background: white; }
      .shopping-cart-wrapper .head .cart-quantity .item-quantity {
        font-weight: 600; }
    .shopping-cart-wrapper .head .cart-number {
      font-size: 1.125rem;
      font-weight: 700;
      margin-left: auto;
      position: static !important; }
      .shopping-cart-wrapper .head .cart-number p {
        display: flex;
        align-items: center; }
      .shopping-cart-wrapper .head .cart-number span {
        font-weight: 700;
        color: #026928;
        padding-left: 10px; }
  .shopping-cart-wrapper .body {
    max-height: 300px;
    overflow: auto; }
    .shopping-cart-wrapper .body::-webkit-scrollbar {
      width: 2px;
      height: 2px; }
    .shopping-cart-wrapper .body .scroll-wrapper.cartpanel {
      padding-right: 10px; }
      @media (max-width: 1023.98px) {
        .shopping-cart-wrapper .body .scroll-wrapper.cartpanel {
          display: none; } }
    .shopping-cart-wrapper .body .cart-item {
      padding: 10px 0;
      display: flex;
      align-items: center;
      position: relative; }
      .shopping-cart-wrapper .body .cart-item .remove-item {
        position: absolute;
        top: 10px;
        right: 5px;
        cursor: pointer;
        display: flex; }
        .shopping-cart-wrapper .body .cart-item .remove-item em {
          font-size: 1.3125rem; }
      .shopping-cart-wrapper .body .cart-item .image {
        height: 100px;
        flex: 0 0 85px;
        max-width: 85px; }
        .shopping-cart-wrapper .body .cart-item .image a {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center; }
          .shopping-cart-wrapper .body .cart-item .image a img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; }
      .shopping-cart-wrapper .body .cart-item .caption {
        margin-left: 10px;
        flex: 1;
        max-width: 100%;
        width: 100%; }
        .shopping-cart-wrapper .body .cart-item .caption .title {
          font-weight: 400;
          font-size: 1rem;
          padding-right: 15px;
          margin-bottom: 10px; }
          .shopping-cart-wrapper .body .cart-item .caption .title a {
            line-height: 1.3;
            transition: .3s all ease-in-out; }
            .shopping-cart-wrapper .body .cart-item .caption .title a:hover {
              color: #026928; }
          .shopping-cart-wrapper .body .cart-item .caption .title .price {
            padding: 3px 0px;
            font-weight: 700; }
        .shopping-cart-wrapper .body .cart-item .caption .wrap-price-quantity {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%; }
        .shopping-cart-wrapper .body .cart-item .caption .quantity {
          color: #009035;
          font-size: 0.75rem;
          white-space: nowrap; }
          .shopping-cart-wrapper .body .cart-item .caption .quantity span {
            color: #009035; }
      .shopping-cart-wrapper .body .cart-item + .cart-item {
        border-top: thin solid #ccc; }
  .shopping-cart-wrapper .btn-center .btn-buy-product {
    margin-top: 0;
    height: 45px; }
    @media (max-width: 767.98px) {
      .shopping-cart-wrapper .btn-center .btn-buy-product {
        max-width: 100%; } }

.checkout-wrapper {
  padding: 0;
  box-shadow: unset !important;
  padding-bottom: 0; }
  @media (max-width: 575.98px) {
    .checkout-wrapper {
      padding: 0;
      box-shadow: none !important; } }
  .checkout-wrapper .wrap-button-next-cart {
    display: flex;
    align-items: center; }
    .checkout-wrapper .wrap-button-next-cart .btn-shopping {
      max-width: 130px; }
    .checkout-wrapper .wrap-button-next-cart .btn-checkout {
      max-width: 300px; }
    @media (max-width: 1023.98px) {
      .checkout-wrapper .wrap-button-next-cart {
        margin-top: 20px;
        flex-wrap: wrap;
        display: none; }
        .checkout-wrapper .wrap-button-next-cart .btn {
          max-width: 100%;
          width: 100%;
          margin-left: 0;
          margin-bottom: 15px; } }
  .checkout-wrapper .content-wrapper {
    padding: 0 !important; }
    @media (max-width: 1023.98px) {
      .checkout-wrapper .content-wrapper {
        padding: 0px !important; } }
    @media (max-width: 575.98px) {
      .checkout-wrapper .content-wrapper {
        padding: 0 !important;
        margin-top: 15px; } }
  .checkout-wrapper .checkout-title {
    padding-left: 0px;
    padding-right: 0px;
    color: #000;
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 5px;
    font-weight: 600; }
    @media (max-width: 575.98px) {
      .checkout-wrapper .checkout-title {
        font-size: 1.125rem; } }
  .checkout-wrapper .cart-table-wrapper {
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 0px 15px; }
    @media (max-width: 575.98px) {
      .checkout-wrapper .cart-table-wrapper {
        background-color: transparent !important;
        box-shadow: unset !important;
        padding: 0 0px; } }
    @media screen and (max-width: 1023.67px) {
      .checkout-wrapper .cart-table-wrapper .carttable thead {
        display: none; } }
    .checkout-wrapper .cart-table-wrapper .carttable thead tr th {
      height: 40px;
      font-size: 1rem;
      font-weight: 400;
      color: #a2a2a2;
      border-bottom: 1px solid #e5e5e5; }
      .checkout-wrapper .cart-table-wrapper .carttable thead tr th + th {
        text-align: center; }
      .checkout-wrapper .cart-table-wrapper .carttable thead tr th:first-child {
        padding-left: 40px; }
    .checkout-wrapper .cart-table-wrapper .carttable tbody tr + tr {
      border-top: 1px solid #dad6cf;
      border-bottom: 0 !important; }
    @media screen and (max-width: 1023.67px) {
      .checkout-wrapper .cart-table-wrapper .carttable tbody tr {
        flex-direction: column;
        display: flex;
        padding-bottom: 10px; } }
    .checkout-wrapper .cart-table-wrapper .carttable tbody tr td {
      text-align: center; }
      @media screen and (max-width: 1023.67px) {
        .checkout-wrapper .cart-table-wrapper .carttable tbody tr td {
          display: flex;
          justify-content: space-between;
          align-items: center; } }
      @media screen and (max-width: 1023.67px) {
        .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(2), .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(3), .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(4) {
          padding: 5px 5px; } }
      .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:first-child {
        text-align: left;
        width: 40%; }
        @media screen and (max-width: 1023.67px) {
          .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:first-child {
            width: 100%; } }
      .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(2) {
        width: 24%; }
        @media screen and (max-width: 1023.67px) {
          .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(2) {
            width: 100%; } }
      .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(3) {
        width: 14%; }
        @media screen and (max-width: 1023.67px) {
          .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:nth-child(3) {
            width: 100%; } }
      .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:last-child {
        width: 16.5%;
        padding: 0 15px; }
        @media screen and (max-width: 1023.67px) {
          .checkout-wrapper .cart-table-wrapper .carttable tbody tr td:last-child {
            padding: 0 5px;
            width: 100%; } }
    @media screen and (max-width: 1023.67px) {
      .checkout-wrapper .cart-table-wrapper .carttable .product-item {
        width: 100%; } }
    .checkout-wrapper .cart-table-wrapper .carttable .product-item .title a {
      color: #000;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1.1; }
      .checkout-wrapper .cart-table-wrapper .carttable .product-item .title a:hover {
        color: #009035;
        text-decoration: underline; }
      @media (max-width: 575.98px) {
        .checkout-wrapper .cart-table-wrapper .carttable .product-item .title a {
          font-size: .9rem;
          padding-right: 15px; } }
    @media (max-width: 575.98px) {
      .checkout-wrapper .cart-table-wrapper .carttable .product-item .image {
        margin-top: 0px; } }
    .checkout-wrapper .cart-table-wrapper .carttable .product-item .image a {
      display: flex;
      padding: 10px;
      width: 140px;
      height: 100px;
      align-items: center;
      justify-content: center; }
      @media (max-width: 1279.98px) {
        .checkout-wrapper .cart-table-wrapper .carttable .product-item .image a {
          width: 90px; } }
      @media (max-width: 575.98px) {
        .checkout-wrapper .cart-table-wrapper .carttable .product-item .image a {
          width: 80px;
          height: auto; } }
      .checkout-wrapper .cart-table-wrapper .carttable .product-item .image a img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
    .checkout-wrapper .cart-table-wrapper .carttable .product-item .remove-item {
      display: flex;
      align-items: center;
      cursor: pointer; }
      @media screen and (max-width: 1024.67px) {
        .checkout-wrapper .cart-table-wrapper .carttable .product-item .remove-item {
          align-items: center; } }
      @media (max-width: 1023.98px) {
        .checkout-wrapper .cart-table-wrapper .carttable .product-item .remove-item {
          right: 6px;
          position: static; } }
      .checkout-wrapper .cart-table-wrapper .carttable .product-item .remove-item em {
        color: #026928;
        font-size: 14px;
        margin-right: 0 !important; }
      .checkout-wrapper .cart-table-wrapper .carttable .product-item .remove-item span {
        color: #999;
        font-size: 12px;
        padding-top: 2px;
        margin-left: 5px; }
        .checkout-wrapper .cart-table-wrapper .carttable .product-item .remove-item span:hover {
          color: #026928; }
    @media screen and (max-width: 1023.67px) {
      .checkout-wrapper .cart-table-wrapper .carttable .discount-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center; } }
    .checkout-wrapper .cart-table-wrapper .carttable .discount-wrap * {
      display: block; }
    .checkout-wrapper .cart-table-wrapper .carttable .discount-wrap .now-price {
      color: #000000;
      font-size: 1.125rem;
      font-weight: 700; }
    .checkout-wrapper .cart-table-wrapper .carttable .discount-wrap .old-price {
      color: #009035;
      font-size: 0.75rem;
      font-weight: 400;
      text-decoration: line-through; }
      @media screen and (max-width: 1023.67px) {
        .checkout-wrapper .cart-table-wrapper .carttable .discount-wrap .old-price {
          margin-left: 25px;
          position: relative;
          padding-right: 0; }
          .checkout-wrapper .cart-table-wrapper .carttable .discount-wrap .old-price::before {
            background-color: #999;
            width: 6px;
            height: .3px;
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px; } }
    .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount {
      display: flex;
      align-items: center;
      width: fit-content; }
      .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount input {
        width: 50px;
        text-align: center;
        border: 0;
        height: 40px;
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        background-color: white;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.4px; }
        @media (max-width: 575.98px) {
          .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount input {
            width: 45px;
            height: 30px; } }
      .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-dec {
        border-radius: 4px 0px 0px 4px; }
      .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-inc {
        border-radius: 0px 4px 4px 0px; }
      .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-dec, .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-inc {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 1px solid #d1d1d1;
        background-color: white;
        transition: .3s all ease-in-out; }
        @media (max-width: 575.98px) {
          .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-dec, .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-inc {
            width: 30px;
            height: 30px; } }
        .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-dec:hover, .checkout-wrapper .cart-table-wrapper .carttable .quantity-wrap .input-amount .btn-inc:hover {
          background-color: #d9d8d8; }
    .checkout-wrapper .cart-table-wrapper .carttable .total {
      color: #000;
      font-size: 1.125rem;
      white-space: nowrap;
      font-weight: 700; }
      @media (max-width: 1023.98px) {
        .checkout-wrapper .cart-table-wrapper .carttable .total {
          font-size: 1.2rem;
          color: #009035;
          font-weight: bold; } }
  .checkout-wrapper .total-money {
    color: #026928; }
  .checkout-wrapper .cart-fixed-mobile {
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 0;
    box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    padding: 15px;
    width: 100%; }
    .checkout-wrapper .cart-fixed-mobile .total {
      color: #009035;
      font-size: 1.375rem;
      font-weight: 700;
      margin-bottom: 0.625rem; }
    .checkout-wrapper .cart-fixed-mobile .wrap-button-buy {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr;
      grid-gap: 10px; }
      .checkout-wrapper .cart-fixed-mobile .wrap-button-buy .btn-shopping {
        background-color: #f5f5f5;
        color: #95907a;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-left: 0;
        font-weight: bold;
        max-width: unset; }
      .checkout-wrapper .cart-fixed-mobile .wrap-button-buy .btn-checkout {
        background-color: #009035;
        color: white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-left: 0;
        text-transform: uppercase;
        font-weight: bold;
        max-width: unset; }
    @media (min-width: 1024px) {
      .checkout-wrapper .cart-fixed-mobile {
        display: none; } }
  .checkout-wrapper .wrap-total-cart {
    width: 100%; }
    @media (max-width: 1023.98px) {
      .checkout-wrapper .wrap-total-cart {
        order: 1; } }
    .checkout-wrapper .wrap-total-cart table {
      width: 100%; }
  .checkout-wrapper .wrap-discount-button {
    align-items: baseline;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px !important;
    flex-wrap: wrap; }
    @media (max-width: 1023.98px) {
      .checkout-wrapper .wrap-discount-button {
        margin-top: 0.9375rem; } }
    @media (max-width: 575.98px) {
      .checkout-wrapper .wrap-discount-button {
        padding: 0px 0 0 0 !important; } }
    .checkout-wrapper .wrap-discount-button .checkout-discount {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%; }
      @media (max-width: 1023.98px) {
        .checkout-wrapper .wrap-discount-button .checkout-discount {
          order: 2; } }
    .checkout-wrapper .wrap-discount-button .price-wrapper {
      margin-bottom: 15px; }
      @media (max-width: 1023.98px) {
        .checkout-wrapper .wrap-discount-button .price-wrapper {
          margin-bottom: 0; } }
      @media screen and (max-width: 1023.67px) {
        .checkout-wrapper .wrap-discount-button .price-wrapper table {
          width: 100%; } }
      @media (max-width: 1023.98px) {
        .checkout-wrapper .wrap-discount-button .price-wrapper table tr:last-child {
          display: none; } }
      .checkout-wrapper .wrap-discount-button .price-wrapper table tr td {
        padding: 5px 0px; }
        .checkout-wrapper .wrap-discount-button .price-wrapper table tr td:first-child {
          color: #000;
          font-size: 1rem;
          font-weight: 700;
          padding-right: 92px;
          padding-top: 4px; }
          @media (max-width: 575.98px) {
            .checkout-wrapper .wrap-discount-button .price-wrapper table tr td:first-child {
              font-size: 1.1rem; } }
        .checkout-wrapper .wrap-discount-button .price-wrapper table tr td:last-child {
          color: #000;
          font-size: 1.1rem;
          font-weight: 700;
          text-align: right; }
          @media (max-width: 575.98px) {
            .checkout-wrapper .wrap-discount-button .price-wrapper table tr td:last-child {
              font-size: 1.1rem; } }
  .checkout-wrapper .mobile-title {
    display: none; }
    @media screen and (max-width: 1023.67px) {
      .checkout-wrapper .mobile-title {
        font-weight: 700;
        display: inline-block; } }

.checkout-title {
  margin-bottom: 1.25rem; }
  @media (max-width: 1023.98px) {
    .checkout-title {
      margin-bottom: 0.9375rem; } }
  .checkout-title span {
    text-align: center; }
    @media (max-width: 767.98px) {
      .checkout-title span {
        font-size: 1rem; } }

.cart-display .cart-button-checkout {
  margin-top: 15px !important; }

@media (max-width: 1023.98px) {
  .cart-display {
    padding: 1.875rem 0px !important; } }

@media (max-width: 1023.98px) {
  .cart-display .product-item {
    flex-direction: row !important; } }

.number-quantity {
  font-size: 1.5rem !important; }

.cartpage {
  background-color: #f1f1f1; }
  @media (max-width: 575.98px) {
    .cartpage {
      background-color: #fff; } }

@media (max-width: 1023.98px) {
  .check-out {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important; } }

.check-out .button-mobile-cart {
  display: none; }
  @media (max-width: 1023.98px) {
    .check-out .button-mobile-cart {
      display: flex; }
      .check-out .button-mobile-cart .wrap-button-next-cart {
        width: 100%; }
      .check-out .button-mobile-cart .cart-button {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        grid-gap: 10px; } }

.check-out .cart-wrapper {
  background: #ffffff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
  padding-top: 23px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px; }
  .check-out .cart-wrapper .admin-title {
    margin-bottom: 1.875rem;
    border-bottom: 3px solid #ebebeb;
    padding-bottom: 0.625rem; }
    .check-out .cart-wrapper .admin-title .text {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.125rem; }
      .check-out .cart-wrapper .admin-title .text p {
        padding-right: 10px;
        text-transform: uppercase; }
      .check-out .cart-wrapper .admin-title .text span {
        padding-right: 10px;
        color: red; }
      @media screen and (max-width: 991px) {
        .check-out .cart-wrapper .admin-title .text {
          font-size: .8rem; } }
  .check-out .cart-wrapper .wrapper {
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px; }
    .check-out .cart-wrapper .wrapper .carttable {
      width: 100%; }
      .check-out .cart-wrapper .wrapper .carttable thead tr th {
        background: #f0f0f0;
        line-height: 40px;
        height: 50px;
        color: #333;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase; }
        .check-out .cart-wrapper .wrapper .carttable thead tr th:first-child {
          padding-left: 2rem;
          text-align: left; }
      .check-out .cart-wrapper .wrapper .carttable thead tr:first-child th:first-child {
        border-top-left-radius: 5px; }
      .check-out .cart-wrapper .wrapper .carttable thead tr:last-child th:last-child {
        border-top-right-radius: 5px; }
      @media screen and (max-width: 991px) {
        .check-out .cart-wrapper .wrapper .carttable thead {
          display: none; } }
      .check-out .cart-wrapper .wrapper .carttable tbody tr td {
        padding: 1rem;
        width: 20%;
        font-weight: 500; }
        .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .image {
            width: 80px; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .image a {
              position: relative;
              display: block;
              height: 0;
              overflow: hidden;
              padding-top: 100%; }
              .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .image a img, .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .image a iframe, .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .image a video {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                transition: .3s ease-in-out all; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .caption {
            margin-left: 3.75rem;
            margin-bottom: 1rem;
            font-size: 1rem;
            line-height: 1.625rem; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .caption .title a {
              text-align: center;
              display: block;
              margin-bottom: .25rem;
              color: #333;
              font-size: 1rem; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .caption .remove-item {
              display: flex;
              align-items: center;
              justify-content: flex-star; }
              .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .caption .remove-item .lnr {
                display: block;
                margin-right: .5rem;
                height: 16px;
                color: #ec1f27;
                font-size: 16px; }
              .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .caption .remove-item span {
                color: #999;
                font-size: 12px;
                padding-top: 2px;
                margin-left: 5px; }
                .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item .caption .remove-item span:hover {
                  color: #026928; }
          @media screen and (max-width: 375px) {
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .product-item {
              flex-direction: column; } }
        .check-out .cart-wrapper .wrapper .carttable tbody tr td .price {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr td .price .current {
            color: #333;
            font-size: 1.125rem;
            line-height: 22px;
            font-weight: 700;
            margin-bottom: 5px; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr td .price .old-wrap {
            display: flex; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .price .old-wrap .old-price {
              padding-right: 12px;
              font-size: 1rem;
              color: red;
              line-height: 22px;
              text-decoration: line-through; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .price .old-wrap .discount {
              padding-left: 12px;
              position: relative;
              font-weight: 600;
              font-size: 1rem;
              line-height: 22px;
              color: red; }
              .check-out .cart-wrapper .wrapper .carttable tbody tr td .price .old-wrap .discount::before {
                content: '';
                position: absolute;
                left: 0;
                width: 1px;
                height: 100%;
                background: #e1e1e1; }
        .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity {
          width: 100%; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group {
            border-width: 1px;
            border-color: #e1e1e1;
            border-style: solid;
            background-color: white;
            width: 160px;
            height: 40px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            border-radius: 5px; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group input {
              border: none;
              width: 80px;
              height: 100%;
              text-align: center; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group .btn-dec,
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group .btn-inc {
              min-width: 40px;
              height: 40px;
              background: #e5e5e5;
              font-size: 1.5rem;
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group .btn-dec {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group .btn-inc {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group .form-control {
              padding: 0;
              border: none;
              text-align: center; }
              .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group .form-control:focus {
                box-shadow: none; }
            @media screen and (max-width: 991.98px) {
              .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group {
                width: 100%; }
                .check-out .cart-wrapper .wrapper .carttable tbody tr td .quantity-wrapper .item-quantity .input-group input {
                  width: 100%; } }
        .check-out .cart-wrapper .wrapper .carttable tbody tr td .total {
          color: #333;
          font-size: 1.125rem;
          font-weight: 700;
          text-align: center; }
        .check-out .cart-wrapper .wrapper .carttable tbody tr td:first-child {
          width: 40%; }
      .check-out .cart-wrapper .wrapper .carttable tbody tr:first-child td:last-child {
        border-bottom-left-radius: 5px; }
      .check-out .cart-wrapper .wrapper .carttable tbody tr:last-child td {
        padding-bottom: 2rem; }
        .check-out .cart-wrapper .wrapper .carttable tbody tr:last-child td:last-child {
          border-bottom-right-radius: 5px; }
      @media screen and (max-width: 991px) {
        .check-out .cart-wrapper .wrapper .carttable tbody tr {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr td {
            padding: .9rem;
            width: 100%; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .price {
              align-items: flex-start; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td:first-child {
              width: 100%; }
            .check-out .cart-wrapper .wrapper .carttable tbody tr td .total {
              text-align: left; }
          .check-out .cart-wrapper .wrapper .carttable tbody tr:last-child td {
            padding-bottom: .9rem; } }
  .check-out .cart-wrapper .total-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-top: 1.875rem; }
    .check-out .cart-wrapper .total-wrapper .total-table {
      width: 100%; }
      .check-out .cart-wrapper .total-wrapper .total-table tbody tr td {
        padding: 0 .5rem;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 40px; }
      .check-out .cart-wrapper .total-wrapper .total-table tbody tr:last-child td:last-child {
        color: red; }
    .check-out .cart-wrapper .total-wrapper .btn-right {
      padding-top: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary {
        transition: .3s all ease-in-out;
        width: 280px;
        padding: 0.8125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 1rem;
        text-transform: uppercase; }
        .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary .lnr {
          padding-right: 10px; }
        .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary span {
          font-size: 1rem;
          font-weight: 500; }
        .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.red {
          background: red;
          color: white; }
          .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.red .lnr {
            padding-right: 0;
            padding-left: 10px; }
          .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.red:hover {
            background: #ebebeb; }
            .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.red:hover .lnr,
            .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.red:hover span {
              color: #000; }
        .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.gray-6 {
          background: #ebebeb; }
          @media screen and (max-width: 375px) {
            .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.gray-6 {
              margin-bottom: .9rem; } }
          .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.gray-6:hover {
            background: #009035; }
            .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.gray-6:hover .lnr,
            .check-out .cart-wrapper .total-wrapper .btn-right .btn-primary.gray-6:hover span {
              color: white; }
      .check-out .cart-wrapper .total-wrapper .btn-right a:not(:last-child) {
        margin-right: 1.25rem; }
      @media screen and (max-width: 575px) {
        .check-out .cart-wrapper .total-wrapper .btn-right {
          flex-direction: column;
          max-width: auto; }
          .check-out .cart-wrapper .total-wrapper .btn-right a:not(:last-child) {
            margin-right: 0; } }
    @media screen and (max-width: 991px) {
      .check-out .cart-wrapper .total-wrapper {
        align-items: flex-start; } }

.check-out.step-2 .btn-checkout {
  color: white;
  text-transform: uppercase;
  font-weight: 700; }

@media (max-width: 1023.98px) {
  .check-out.step-2 .payment-form + .payment-form {
    margin-top: 30px; } }

.check-out.step-2 .Module-194 .ModuleContent {
  position: sticky;
  top: 150px; }

@media (max-width: 1023.98px) {
  .check-out.step-2 .button-mobile-cart {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 99;
    padding: 5px 15px;
    flex-wrap: wrap; }
    .check-out.step-2 .button-mobile-cart .wrap-button-next-cart {
      margin-top: 0 !important;
      padding: 5px 0px; }
      .check-out.step-2 .button-mobile-cart .wrap-button-next-cart .btn {
        width: 100% !important;
        max-width: 100% !important; }
    .check-out.step-2 .button-mobile-cart .btn-checkout {
      margin-left: 0px !important; }
    .check-out.step-2 .button-mobile-cart .total-price-cart {
      flex: 0 0 100%;
      max-width: 100%; }
      .check-out.step-2 .button-mobile-cart .total-price-cart .price {
        font-weight: bold;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 0px; }
      .check-out.step-2 .button-mobile-cart .total-price-cart .total {
        color: #009035;
        margin-left: 15px; }
    .check-out.step-2 .button-mobile-cart .btn-right {
      flex: 0 0 100%;
      max-width: 100%; } }

.check-out.step-2 label.error {
  display: block;
  color: red;
  font-size: .8rem;
  position: absolute;
  top: -18px;
  right: 0px;
  animation: AlertError 0.4s 1 linear !important; }
  @media (max-width: 767.98px) {
    .check-out.step-2 label.error {
      bottom: -20px;
      top: auto; } }

.check-out.step-2 #divCompany {
  width: 100%; }

@media (max-width: 767.98px) {
  .check-out.step-2 .wrap-button-next-cart {
    margin-top: 10px; } }

@media (max-width: 1199.98px) {
  .check-out.step-2 .wrap-button-next-cart .btn {
    width: 195px; } }

@media (max-width: 575.98px) {
  .check-out.step-2 .wrap-button-next-cart .btn {
    width: 100%; } }

.check-out.step-2 .select-icon {
  position: relative; }
  .check-out.step-2 .select-icon::before {
    content: "\f078";
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2; }

.check-out.step-2 .check-text {
  color: #000;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 2px; }

.check-out.step-2 .xuat-hoa-don-input {
  padding: 0 15px;
  margin-top: 1.25rem;
  width: 100%;
  margin-bottom: 22px; }
  @media (max-width: 1023.98px) {
    .check-out.step-2 .xuat-hoa-don-input {
      margin-bottom: 0; } }
  .check-out.step-2 .xuat-hoa-don-input label {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    display: flex; }
    .check-out.step-2 .xuat-hoa-don-input label::before {
      content: 'check_box_outline_blank';
      font-family: 'Material Icons';
      color: #000;
      margin-top: -2px;
      margin-right: 11px;
      font-size: 18px;
      font-weight: 400; }
  .check-out.step-2 .xuat-hoa-don-input input {
    display: none; }
    .check-out.step-2 .xuat-hoa-don-input input:checked ~ label::before {
      content: 'check_box'; }

.check-out.step-2 #divCompany {
  width: 100%;
  padding: 0 15px; }

.check-out.step-2 .massive-wrapper {
  background: white;
  box-shadow: 0 0 20px rgba(33, 33, 33, 0.1);
  padding: 21px 27px 54px;
  background: #fff;
  border-radius: 10px; }
  @media (max-width: 575.98px) {
    .check-out.step-2 .massive-wrapper {
      box-shadow: none;
      padding: 0px 0px 0px; } }
  .check-out.step-2 .massive-wrapper .carttitle {
    margin-bottom: 1.5625rem; }
  @media (max-width: 1023.98px) {
    .check-out.step-2 .massive-wrapper .receive-address-btn {
      display: none; } }
  .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group {
        flex-wrap: wrap; } }
    @media (max-width: 767.98px) {
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .label {
        margin-bottom: 9px; } }
    .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .label label {
      font-size: 1rem;
      line-height: 22px; }
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .label label span {
        color: red; }
    @media screen and (max-width: 1279.98px) {
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .label {
        flex: 1 0 30%; } }
    @media (max-width: 767.98px) {
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .label {
        flex: 0 0 100%; } }
    .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input {
      width: 490px;
      height: 50px;
      position: relative; }
      @media screen and (max-width: 1279.98px) {
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input {
          flex: 1 0 70%; } }
      @media (max-width: 767.98px) {
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input {
          flex: 0 0 100%; } }
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input input,
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input select {
        width: 100%;
        height: 100%;
        border: none;
        background: #f0f0f0;
        padding-left: 15px;
        border-radius: 5px; }
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        position: relative;
        background-image: url("../img/icons/select.png");
        background-repeat: no-repeat;
        background-position: 95%; }
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .input select::-ms-expand {
          display: none; }
    .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .textarea {
      width: 490px;
      height: 70px;
      position: relative; }
      @media screen and (max-width: 1279.98px) {
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .textarea {
          flex: 1 0 70%; } }
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .textarea textarea {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: none;
        background: #f0f0f0;
        padding-left: 15px;
        padding-top: 15px; }
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .textarea textarea::-webkit-resizer {
          background-color: transparent; }
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group .textarea textarea::-webkit-resizer {
          background-image: url("../img/icons/textarea.png");
          background-repeat: no-repeat; }
  .check-out.step-2 .massive-wrapper .receive-address .cart-form .group .form-group + .form-group {
    margin-top: 20px; }
  .check-out.step-2 .massive-wrapper .receive-address .cart-form .group.other-address {
    margin-top: 51px; }
    @media (max-width: 1023.98px) {
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group.other-address {
        margin-top: 2.1875rem; } }
    .check-out.step-2 .massive-wrapper .receive-address .cart-form .group.other-address .check-group {
      margin-bottom: 30px; }
      .check-out.step-2 .massive-wrapper .receive-address .cart-form .group.other-address .check-group #btn-ttmh .click-more-address {
        color: #000;
        font-weight: 700;
        position: relative;
        display: inline-block; }
        .check-out.step-2 .massive-wrapper .receive-address .cart-form .group.other-address .check-group #btn-ttmh .click-more-address::before {
          content: '';
          position: absolute;
          top: 112%;
          left: 0;
          height: 1px;
          background-color: #000;
          width: 100%; }
    .check-out.step-2 .massive-wrapper .receive-address .cart-form .group.other-address .user-info {
      display: none;
      margin-bottom: 30px; }
  .check-out.step-2 .massive-wrapper .payment-info {
    padding-top: 30px; }
    @media (max-width: 1199.98px) {
      .check-out.step-2 .massive-wrapper .payment-info {
        padding-top: 2.8125rem; } }
    .check-out.step-2 .massive-wrapper .payment-info .cart-form {
      padding-bottom: 37px; }
      @media (max-width: 1023.98px) {
        .check-out.step-2 .massive-wrapper .payment-info .cart-form {
          padding-bottom: 0px; } }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group {
        display: flex;
        align-items: center; }
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group input[type="radio"] {
          cursor: pointer;
          appearance: none;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAB/klEQVRoQ+2XT2rCUBDGR4lJILhK1YW4UHHx8Ai9QAulvUG7ak/QnsKeoF21N2gptBfoEeQtRF2IC7VZiZA/EsqzCkW0SXh5dAwTeIvATPJ983svM8lBxq9cxv0BGTx0wkSQCCKvAG1R5IAi5RHByBIhDyCCyAFFyiOCkSVCHkAEkQOKlEcEI0uEPIAIygLq9XrGcrm8BIAzADheP+8TAN7CMHxqt9u+7Dv+yldKkHN+CwAd27bBNM3VEpfruqvlOI64vWOM3asyqcwg5/zdsqyTcrkMhmHs1O/7PkwmE1gsFh+MsVMVJpUYFOQsy+rUarVYmkejkTCphGTqBrvdrp7P571GowG6rscy6HkeDIdD0DTNbLVaXqykmEGpG+ScX9u2/VAqlWJK+AmbzWbiTN4wxh4TJUYEqzD4Uq1Wz4vFYiKd8/kcxuPxK2PsIlHiPxj8ajabdqFQSKQzCALo9/sOY+woUSIZTLNcAMA5z/wWzfZHZj2aufV6fW+D3940ouEPBgMIw9BIe3RL/SsqxGe60W/obEa1SqWyt+GLBj+dTg9vVPtlMrvD9sbkenS72vW7pGnac9qj2fb5VnIGU+48Uo8jg1LlQ5BMBBFAkJJABKXKhyCZCCKAICWBCEqVD0EyEUQAQUoCEZQqH4JkIogAgpQEIihVPgTJmSf4DWNIwDkYSBQTAAAAAElFTkSuQmCC");
          background-position: 50%;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px; }
          .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group input[type="radio"]:checked {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAACUElEQVRoQ+2XP4gaURDGv23OtPs2vY1op1iqlYKQYEwaQSutEhAbhaTxtPJPcwFtVEgq7QSbi5EEBK3UUrRTbOzj2zaXZoNLDkLw3Ag7sC5vu4XHzHzfb97OrASbP5LN9UEIvHbCgqAgaHEHRItaHJBheYKgoUUWPyAIWhyQYXmCoKFFFj8gCFockGF5gqChRRY/IAiaAMjBGEtrmvYKQOhPvJkkSV85510Av0zI8WQIUoKMsfeSJN3l83n4fD54vV69kPV6jdVqhWazCU3TPnDOP1KJJBMoy/K3SCTyolarwePxnKx/u92iWCxiMpl8V1X1JYVIEoFHcuFw+G4wGOg1LxYL9Ho9jMdj/T0ajSKdTiMQCOjviUQC0+mUhCSFwBtFUR7m8zncbjfK5TJardZJOLlcDpVKBZvNBqFQCIfD4RmABzNJmi6QMfa2UCh8KpVKOrlYLHa23tFopJOsVqtoNBrvOOefLS1QluX7brf7Oh6PI5vNot/vn603lUqh3W5jOBwik8l8UVX1jdUF/lgul4rT6YTL5QLn/Gy9jDHsdjvs93v4/f6DqqrPhcALHDD9Dl7aoslkEp1O53pa1PYfGQAORVF+zmYzfcD/z5g4DvxgMHgcEw6zVzfTW/R4Pew+6PVPwOOqVq/X9YF/6jkO+Nvb2+tb1R7F2HrZ/ovYDWMs88TvUs/s1ezfTiG5gxeMKfKjQiC5xcQJBEFig8nDC4LkFhMnEASJDSYPLwiSW0ycQBAkNpg8vCBIbjFxAkGQ2GDy8IIgucXECWxP8Dd10wJIhynNlgAAAABJRU5ErkJggg=="); }
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group label {
          width: calc( 100% -40px);
          background: white;
          border: solid 1px #e1e1e1;
          border-radius: 5px;
          margin-left: 23px;
          padding: 15px 15px 15px 25px; }
          .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group label .wrap {
            display: flex;
            align-items: center; }
            .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group label .wrap .des {
              margin-right: 20px;
              color: #7c7c7c; }
            .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group label .wrap select {
              width: 200px;
              border: 0;
              border-bottom: 1px solid #e1e1e1; }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .form-group + .form-group {
        margin-top: 20px; }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .title-ck span {
        margin-bottom: 4px;
        text-transform: uppercase;
        display: block; }
      @media (max-width: 767.98px) {
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .method-info table tr {
          display: flex;
          flex-direction: column; } }
      @media (max-width: 767.98px) {
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .method-info table tr td {
          flex: 0 0 100%;
          max-width: 100%; } }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .method-info table tr td:first-child {
        padding-right: 27px;
        color: #000; }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group {
        display: flex;
        align-items: center; }
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group input[type="radio"] {
          cursor: pointer;
          appearance: none;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAB/klEQVRoQ+2XT2rCUBDGR4lJILhK1YW4UHHx8Ai9QAulvUG7ak/QnsKeoF21N2gptBfoEeQtRF2IC7VZiZA/EsqzCkW0SXh5dAwTeIvATPJ983svM8lBxq9cxv0BGTx0wkSQCCKvAG1R5IAi5RHByBIhDyCCyAFFyiOCkSVCHkAEkQOKlEcEI0uEPIAIygLq9XrGcrm8BIAzADheP+8TAN7CMHxqt9u+7Dv+yldKkHN+CwAd27bBNM3VEpfruqvlOI64vWOM3asyqcwg5/zdsqyTcrkMhmHs1O/7PkwmE1gsFh+MsVMVJpUYFOQsy+rUarVYmkejkTCphGTqBrvdrp7P571GowG6rscy6HkeDIdD0DTNbLVaXqykmEGpG+ScX9u2/VAqlWJK+AmbzWbiTN4wxh4TJUYEqzD4Uq1Wz4vFYiKd8/kcxuPxK2PsIlHiPxj8ajabdqFQSKQzCALo9/sOY+woUSIZTLNcAMA5z/wWzfZHZj2aufV6fW+D3940ouEPBgMIw9BIe3RL/SsqxGe60W/obEa1SqWyt+GLBj+dTg9vVPtlMrvD9sbkenS72vW7pGnac9qj2fb5VnIGU+48Uo8jg1LlQ5BMBBFAkJJABKXKhyCZCCKAICWBCEqVD0EyEUQAQUoCEZQqH4JkIogAgpQEIihVPgTJmSf4DWNIwDkYSBQTAAAAAElFTkSuQmCC");
          background-position: 50%;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px; }
          @media screen and (max-width: 767.67px) {
            .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group input[type="radio"] {
              display: none; } }
          .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group input[type="radio"]:checked {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAACUElEQVRoQ+2XP4gaURDGv23OtPs2vY1op1iqlYKQYEwaQSutEhAbhaTxtPJPcwFtVEgq7QSbi5EEBK3UUrRTbOzj2zaXZoNLDkLw3Ag7sC5vu4XHzHzfb97OrASbP5LN9UEIvHbCgqAgaHEHRItaHJBheYKgoUUWPyAIWhyQYXmCoKFFFj8gCFockGF5gqChRRY/IAiaAMjBGEtrmvYKQOhPvJkkSV85510Av0zI8WQIUoKMsfeSJN3l83n4fD54vV69kPV6jdVqhWazCU3TPnDOP1KJJBMoy/K3SCTyolarwePxnKx/u92iWCxiMpl8V1X1JYVIEoFHcuFw+G4wGOg1LxYL9Ho9jMdj/T0ajSKdTiMQCOjviUQC0+mUhCSFwBtFUR7m8zncbjfK5TJardZJOLlcDpVKBZvNBqFQCIfD4RmABzNJmi6QMfa2UCh8KpVKOrlYLHa23tFopJOsVqtoNBrvOOefLS1QluX7brf7Oh6PI5vNot/vn603lUqh3W5jOBwik8l8UVX1jdUF/lgul4rT6YTL5QLn/Gy9jDHsdjvs93v4/f6DqqrPhcALHDD9Dl7aoslkEp1O53pa1PYfGQAORVF+zmYzfcD/z5g4DvxgMHgcEw6zVzfTW/R4Pew+6PVPwOOqVq/X9YF/6jkO+Nvb2+tb1R7F2HrZ/ovYDWMs88TvUs/s1ezfTiG5gxeMKfKjQiC5xcQJBEFig8nDC4LkFhMnEASJDSYPLwiSW0ycQBAkNpg8vCBIbjFxAkGQ2GDy8IIgucXECWxP8Dd10wJIhynNlgAAAABJRU5ErkJggg=="); }
          .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group input[type="radio"]:checked ~ label {
            border: 1px solid #009035; }
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label {
          width: 100%;
          margin-left: 23px;
          border: solid 1px #e1e1e1;
          transition: .3s all ease-in-out; }
          @media (max-width: 767.98px) {
            .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label {
              margin-left: 0px; } }
          .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info {
            width: 100%;
            display: flex;
            align-items: center; }
            @media (max-width: 767.98px) {
              .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info {
                flex-wrap: wrap; } }
            .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info .img {
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: solid 1px #e1e1e1;
              flex: 0 0 100px;
              max-width: 100%; }
              @media (max-width: 767.98px) {
                .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info .img {
                  flex: 0 0 100%;
                  max-width: 100%; } }
            .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info .text-wrap {
              padding: 15px 0px;
              padding-left: 30px;
              flex: 1; }
              @media (max-width: 767.98px) {
                .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info .text-wrap {
                  flex: 0 0 100%;
                  max-width: 100%; } }
              @media (max-width: 575.98px) {
                .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method .select-group label .method-info .text-wrap {
                  padding: 0 10px !important;
                  padding-bottom: 10px !important; } }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method:last-child .select-group label .method-info .img {
        height: 130px; }
        @media (max-width: 575.98px) {
          .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method:last-child .select-group label .method-info .img {
            height: 90px; } }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method:last-child .select-group label .method-info .text-wrap {
        padding-top: 18px;
        padding-bottom: 10px; }
      @media (max-width: 767.98px) {
        .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method:first-child .text-wrap .text {
          text-align: center; } }
      .check-out.step-2 .massive-wrapper .payment-info .cart-form .payment-method + .payment-method {
        margin-top: 20px; }
  .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right {
    display: flex;
    justify-content: flex-end; }
    .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 50px;
      border-radius: 5px;
      text-transform: uppercase;
      transition: all .5s; }
      .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary em {
        font-size: 1.25rem; }
      .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary.gray-6 {
        background: #ebebeb; }
        .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary.gray-6 em {
          padding-right: 10px; }
        .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary.gray-6:hover {
          background: red;
          color: white; }
      .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary.red {
        background: red;
        color: white;
        margin-left: 20px; }
        .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary.red em {
          padding-left: 10px; }
        .check-out.step-2 .massive-wrapper .receive-address-btn .btn-right .btn-primary.red:hover {
          background: #ebebeb;
          color: #000; }

.check-out.step-2 .minicart {
  padding-bottom: 11px; }
  @media (max-width: 1023.98px) {
    .check-out.step-2 .minicart {
      margin-top: 30px; } }
  @media (max-width: 575.98px) {
    .check-out.step-2 .minicart .carttitle {
      margin-bottom: 0; } }
  .check-out.step-2 .minicart .carttitle a em {
    color: #009035;
    font-size: 1.125rem;
    font-weight: 400; }
  .check-out.step-2 .minicart .carttable .product {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: thin solid #ebebeb; }
    .check-out.step-2 .minicart .carttable .product .productimg {
      flex: 0 0 72px;
      align-items: center;
      justify-content: center;
      display: flex; }
      .check-out.step-2 .minicart .carttable .product .productimg a {
        display: block;
        width: 100%;
        height: 65px; }
        @media (max-width: 1024.98px) {
          .check-out.step-2 .minicart .carttable .product .productimg a {
            height: 100px; } }
        @media (max-width: 767.98px) {
          .check-out.step-2 .minicart .carttable .product .productimg a {
            width: 100%;
            height: auto; } }
      .check-out.step-2 .minicart .carttable .product .productimg img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
        @media screen and (max-width: 375px) {
          .check-out.step-2 .minicart .carttable .product .productimg img {
            padding: 10px 5px; } }
    .check-out.step-2 .minicart .carttable .product .content {
      display: flex;
      margin-left: 15px;
      justify-content: space-between;
      width: 100%; }
      @media screen and (max-width: 991px) {
        .check-out.step-2 .minicart .carttable .product .content {
          flex: 1; } }
      @media screen and (max-width: 576px) {
        .check-out.step-2 .minicart .carttable .product .content {
          flex-direction: column; } }
      @media (max-width: 1199.98px) {
        .check-out.step-2 .minicart .carttable .product .content .productdetail {
          display: flex;
          align-items: center; } }
      .check-out.step-2 .minicart .carttable .product .content .productname {
        font-size: 1rem;
        font-weight: 400;
        max-width: 200px;
        padding-top: 5px; }
        @media (max-width: 1199.98px) {
          .check-out.step-2 .minicart .carttable .product .content .productname {
            font-size: 0.875rem; } }
      .check-out.step-2 .minicart .carttable .product .content .amount {
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        white-space: nowrap;
        flex-direction: column;
        font-weight: 400; }
        @media (max-width: 1199.98px) {
          .check-out.step-2 .minicart .carttable .product .content .amount {
            font-size: 0.875rem; } }
        @media screen and (max-width: 576px) {
          .check-out.step-2 .minicart .carttable .product .content .amount {
            margin-top: 0px;
            flex-direction: row;
            justify-content: flex-start; }
            .check-out.step-2 .minicart .carttable .product .content .amount .price {
              margin-right: 5px; }
            .check-out.step-2 .minicart .carttable .product .content .amount .count {
              margin-right: 5px; } }
        .check-out.step-2 .minicart .carttable .product .content .amount .total {
          font-weight: 800;
          font-size: 1.125rem; }
          @media (max-width: 1199.98px) {
            .check-out.step-2 .minicart .carttable .product .content .amount .total {
              font-size: 1rem; } }
          @media screen and (max-width: 576px) {
            .check-out.step-2 .minicart .carttable .product .content .amount .total {
              position: relative;
              margin-left: auto; } }
  .check-out.step-2 .minicart .carttable .bottom {
    padding: 15px 0px; }
    .check-out.step-2 .minicart .carttable .bottom .text {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .check-out.step-2 .minicart .carttable .bottom .text:last-child .value {
        color: #009035; }
      .check-out.step-2 .minicart .carttable .bottom .text .label {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 40px; }
      .check-out.step-2 .minicart .carttable .bottom .text .value {
        font-size: 1.125rem;
        font-weight: 800;
        line-height: 26px; }
        .check-out.step-2 .minicart .carttable .bottom .text .value.discount-total, .check-out.step-2 .minicart .carttable .bottom .text .value.shipping-total {
          font-weight: normal; }
        .check-out.step-2 .minicart .carttable .bottom .text .value.order-total {
          width: auto;
          padding: 0;
          font-size: 1.125rem;
          line-height: 40px;
          margin: 0; }

.checkout-complete-section .completed-wrapper {
  width: 100%;
  padding-top: 14px;
  padding-left: 26px;
  padding-right: 34px;
  padding-bottom: 50px;
  background: white; }
  .checkout-complete-section .completed-wrapper .title {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    line-height: 24px;
    color: #000;
    font-weight: 700;
    padding-bottom: 8px;
    text-transform: uppercase;
    border-bottom: solid 3px #ebebeb; }
  .checkout-complete-section .completed-wrapper img {
    padding-right: 18px; }
  .checkout-complete-section .completed-wrapper .article-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 50px; }
    .checkout-complete-section .completed-wrapper .article-content .content {
      padding-bottom: 30px; }
      .checkout-complete-section .completed-wrapper .article-content .content p {
        line-height: 22px; }
        .checkout-complete-section .completed-wrapper .article-content .content p .email {
          color: #00c1ef; }
  .checkout-complete-section .completed-wrapper .cart-group-button {
    margin-bottom: 50px; }
    .checkout-complete-section .completed-wrapper .cart-group-button .btn-next {
      width: 280px;
      height: 50px;
      border-radius: 5px;
      background: #ebebeb;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      transition: all .3s; }
      .checkout-complete-section .completed-wrapper .cart-group-button .btn-next em {
        font-size: 1.25rem; }
      .checkout-complete-section .completed-wrapper .cart-group-button .btn-next span {
        padding-left: 10px; }
      @media screen and (min-width: 1025px) {
        .checkout-complete-section .completed-wrapper .cart-group-button .btn-next:hover {
          background: #009035;
          color: white; } }

.checkout-discount {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }
  .checkout-discount .admin-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    line-height: 18px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: solid 3px #ebebeb; }
  .checkout-discount .carttable .normal-text {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 22px; }
  .checkout-discount .setting-control {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    height: 40px; }
    @media (max-width: 1023.98px) {
      .checkout-discount .setting-control {
        margin-top: 15px;
        margin-bottom: 0; } }
    .checkout-discount .setting-control input {
      max-width: 100%;
      flex: 1;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      font-size: 0.875rem;
      border: none;
      padding: 5px 15px;
      border: 1px solid #f5f5f5;
      order: 1; }
      @media (max-width: 1279.98px) {
        .checkout-discount .setting-control input {
          font-size: 1rem; } }
    .checkout-discount .setting-control button {
      flex: 0 0 130px;
      width: 100%;
      border: none;
      height: 100%;
      margin-left: 15px;
      order: 2;
      color: #009035;
      background-color: #f3f3f3;
      font-size: 0.875rem;
      text-align: center;
      border-radius: 5px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      font-weight: 700;
      transition: .3s all ease-in-out; }
      @media (max-width: 1279.98px) {
        .checkout-discount .setting-control button {
          flex: 0 0 90px; } }
      @media (max-width: 575.98px) {
        .checkout-discount .setting-control button {
          flex: 0 0 100px; } }
      .checkout-discount .setting-control button:hover {
        background: #009035;
        letter-spacing: 2px;
        color: #009035; }
    .checkout-discount .setting-control .code {
      font-size: 18px;
      padding: 5px 15px;
      height: 40px;
      border: 1px solid #009035;
      margin-right: 15px;
      font-weight: 700;
      color: #009035; }
    .checkout-discount .setting-control .coupon-apply-error {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      color: red;
      font-style: italic;
      order: 3; }
  .checkout-discount .check-text {
    margin-bottom: 20px;
    color: red; }

.carttitle {
  display: flex;
  align-items: center;
  color: #009035;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: space-between;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 0.625rem;
  border-bottom: solid 1px #f5f5f5; }
  @media (max-width: 767.98px) {
    .carttitle {
      font-size: 1.25rem; } }

.search-product-wrapper .product-item .pro-img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 40%; }
  .search-product-wrapper .product-item .pro-img a img, .search-product-wrapper .product-item .pro-img a iframe, .search-product-wrapper .product-item .pro-img a video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: .3s ease-in-out all; }

.recover-password .login-box {
  padding: 30px;
  background: white;
  box-shadow: 0 0 20px rgba(33, 33, 33, 0.2); }

.recover-password .card .login-card-body .login-box-msg {
  padding-bottom: 7px;
  font-size: 1.125rem;
  font-weight: 700; }

.recover-password .card .login-card-body .input-group {
  position: relative;
  width: 100%;
  margin-bottom: 20px; }
  .recover-password .card .login-card-body .input-group input {
    width: 100%;
    height: 50px;
    padding-left: 50px;
    border: none;
    border-radius: 5px;
    background: #f0f0f0; }
  .recover-password .card .login-card-body .input-group .input-group-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    color: red;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%); }
  .recover-password .card .login-card-body .input-group .fa {
    position: absolute;
    left: 0;
    bottom: -20px;
    color: red; }
  .recover-password .card .login-card-body .input-group .captcha .RadCaptcha {
    margin-bottom: 20px; }
    .recover-password .card .login-card-body .input-group .captcha .RadCaptcha span {
      color: red; }
    .recover-password .card .login-card-body .input-group .captcha .RadCaptcha div div {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .recover-password .card .login-card-body .input-group .captcha .RadCaptcha div div .rcRefreshImage {
        margin-left: 20px;
        font-size: 0; }
        .recover-password .card .login-card-body .input-group .captcha .RadCaptcha div div .rcRefreshImage::after {
          content: 'replay';
          font-family: Material Icons;
          font-size: 1.875rem;
          color: red; }

.recover-password .card .login-card-body .buttom {
  width: 100%; }
  .recover-password .card .login-card-body .buttom input {
    width: 100%;
    height: 50px;
    border: none;
    background: red;
    color: white;
    text-transform: uppercase; }

.checkout-login-section {
  padding: 50px 0px; }
  .checkout-login-section .checkout-guest .form-group {
    margin-top: 0 !important; }
  .checkout-login-section .cart-login {
    max-width: 495px;
    padding: 23px;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid #009035; }
    .checkout-login-section .cart-login .login-heading {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.125rem;
      text-transform: uppercase;
      padding-bottom: 15px;
      border-bottom: solid 3px #ebebeb;
      margin-bottom: 31px; }
    .checkout-login-section .cart-login .login-form .select-group {
      margin-bottom: 15px; }
      .checkout-login-section .cart-login .login-form .select-group ul {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        .checkout-login-section .cart-login .login-form .select-group ul li {
          display: flex;
          align-items: center; }
          .checkout-login-section .cart-login .login-form .select-group ul li span {
            display: flex;
            align-items: center; }
          .checkout-login-section .cart-login .login-form .select-group ul li input[type="radio"] {
            display: none; }
            .checkout-login-section .cart-login .login-form .select-group ul li input[type="radio"]:checked ~ label::before {
              content: "radio_button_checked"; }
          .checkout-login-section .cart-login .login-form .select-group ul li label {
            line-height: 1.875rem;
            display: flex;
            align-items: center; }
            .checkout-login-section .cart-login .login-form .select-group ul li label::before {
              padding-right: 8px;
              font-family: Material Icons;
              font-size: 18px;
              text-transform: none;
              content: "radio_button_unchecked"; }
    .checkout-login-section .cart-login .login-form .module-title {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 800;
      text-transform: uppercase;
      padding-bottom: 18px; }
    .checkout-login-section .cart-login .login-form .form-group {
      width: 100%; }
      .checkout-login-section .cart-login .login-form .form-group a {
        transition: .3s all  ease-in-out; }
        .checkout-login-section .cart-login .login-form .form-group a:hover {
          color: #026928; }
      .checkout-login-section .cart-login .login-form .form-group label {
        display: flex;
        align-items: center;
        padding-bottom: 10px; }
        .checkout-login-section .cart-login .login-form .form-group label span {
          color: red; }
      .checkout-login-section .cart-login .login-form .form-group input {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 5px;
        background: #f0f0f0; }
      .checkout-login-section .cart-login .login-form .form-group.form-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .checkout-login-section .cart-login .login-form .form-group.form-bottom span {
          font-size: 0.875rem;
          line-height: 1.375rem; }
          .checkout-login-section .cart-login .login-form .form-group.form-bottom span .signup {
            color: #068fdd; }
        .checkout-login-section .cart-login .login-form .form-group.form-bottom .forget {
          font-size: 0.875rem;
          line-height: 1.375rem; }
      .checkout-login-section .cart-login .login-form .form-group:nth-child(2) {
        margin-top: 30px;
        margin-bottom: 22px; }
    .checkout-login-section .cart-login .login-form .btn-continue {
      width: 100%;
      height: 40px;
      border-radius: 0px;
      border: 1px solid #009035 !important;
      background-color: #009035;
      color: #009035;
      font-size: 0.875rem;
      font-weight: 700;
      margin-top: 5px;
      text-transform: uppercase;
      transition: .3s all ease-in-out;
      border-radius: 5px; }
      .checkout-login-section .cart-login .login-form .btn-continue:hover {
        font-size: 1rem;
        transform: scale(1.02) translateZ(0);
        letter-spacing: 1px;
        color: #009035;
        background-color: #009035; }

.search-product-wrapper .searchsummary {
  font-size: 1.5rem;
  margin-bottom: 25px; }
  .search-product-wrapper .searchsummary .searchduration {
    display: none; }
  .search-product-wrapper .searchsummary strong:nth-child(2)::before {
    content: '"'; }
  .search-product-wrapper .searchsummary strong:nth-child(2)::after {
    content: '"'; }

.search-product-wrapper .product-item-main {
  margin-bottom: 50px; }

.search-product-wrapper [class*='col'] {
  margin-bottom: 1.875rem; }

.noresults img {
  margin: 0 auto;
  display: block;
  margin-bottom: 30px; }

.noresults strong {
  color: #846b5b;
  font-size: 1.4375rem;
  font-weight: 500;
  margin-top: 1.875rem;
  text-align: center;
  display: block; }

.noresults p {
  opacity: 0.5;
  color: #797070;
  font-size: 1.0625rem;
  font-weight: 300;
  text-align: center;
  margin-top: 0.625rem; }

.productsearchresults main {
  min-height: 90vh;
  background-color: #f4f4f2; }
  @media (max-width: 1279.98px) {
    .productsearchresults main {
      min-height: 75vh; } }
  @media (max-width: 767.98px) {
    .productsearchresults main {
      min-height: 60vh; } }

.suggestsearch {
  position: absolute;
  top: 126%;
  left: 0;
  width: 100%;
  height: 485px;
  z-index: 8;
  overflow-y: auto;
  background: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); }
  @media (max-width: 1023.98px) {
    .suggestsearch {
      top: 100%;
      height: 50vh; } }
  @media (max-width: 575.98px) {
    .suggestsearch {
      position: fixed;
      top: 61px;
      left: 0;
      height: 325px;
      width: 100vw; } }
  .suggestsearch .title-search-product, .suggestsearch .noresults {
    padding: 15px 30px;
    border-bottom: 1px solid #e5e5e5; }

.item-product-search {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 30px; }
  .item-product-search .item-img {
    flex: 0 0 60px; }
    .item-product-search .item-img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 100%; }
      .item-product-search .item-img a img, .item-product-search .item-img a iframe, .item-product-search .item-img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        transition: .3s ease-in-out all; }
  .item-product-search .item-content {
    padding-left: 1.25rem; }
    .item-product-search .item-content h3 {
      font-weight: bold;
      font-size: 1.125rem; }
    .item-product-search .item-content .wrap-price-cart {
      font-size: 14px;
      margin-top: 0.3125rem; }
      .item-product-search .item-content .wrap-price-cart .sale {
        color: #009035;
        margin-right: 15px; }

.wrap-thong-bao-dat-hang {
  padding: 60px 0px; }
  .wrap-thong-bao-dat-hang .thong-bao-dat-hang {
    padding: 0 30px 24px;
    min-height: 480px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15); }
    @media (max-width: 767.98px) {
      .wrap-thong-bao-dat-hang .thong-bao-dat-hang {
        padding-left: 10px;
        padding-right: 10px; } }
    .wrap-thong-bao-dat-hang .thong-bao-dat-hang .title-thong-bao {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 14px;
      padding-bottom: 6px;
      border-bottom: 3px solid #009035; }
      .wrap-thong-bao-dat-hang .thong-bao-dat-hang .title-thong-bao span {
        color: #009035;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 10px;
        display: inline-block;
        padding-top: 6px; }
        @media (max-width: 575.98px) {
          .wrap-thong-bao-dat-hang .thong-bao-dat-hang .title-thong-bao span {
            font-size: 1.3rem; } }
    .wrap-thong-bao-dat-hang .thong-bao-dat-hang .content-thong-bao {
      padding-top: 50px;
      max-width: 690px;
      margin: 0 auto;
      width: 100%; }
      @media (max-width: 1023.98px) {
        .wrap-thong-bao-dat-hang .thong-bao-dat-hang .content-thong-bao {
          padding-top: 20px; } }
      .wrap-thong-bao-dat-hang .thong-bao-dat-hang .content-thong-bao * {
        color: #009035;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.8px; }
      .wrap-thong-bao-dat-hang .thong-bao-dat-hang .content-thong-bao .masodon {
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0.9px;
        margin-left: 5px; }
    .wrap-thong-bao-dat-hang .thong-bao-dat-hang .btn-success-checkout {
      max-width: 435px;
      width: 100%;
      height: 40px;
      border: 1px solid #009035;
      background-color: #009035;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 28px;
      color: #009035;
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      transition: .3s all ease-in-out; }
      .wrap-thong-bao-dat-hang .thong-bao-dat-hang .btn-success-checkout:hover {
        letter-spacing: 2px; }

.user-sidelink {
  padding: 1.875rem;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media screen and (max-width: 1023.67px) {
    .user-sidelink {
      position: fixed;
      width: 100%;
      height: 100%;
      padding-top: 45px;
      left: 0;
      bottom: -100%;
      opacity: 0;
      visibility: hidden;
      z-index: 1000;
      transition: .3s all ease-in-out; }
      .user-sidelink.active {
        opacity: 1;
        visibility: visible;
        bottom: 0; } }
  .user-sidelink ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .user-sidelink ul li.active a {
      color: #009035;
      text-decoration: underline; }
    .user-sidelink ul li a {
      transition: .3s all ease-in-out; }
      .user-sidelink ul li a:hover {
        color: #009035; }
    .user-sidelink ul li + li {
      margin-top: 15px;
      border-top: 1px solid #eee;
      padding-top: 15px; }

.user-sidebar {
  background-color: #fff;
  padding: 1.875rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03); }
  @media screen and (max-width: 1023.67px) {
    .user-sidebar {
      margin-bottom: 20px; } }
  .user-sidebar .userinfo figure {
    display: flex;
    align-items: center; }
    .user-sidebar .userinfo figure img {
      border-radius: 50%;
      margin-right: 20px; }
  .user-sidebar .user-name {
    font-size: 1.125rem;
    font-weight: bold;
    color: #009035; }

.profile-container {
  padding-top: 40px;
  padding-bottom: 40px; }
  .profile-container .table-wrapper {
    overflow: auto; }
    @media screen and (max-width: 992px) {
      .profile-container .table-wrapper table {
        width: 900px; } }
  .profile-container .posttitle {
    font-size: 1.375rem; }
    @media (max-width: 575.98px) {
      .profile-container .posttitle {
        font-size: 1.25rem; } }
  .profile-container .addr-detail {
    line-height: 1.5; }
    @media screen and (max-width: 575px) {
      .profile-container .addr-detail {
        margin-top: 15px; } }
  .profile-container .postname {
    margin-bottom: 20px;
    font-size: 1.875rem !important;
    color: #000; }
  .profile-container .account-info {
    background-color: #fff;
    padding: 1.875rem;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 20px; }
    .profile-container .account-info .info-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px; }
      .profile-container .account-info .info-heading .info-detail {
        line-height: 1.3; }
      .profile-container .account-info .info-heading .posttitle {
        color: #000; }
      .profile-container .account-info .info-heading a {
        color: #000;
        border-bottom: 1px solid #000;
        transition: .3s all ease-in-out; }
        .profile-container .account-info .info-heading a:hover {
          color: #009035;
          border-bottom: 1px solid #009035; }
  .profile-container .coin-info {
    background-color: #fff;
    padding: 1.875rem;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 20px; }
  .profile-container .cart-history {
    background-color: #fff;
    padding: 1.875rem;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 20px; }
    .profile-container .cart-history .text-right a {
      color: #000;
      border-bottom: 1px solid #000;
      transition: .3s all ease-in-out; }
      .profile-container .cart-history .text-right a:hover {
        color: #009035;
        border-bottom: 1px solid #009035; }
  .profile-container .address-info {
    background-color: #fff;
    padding: 1.875rem;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 20px; }
    .profile-container .address-info .name {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      align-items: center; }
      .profile-container .address-info .name::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: #009035; }
    .profile-container .address-info #ctl00_mainContent_btnUpdate {
      background-color: #009035;
      color: white;
      padding: 10px 15px;
      border: 0;
      border-radius: 10px;
      outline: none; }
    .profile-container .address-info .check-group {
      display: flex;
      margin-bottom: 10px; }
      .profile-container .address-info .check-group input {
        margin-right: 10px;
        margin-top: 3px; }
    .profile-container .address-info .address-form .form-group {
      margin-bottom: 20px; }
      .profile-container .address-info .address-form .form-group label {
        font-size: 1.125rem;
        margin-bottom: 5px;
        display: block;
        color: #009035; }
      .profile-container .address-info .address-form .form-group input[type="text"] {
        font-size: 0.875rem;
        background-color: white;
        border: 0 !important;
        border-bottom: 1px solid #d7d7d7 !important;
        padding: 12px 0px !important;
        width: 100% !important;
        height: auto !important; }
      .profile-container .address-info .address-form .form-group select {
        font-size: 0.875rem;
        background-color: white;
        border: 0 !important;
        border-bottom: 1px solid #d7d7d7 !important;
        padding: 12px 0px !important;
        width: 100% !important;
        height: auto !important; }
    .profile-container .address-info .info-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px; }
      .profile-container .address-info .info-heading a {
        color: #000;
        transition: .3s all ease-in-out;
        border-bottom: 1px solid #000; }
        .profile-container .address-info .info-heading a:hover {
          color: #009035;
          border-bottom: 1px solid #009035; }
    .profile-container .address-info .address-col .address {
      position: relative; }
    @media screen and (max-width: 575px) {
      .profile-container .address-info .address-col .name {
        font-weight: bold;
        font-size: 1.125rem; } }
    .profile-container .address-info .address-col + .address-col {
      padding-top: 1.25rem;
      margin-top: 1.25rem;
      border-top: 1px dashed #eee; }
    .profile-container .address-info .type {
      color: white;
      background-color: #009035;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      position: absolute;
      top: 0px;
      right: 5px;
      width: fit-content;
      font-size: 12px; }
    .profile-container .address-info .addr-detail ul {
      list-style: none;
      margin: 0;
      padding: 0; }
    .profile-container .address-info .btn-editaddr {
      color: #009035;
      margin-top: 10px;
      display: inline-block;
      transition: .3s all ease-in-out;
      padding: 4px 10px;
      border-radius: 5px;
      background-color: #009035;
      font-size: 14px; }
      .profile-container .address-info .btn-editaddr:hover {
        color: #009035;
        background-color: #009035; }
    .profile-container .address-info .btn-deleteaddr {
      color: white;
      margin-top: 10px;
      display: inline-block;
      transition: .3s all ease-in-out;
      padding: 4px 10px;
      border-radius: 5px;
      background-color: #d03535;
      font-size: 14px;
      margin-left: 5px; }
  .profile-container #ctl00_mainContent_pnlProfileProperties [class*="col"] {
    padding-left: 0;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0; }
  .profile-container .account-form .form-group {
    margin-bottom: 20px; }
  .profile-container .account-form label {
    font-size: 1.125rem;
    margin-bottom: 5px;
    display: block;
    color: #000; }
  .profile-container .account-form input[type="text"], .profile-container .account-form input[type="password"] {
    font-size: 0.875rem;
    background-color: white;
    border: 0 !important;
    border-bottom: 1px solid #d7d7d7 !important;
    padding: 12px 0px !important;
    width: 100% !important;
    height: auto !important; }
  .profile-container .account-form input[type="submit"] {
    font-size: 1.125rem;
    color: white;
    padding: 8px 14px;
    border-radius: 5px;
    background-color: #009035;
    margin-top: 10px;
    border: 0; }
  .profile-container .account-form select {
    font-size: 0.875rem;
    background-color: white;
    border: 0 !important;
    border-bottom: 1px solid #d7d7d7 !important;
    padding: 12px 0px !important;
    width: 100% !important;
    height: auto !important; }
  .profile-container .user-page .info-heading {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .profile-container .user-page .info-heading .btn-addaddr {
      color: #026928;
      text-decoration: underline; }

.user-page .order-detail {
  margin-bottom: 60px; }
  .user-page .order-detail .carttable {
    width: 100%; }
  .user-page .order-detail .admin-title {
    width: fit-content;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    background-color: #fff;
    padding: 15px 20px; }
    .user-page .order-detail .admin-title .status {
      margin-bottom: 5px;
      display: flex;
      align-items: center; }
      .user-page .order-detail .admin-title .status em {
        color: #000;
        font-size: 1.1875rem;
        margin: 0 5px; }
      .user-page .order-detail .admin-title .status span {
        color: #000; }
    .user-page .order-detail .admin-title .text {
      margin-bottom: 5px; }
      .user-page .order-detail .admin-title .text strong {
        font-size: 1.375rem;
        color: #009035; }
  .user-page .order-detail .text-st {
    color: #000 !important;
    font-weight: 600 !important; }
  .user-page .order-detail .second-row-status {
    margin-top: 20px; }
    .user-page .order-detail .second-row-status .box-address-item {
      margin-bottom: 30px; }
      .user-page .order-detail .second-row-status .box-address-item ul li {
        margin-top: 5px; }
        .user-page .order-detail .second-row-status .box-address-item ul li.name {
          margin-bottom: 10px; }
      .user-page .order-detail .second-row-status .box-address-item .title {
        font-size: 1.125rem;
        margin-bottom: 0.9375rem;
        font-weight: 700;
        color: #009035; }
    .user-page .order-detail .second-row-status .col-match-height {
      height: 100%; }
    @media screen and (max-width: 767.67px) {
      .user-page .order-detail .second-row-status .col {
        flex: 0 0 100%; }
        .user-page .order-detail .second-row-status .col + .col {
          margin-top: 20px; } }
    .user-page .order-detail .second-row-status .item {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      background-color: #fff;
      padding: 15px 20px;
      height: 100%; }
      .user-page .order-detail .second-row-status .item .text-st {
        margin-top: 5px;
        margin-bottom: 5px;
        display: block; }
      .user-page .order-detail .second-row-status .item ul {
        padding-left: 0;
        list-style: none; }
        .user-page .order-detail .second-row-status .item ul li {
          line-height: 1.3; }
          .user-page .order-detail .second-row-status .item ul li .status {
            color: #000;
            font-weight: bold; }
          .user-page .order-detail .second-row-status .item ul li span {
            margin-right: 5px;
            color: #333;
            font-weight: normal;
            font-size: 0.875rem; }
  .user-page .order-detail .carttable {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    background-color: #fff;
    padding: 10px; }
    .user-page .order-detail .carttable tr + tr td {
      border-top: 1px dashed #d7d7d7; }
    .user-page .order-detail .carttable tr th {
      padding: 10px 15px;
      white-space: nowrap;
      text-align: center; }
      .user-page .order-detail .carttable tr th:first-child {
        text-align: left; }
    .user-page .order-detail .carttable tr td {
      padding: 15px 0px; }
      .user-page .order-detail .carttable tr td:nth-child(2), .user-page .order-detail .carttable tr td:nth-child(3), .user-page .order-detail .carttable tr td:nth-child(4), .user-page .order-detail .carttable tr td:nth-child(5) {
        text-align: center;
        white-space: nowrap; }
      .user-page .order-detail .carttable tr td .product-item {
        padding-left: 15px; }
        .user-page .order-detail .carttable tr td .product-item .image a {
          display: block;
          width: auto;
          min-width: 100px;
          max-width: 100px;
          height: 90px; }
          .user-page .order-detail .carttable tr td .product-item .image a img {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain; }
        .user-page .order-detail .carttable tr td .product-item .title {
          text-align: left !important;
          padding-right: 50px; }
          .user-page .order-detail .carttable tr td .product-item .title a {
            color: #009035;
            transition: .3s all ease-in-out; }
            .user-page .order-detail .carttable tr td .product-item .title a:hover {
              color: #009035;
              text-decoration: underline; }
  .user-page .order-detail .total-table {
    margin-left: auto;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 30px; }
    .user-page .order-detail .total-table tr td {
      padding: 5px 0px; }
      .user-page .order-detail .total-table tr td:first-child {
        text-align: left;
        font-weight: bold;
        color: #009035; }
      .user-page .order-detail .total-table tr td:last-child {
        color: #333;
        font-size: 1.125rem; }
    .user-page .order-detail .total-table tr:last-child td:last-child {
      color: #ed1c24;
      font-size: 1.875rem;
      font-weight: 700; }
  .user-page .order-detail .btn-back {
    color: white;
    background-color: #009035;
    padding: 11px 18px;
    border-radius: 10px;
    transition: .3s all ease-in-out; }
    .user-page .order-detail .btn-back:hover {
      background-color: #026928; }
  .user-page .order-detail .cart-display {
    padding: 0px 0px;
    margin-bottom: 10px; }
    @media screen and (max-width: 960px) {
      .user-page .order-detail .cart-display .product-item {
        flex-direction: row !important; }
        .user-page .order-detail .cart-display .product-item .image {
          margin-right: 20px; } }
    @media screen and (max-width: 768px) {
      .user-page .order-detail .cart-display .wrapper {
        overflow: auto; }
        .user-page .order-detail .cart-display .wrapper::-webkit-scrollbar {
          height: 3px; }
        .user-page .order-detail .cart-display .wrapper::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .user-page .order-detail .cart-display .wrapper::-webkit-scrollbar-thumb {
          background: #009035; }
      .user-page .order-detail .cart-display .carttable {
        width: 800px; }
        .user-page .order-detail .cart-display .carttable td {
          padding: 0 15px; } }

.table-admin-stripe {
  width: 100%;
  margin-top: 20px; }
  .table-admin-stripe tr td {
    padding: 1.25rem 0.625rem;
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    font-size: 1rem; }
    .table-admin-stripe tr td:nth-child(3), .table-admin-stripe tr td:nth-child(4) {
      white-space: nowrap; }
    .table-admin-stripe tr td:first-child a {
      font-weight: bold;
      color: #009035;
      transition: .3s all ease-in-out; }
      .table-admin-stripe tr td:first-child a:hover {
        color: #009035; }
    .table-admin-stripe tr td .status {
      color: white;
      border-radius: 10px;
      padding: 2px 10px;
      display: inline-block;
      background-color: #009035; }
    .table-admin-stripe tr td .text label {
      display: inline; }

.button-account-profile {
  display: none; }
  @media screen and (max-width: 1023.67px) {
    .button-account-profile {
      z-index: 1001;
      position: fixed;
      bottom: 66px;
      left: 0;
      border-radius: 15px 15px 0 0;
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      background-color: #000; }
      .button-account-profile.active em {
        transform: rotate(135deg); }
      .button-account-profile .title-main {
        color: #009035;
        font-size: 1rem;
        font-weight: 700;
        text-align: center; }
      .button-account-profile em {
        color: #009035;
        font-size: 24px;
        transition: .3s all ease-in-out; } }

.purchase-history-page .pagination {
  margin-top: 20px; }

.purchase-history-page .postname {
  margin-bottom: 20px;
  font-size: 2.25rem; }

.purchase-history-page .middle-fullwidth {
  padding-top: 40px;
  padding-bottom: 40px; }

.purchase-history-page .table-wrapper {
  overflow: auto; }

.purchase-history-page .cart-history {
  margin-bottom: 20px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 1.875rem 1.875rem; }
  .purchase-history-page .cart-history .account-order {
    overflow: auto; }
  .purchase-history-page .cart-history table {
    width: 100%; }
    @media screen and (max-width: 575px) {
      .purchase-history-page .cart-history table {
        width: 750px; } }
    .purchase-history-page .cart-history table thead th {
      font-weight: 700;
      padding: .75rem .75rem;
      border-bottom: 1px solid #dbdbdb; }
    .purchase-history-page .cart-history table tbody tr td {
      border: 1px solid #dbdbdb;
      border-width: 0 0 1px;
      padding: .75rem .75rem; }

.purchase-history-page main {
  background-color: #f8f8f8; }

.wishlist-page .cart-product-item {
  display: flex;
  padding: 25px 0px;
  border-bottom: 1px dashed #d7d7d7; }
  @media screen and (max-width: 575px) {
    .wishlist-page .cart-product-item {
      flex-wrap: wrap; } }
  .wishlist-page .cart-product-item .cart-product-item-img {
    flex: 0 0 120px;
    max-width: 120px; }
    .wishlist-page .cart-product-item .cart-product-item-img a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 120px; }
      .wishlist-page .cart-product-item .cart-product-item-img a img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
    @media screen and (max-width: 575px) {
      .wishlist-page .cart-product-item .cart-product-item-img {
        flex: 0 0 100%;
        max-width: 100%; } }
  .wishlist-page .cart-product-item .cart-product-item-content {
    flex: 1;
    max-width: 100%; }
    @media screen and (max-width: 575px) {
      .wishlist-page .cart-product-item .cart-product-item-content {
        flex: 0 0 100%;
        max-width: 100%; } }
    .wishlist-page .cart-product-item .cart-product-item-content .cart-product-item-name {
      font-size: 1.125rem;
      transition: .3s all ease-in-out;
      padding-top: 10px; }
      @media screen and (max-width: 992px) {
        .wishlist-page .cart-product-item .cart-product-item-content .cart-product-item-name {
          font-size: 1.25rem; } }
      .wishlist-page .cart-product-item .cart-product-item-content .cart-product-item-name:hover {
        color: #009035; }

.wishlist-page .cart-product-item-detail {
  display: flex;
  padding-left: 15px; }
  @media screen and (max-width: 992px) {
    .wishlist-page .cart-product-item-detail {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 15px; } }
  .wishlist-page .cart-product-item-detail .cart-product-item-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: baseline; }
    @media screen and (max-width: 992px) {
      .wishlist-page .cart-product-item-detail .cart-product-item-price {
        margin-top: 10px;
        justify-content: flex-start; } }
    .wishlist-page .cart-product-item-detail .cart-product-item-price p {
      font-size: 1.125rem; }
      .wishlist-page .cart-product-item-detail .cart-product-item-price p .ri-delete-bin-line {
        font-size: 18px; }
      .wishlist-page .cart-product-item-detail .cart-product-item-price p:nth-child(1) {
        font-size: 1.2rem;
        color: #009035;
        margin-right: 5px; }
      .wishlist-page .cart-product-item-detail .cart-product-item-price p:nth-child(2) {
        color: #ed1c24; }
        .wishlist-page .cart-product-item-detail .cart-product-item-price p:nth-child(2)::before {
          content: '|';
          padding-left: 5px;
          padding-right: 5px; }
        .wishlist-page .cart-product-item-detail .cart-product-item-price p:nth-child(2) span {
          display: none; }
      .wishlist-page .cart-product-item-detail .cart-product-item-price p:last-child {
        flex: 0 0 100%; }
        @media screen and (max-width: 992px) {
          .wishlist-page .cart-product-item-detail .cart-product-item-price p:last-child {
            display: flex;
            justify-content: flex-start;
            margin-top: 10px; }
            .wishlist-page .cart-product-item-detail .cart-product-item-price p:last-child a span {
              font-size: 18px; } }

.wishlist-page .postname {
  margin-bottom: 20px;
  font-size: 2.25rem; }

.wishlist-page main {
  background-color: #f8f8f8; }

.wishlist-page .middle-fullwidth {
  padding-top: 40px;
  padding-bottom: 40px; }

.wishlist-page .cart-history {
  margin-bottom: 20px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 1.875rem 1.875rem; }

.wishlist-page .card-title {
  display: flex !important; }
  .wishlist-page .card-title a {
    padding-top: 1px;
    margin-right: 5px;
    display: none; }
  .wishlist-page .card-title .posttitle {
    color: #000;
    font-size: 1.125rem;
    font-weight: 400; }

.user-page .address-info {
  margin-bottom: 20px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #fff; }

.user-page .check-group {
  display: flex;
  align-items: center; }
  .user-page .check-group label {
    margin-left: 15px;
    margin-bottom: 0px !important; }

.user-page .address-form .form-group {
  margin-bottom: 20px; }
  .user-page .address-form .form-group label {
    font-size: 1.125rem;
    display: block;
    color: #333; }
  .user-page .address-form .form-group input[type="text"] {
    font-size: 0.875rem;
    background-color: white;
    border: 0 !important;
    border-bottom: 1px solid #d7d7d7 !important;
    padding: 12px 0px !important;
    width: 100% !important;
    height: auto !important; }
  .user-page .address-form .form-group select {
    font-size: 0.875rem;
    background-color: white;
    border: 0 !important;
    border-bottom: 1px solid #d7d7d7 !important;
    padding: 12px 0px !important;
    width: 100% !important;
    height: auto !important; }

.user-page input[type="submit"] {
  font-size: 1.125rem;
  color: white;
  padding: 8px 14px;
  border-radius: 5px;
  background-color: #009035;
  margin-top: 10px;
  border: 0; }

.user-sidemenu {
  padding-top: 40px; }

.page-video-banner {
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 30px 0px 8px; }
  @media (max-width: 1023.98px) {
    .page-video-banner {
      padding: 1.25rem 0px; } }
  .page-video-banner .box-video-left {
    position: relative; }
    .page-video-banner .box-video-left::before {
      content: '';
      width: 1px;
      height: 110px;
      background-color: #e5e5e5;
      position: absolute;
      top: -10px;
      right: 35px; }
      @media (max-width: 1023.98px) {
        .page-video-banner .box-video-left::before {
          display: none; } }
    .page-video-banner .box-video-left .icon em {
      color: #009035;
      font-size: 1rem;
      font-weight: 300; }
    .page-video-banner .box-video-left .text {
      padding-left: 14px; }
      @media (max-width: 1023.98px) {
        .page-video-banner .box-video-left .text {
          margin-bottom: 1.25rem; } }
      .page-video-banner .box-video-left .text span {
        color: #666666;
        font-size: 1.125rem; }
      .page-video-banner .box-video-left .text p {
        color: #111111;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.2; }
  @media (min-width: 1024px) {
    .page-video-banner .box-video-right {
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }
  @media (min-width: 1280px) {
    .page-video-banner .box-video-right {
      padding-left: 1.875rem;
      padding-right: 5rem; } }
  @media (min-width: 1440px) {
    .page-video-banner .box-video-right {
      padding-right: 8.75rem;
      padding-left: 2.5rem; } }
  .page-video-banner .box-video-right .video:hover .img::before {
    background-color: rgba(0, 0, 0, 0.2); }
  .page-video-banner .box-video-right .video .img {
    margin-bottom: 20px;
    width: 90px;
    height: 60px;
    margin: 0 auto; }
    @media (max-width: 575.98px) {
      .page-video-banner .box-video-right .video .img {
        width: 110px;
        height: 80px; }
        .page-video-banner .box-video-right .video .img img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
    .page-video-banner .box-video-right .video .img::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: .3s all ease-in-out;
      z-index: 2; }
    .page-video-banner .box-video-right .video .img .play-video {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%, -50%); }
      .page-video-banner .box-video-right .video .img .play-video em {
        font-size: 1.5rem;
        color: white; }
  .page-video-banner .box-video-right .video h3 {
    font-size: 0.8125rem;
    text-align: center;
    transition: .3s all ease-in-out;
    margin-top: 10px; }
    .page-video-banner .box-video-right .video h3:hover {
      color: #009035; }
  @media (max-width: 1023.98px) {
    .page-video-banner .box-video-right .wrap-button-slide {
      display: none; } }
  .page-video-banner .box-video-right .wrap-button-slide .btn-prev, .page-video-banner .box-video-right .wrap-button-slide .btn-next {
    top: 40%; }
  .page-video-banner .box-video-right .wrap-button-slide .btn-prev {
    margin-right: 22px; }
  .page-video-banner .box-video-right .wrap-button-slide .btn-next {
    margin-left: 22px; }
  .page-video-banner .box-video-right .pagination-mb {
    margin-top: 1.25rem; }
    @media (min-width: 1024px) {
      .page-video-banner .box-video-right .pagination-mb {
        display: none; } }

.home-hot-product {
  padding: 3.4375rem 0px; }
  @media (max-width: 575.98px) {
    .home-hot-product {
      padding: 1.875rem 0px; } }
  @media (max-width: 575.98px) {
    .home-hot-product .wrap-slide-product {
      padding: 0 15px; } }

.home-all-product {
  background-color: #edf6f1; }
  .home-all-product h2 {
    margin-bottom: 20px; }
  .home-all-product .btn-sw-1 {
    background-color: #fff; }
    .home-all-product .btn-sw-1.btn-prev {
      margin-right: 15px; }
    .home-all-product .btn-sw-1.btn-next {
      margin-left: 15px; }
  .home-all-product .swiper-slide {
    height: auto; }

@media (min-width: 1024px) {
  .home-why-chosse .container {
    padding: 0 1.875rem; } }

@media (min-width: 1280px) {
  .home-why-chosse .container {
    padding: 0 5.625rem; } }

.home-why-chosse .text-center {
  max-width: 739px;
  margin: 0 auto; }
  .home-why-chosse .text-center .title {
    font-size: 2.25rem; }
    @media (max-width: 575.98px) {
      .home-why-chosse .text-center .title {
        font-size: 1.7rem; } }
  .home-why-chosse .text-center .desc {
    margin-top: 1.5625rem; }

.home-why-chosse .row {
  margin-top: 1.875rem; }
  @media (min-width: 768px) {
    .home-why-chosse .row {
      margin-top: 5.625rem; } }
  .home-why-chosse .row .icon {
    margin-bottom: 1.875rem; }

@media (min-width: 1024px) {
  .home-why-chosse .wrap {
    padding: 0 1.875rem; } }

@media (min-width: 1280px) {
  .home-why-chosse .wrap {
    padding: 0 3.75rem; } }

.home-why-chosse .wrap p {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500; }
  @media (max-width: 575.98px) {
    .home-why-chosse .wrap p {
      font-size: 1rem; } }

.home-why-chosse .row .col-lg-4 {
  position: relative; }
  @media (max-width: 1279.98px) {
    .home-why-chosse .row .col-lg-4 {
      margin-bottom: 1.25rem; } }
  @media (min-width: 1280px) {
    .home-why-chosse .row .col-lg-4:nth-child(2)::before, .home-why-chosse .row .col-lg-4:nth-child(2)::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 80px;
      background-color: #ffffff;
      opacity: 0.28; }
    .home-why-chosse .row .col-lg-4:nth-child(2)::before {
      left: 10px;
      top: -10px; }
    .home-why-chosse .row .col-lg-4:nth-child(2)::after {
      right: 10px;
      top: -10px; } }

.home-subscribe {
  background-color: #e3ecf0;
  min-height: 250px; }
  .home-subscribe .img-product {
    transform: translateY(-50px) translateX(-22px); }
  .home-subscribe .des {
    color: #666666; }
    @media (max-width: 1279.98px) {
      .home-subscribe .des {
        min-height: 45px; } }
  .home-subscribe .list-social {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 26px; }
    @media (max-width: 1279.98px) {
      .home-subscribe .list-social {
        margin-top: 23px; } }
    @media (max-width: 767.98px) {
      .home-subscribe .list-social {
        margin-top: 0; } }
    .home-subscribe .list-social li a {
      width: 50px;
      height: 50px;
      box-shadow: 9px 12px 30px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      transition: .3s all ease-in-out; }
      .home-subscribe .list-social li a img {
        transition: .3s all ease-in-out; }
      .home-subscribe .list-social li a:hover {
        background-color: #009035; }
        .home-subscribe .list-social li a:hover img {
          filter: brightness(0) invert(1); }
  .home-subscribe .wrap-subscribe {
    padding-bottom: 40px;
    width: 100%; }
    @media (max-width: 767.98px) {
      .home-subscribe .wrap-subscribe {
        padding-bottom: 20px; } }
  @media (min-width: 1024px) {
    .home-subscribe .box-padding {
      padding: 2.5rem 0px; } }
  .home-subscribe .subscribe {
    display: flex;
    align-items: center; }
    .home-subscribe .subscribe [class*='Module'] {
      width: 100%; }
    .home-subscribe .subscribe .subscribefrm {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 1.5625rem; }
      @media (max-width: 767.98px) {
        .home-subscribe .subscribe .subscribefrm {
          margin-top: 0rem; } }
    .home-subscribe .subscribe input {
      flex: 1;
      height: 50px;
      box-shadow: 9px 12px 30px rgba(0, 0, 0, 0.05);
      border-radius: 8px 0 0 8px;
      background-color: #ffffff;
      color: #666666;
      border: none;
      outline: none;
      font-size: 0.875rem;
      padding-left: 25px; }
    .home-subscribe .subscribe button {
      flex: 0 0 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #009035;
      border: none;
      border-radius: 0px 8px 8px 0px;
      outline: none;
      transition: .3s all ease-in-out;
      font-size: 0; }
      .home-subscribe .subscribe button::before {
        content: '';
        background-image: url("../img/icon/right2.png");
        filter: brightness(0) invert(1);
        width: 19px;
        height: 13px;
        display: inline-block; }
      .home-subscribe .subscribe button img {
        filter: brightness(0) invert(1); }
      .home-subscribe .subscribe button:hover {
        background-color: #026928; }
  .home-subscribe .wrap-social {
    padding-left: 62px;
    padding-bottom: 38px; }
    @media (max-width: 1279.98px) {
      .home-subscribe .wrap-social {
        padding-left: 30px; } }
    @media (max-width: 767.98px) {
      .home-subscribe .wrap-social {
        padding-left: 0; } }

.page-news-detail {
  padding-bottom: 6.25rem; }
  .page-news-detail .wrap-title-header .wrap-date-share {
    margin-top: 0.3125rem;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1.4375rem; }
    .page-news-detail .wrap-title-header .wrap-date-share .date {
      color: #7f8084;
      font-weight: 500; }
  .page-news-detail .content-main {
    padding: 1.875rem 0px; }
    .page-news-detail .content-main p {
      margin-bottom: 1.25rem;
      line-height: 1.5; }
    .page-news-detail .content-main h2, .page-news-detail .content-main h3, .page-news-detail .content-main h4, .page-news-detail .content-main h5 {
      margin-bottom: 1.25rem; }
    .page-news-detail .content-main h2 {
      font-size: 1.125rem;
      font-weight: 700; }
  .page-news-detail .box-other-news {
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #f5f5f5;
    padding: 28px 39px; }
    @media (max-width: 1279.98px) {
      .page-news-detail .box-other-news {
        padding: 25px 20px; } }
    @media (max-width: 1023.98px) {
      .page-news-detail .box-other-news {
        padding: 10px 15px; } }
    .page-news-detail .box-other-news .title-36 {
      margin-bottom: 8px; }
    .page-news-detail .box-other-news ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .page-news-detail .box-other-news ul li.item-news-other {
        padding: 1.25rem 0px;
        display: flex;
        border-top: 1px solid #e5e5e5; }
        .page-news-detail .box-other-news ul li.item-news-other .img {
          flex: 0 0 127px; }
          @media (max-width: 1279.98px) {
            .page-news-detail .box-other-news ul li.item-news-other .img {
              flex: 0 0 100px; } }
          .page-news-detail .box-other-news ul li.item-news-other .img a {
            position: relative;
            display: block;
            height: 0;
            overflow: hidden;
            padding-top: 62.99213%;
            border-radius: 8px; }
            .page-news-detail .box-other-news ul li.item-news-other .img a img, .page-news-detail .box-other-news ul li.item-news-other .img a iframe, .page-news-detail .box-other-news ul li.item-news-other .img a video {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              object-fit: cover;
              transition: .3s ease-in-out all; }
        .page-news-detail .box-other-news ul li.item-news-other .content {
          padding-left: 1.5625rem; }
          .page-news-detail .box-other-news ul li.item-news-other .content .date {
            color: #7f8084;
            font-size: 0.875rem; }
          .page-news-detail .box-other-news ul li.item-news-other .content .title {
            color: #333333;
            font-size: 0.875rem;
            margin-top: 0.625rem;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            transition: .3s all  ease-in-out; }
            @media (max-width: 1279.98px) {
              .page-news-detail .box-other-news ul li.item-news-other .content .title {
                margin-top: 0.3125rem; } }
            .page-news-detail .box-other-news ul li.item-news-other .content .title:hover {
              color: #009035;
              text-decoration: underline; }

.page-news-list {
  padding-bottom: 3.75rem; }
  .page-news-list .wrap-news-post {
    margin-bottom: 1.875rem; }
    .page-news-list .wrap-news-post .title-nav {
      margin-bottom: 1.875rem; }
      .page-news-list .wrap-news-post .title-nav h2 {
        font-weight: 400; }
    .page-news-list .wrap-news-post .item-news-main {
      margin-bottom: 0.625rem; }
      .page-news-list .wrap-news-post .item-news-main .content {
        padding: 13px 47px 36px;
        min-height: 120px; }
        @media (max-width: 1279.98px) {
          .page-news-list .wrap-news-post .item-news-main .content {
            padding: 10px 30px 17px; } }
        .page-news-list .wrap-news-post .item-news-main .content h3 a {
          font-size: 0.875rem;
          font-weight: 400; }
          @media (max-width: 1279.98px) {
            .page-news-list .wrap-news-post .item-news-main .content h3 a {
              font-size: 1.1rem; } }

.item-gallery-main {
  margin-bottom: 1.875rem; }
  .item-gallery-main.image .img::after {
    width: 64px;
    height: 64px;
    background-image: url("../img/icon/image.png"); }
  .item-gallery-main .img {
    position: relative; }
    .item-gallery-main .img::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      z-index: 2;
      height: 100%;
      background-color: rgba(51, 51, 51, 0.5); }
    .item-gallery-main .img::after {
      content: '';
      z-index: 3;
      background-image: url("../img/icon/play_1.png");
      position: absolute;
      width: 56px;
      height: 64px;
      pointer-events: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .item-gallery-main .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 66.66667%; }
      .item-gallery-main .img a img, .item-gallery-main .img a iframe, .item-gallery-main .img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .item-gallery-main .title a {
    color: #333333;
    font-size: 1.125rem;
    display: block;
    font-weight: 500;
    text-align: center;
    transition: .3s all ease-in-out;
    padding: 1.25rem 1.25rem 0px; }
    .item-gallery-main .title a:hover {
      color: #009035; }

@media (max-width: 768.67px) {
  .number-tab-wrap {
    display: flex; }
    .number-tab-wrap .tabslet-tab {
      flex: 1; }
    .number-tab-wrap .icon-next {
      flex: 0 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      border: 1px solid #009035; }
      .number-tab-wrap .icon-next .next {
        margin-top: 5px; }
      .number-tab-wrap .icon-next em {
        color: #009035; } }

footer {
  padding-top: 7.5rem; }
  footer > .footer-wrap {
    background-color: #f5f5f5; }
  footer .wrap-footer {
    padding: 3.125rem 0px; }
    @media (max-width: 1023.98px) {
      footer .wrap-footer {
        padding: 1.25rem 0px; } }
    @media (max-width: 1023.98px) {
      footer .wrap-footer [class*='col'] {
        margin-bottom: 1.875rem; } }
  footer .title-footer {
    margin-bottom: 1.1875rem; }
  footer .wrap-footer-1 {
    max-width: 390px; }
  footer .list-footer li + li {
    margin-top: 5px; }
  footer .list-footer li a {
    color: #666666;
    letter-spacing: 0.8px;
    transition: .3s all ease-in-out; }
    footer .list-footer li a:hover {
      color: #009035;
      text-decoration: underline; }
  footer .desc p {
    color: #666666;
    font-size: 0.875rem;
    letter-spacing: 0.7px; }
  footer .desc img {
    margin-top: 1.5625rem; }
  footer .copyright {
    height: 60px;
    background-color: #0f8dc9; }
    @media (max-width: 575.98px) {
      footer .copyright .container {
        flex-direction: column;
        justify-content: center; } }
    footer .copyright span {
      color: #e5e5e5;
      font-size: 0.75rem;
      letter-spacing: 0.6px; }
    footer .copyright ul li + li::before {
      content: '.';
      color: white;
      padding: 0 10px; }
      @media (max-width: 575.98px) {
        footer .copyright ul li + li::before {
          padding: 0 3px; } }
    footer .copyright ul li a {
      color: #e5e5e5;
      font-size: 0.75rem;
      letter-spacing: 0.6px; }
      footer .copyright ul li a:hover {
        text-decoration: underline; }

header {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5; }
  @media (max-width: 1023.98px) {
    header .container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  header nav {
    height: 120px; }
    @media (max-width: 1023.98px) {
      header nav {
        height: 70px; } }
    @media (max-width: 575.98px) {
      header nav {
        height: 60px; } }
    header nav .header-logo {
      flex: 0 0 14.751773049%;
      border-width: 0px 1px 0px 1px;
      z-index: 2;
      position: relative;
      padding: 0 15px;
      background-color: #fff; }
      @media (max-width: 1023.98px) {
        header nav .header-logo {
          flex: 0 0 150px; } }
      @media (max-width: 575.98px) {
        header nav .header-logo {
          flex: 0 0 80px;
          padding: 0 5px; } }
    header nav .header-main-menu {
      flex: 1;
      border-right: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column; }
      header nav .header-main-menu .header-top-menu {
        height: 70px; }
        header nav .header-main-menu .header-top-menu::before {
          content: '';
          position: absolute;
          top: 70px;
          left: 0;
          z-index: -1;
          height: 1px;
          width: 100vw;
          background-color: #e5e5e5; }
          @media (max-width: 1023.98px) {
            header nav .header-main-menu .header-top-menu::before {
              display: none; } }
        header nav .header-main-menu .header-top-menu .wrap-search {
          padding: 13px;
          flex: 0 0 47%;
          border-right: 1px solid #e5e5e5; }
          @media (max-width: 1279.98px) {
            header nav .header-main-menu .header-top-menu .wrap-search {
              flex: 0 0 25%; } }
          @media (max-width: 1023.98px) {
            header nav .header-main-menu .header-top-menu .wrap-search {
              flex: 1; } }
          @media (max-width: 575.98px) {
            header nav .header-main-menu .header-top-menu .wrap-search {
              padding: 10px;
              display: flex;
              align-items: center; } }
          header nav .header-main-menu .header-top-menu .wrap-search .searchbox {
            display: flex;
            align-items: center;
            position: relative; }
            header nav .header-main-menu .header-top-menu .wrap-search .searchbox [class*='Module'] {
              width: 100%; }
            @media (max-width: 575.98px) {
              header nav .header-main-menu .header-top-menu .wrap-search .searchbox {
                justify-content: space-between;
                width: 100%; } }
            header nav .header-main-menu .header-top-menu .wrap-search .searchbox input {
              border: none;
              outline: none;
              color: #7f8084;
              font-size: 0.875rem;
              padding-left: 31px;
              flex: 1; }
              @media (max-width: 1279.98px) {
                header nav .header-main-menu .header-top-menu .wrap-search .searchbox input {
                  padding-left: 0; } }
              @media (max-width: 575.98px) {
                header nav .header-main-menu .header-top-menu .wrap-search .searchbox input {
                  font-size: 0.75rem;
                  max-width: 100px; } }
            header nav .header-main-menu .header-top-menu .wrap-search .searchbox button {
              width: 46px;
              height: 46px;
              flex-shrink: 1;
              border-radius: 4px;
              background-color: #009035;
              transition: .3s all ease-in-out;
              border: none;
              outline: none;
              transform: translateY(-2px); }
              @media (max-width: 575.98px) {
                header nav .header-main-menu .header-top-menu .wrap-search .searchbox button {
                  width: 35px;
                  height: 35px;
                  transform: none; } }
              header nav .header-main-menu .header-top-menu .wrap-search .searchbox button em {
                color: white;
                font-size: 1.5rem; }
                @media (max-width: 575.98px) {
                  header nav .header-main-menu .header-top-menu .wrap-search .searchbox button em {
                    font-size: 1.125rem; } }
              header nav .header-main-menu .header-top-menu .wrap-search .searchbox button:hover {
                filter: brightness(0.9); }
        header nav .header-main-menu .header-top-menu .list-main-menu {
          flex: 1; }
          header nav .header-main-menu .header-top-menu .list-main-menu [class*='Module'] {
            width: 100%;
            height: 100%; }
          @media (max-width: 1023.98px) {
            header nav .header-main-menu .header-top-menu .list-main-menu {
              display: none; } }
          header nav .header-main-menu .header-top-menu .list-main-menu ul {
            width: 100%;
            height: 100%; }
            header nav .header-main-menu .header-top-menu .list-main-menu ul li {
              border-right: 1px solid #e5e5e5;
              flex: 1;
              transition: .3s all ease-in-out; }
              header nav .header-main-menu .header-top-menu .list-main-menu ul li.active a span {
                color: #009035; }
              header nav .header-main-menu .header-top-menu .list-main-menu ul li:hover a span {
                color: #009035; }
              header nav .header-main-menu .header-top-menu .list-main-menu ul li a {
                height: 100%; }
                header nav .header-main-menu .header-top-menu .list-main-menu ul li a img {
                  max-height: 24px;
                  transition: .3s all ease-in-out; }
                header nav .header-main-menu .header-top-menu .list-main-menu ul li a span {
                  margin-top: 5px;
                  font-size: 12px;
                  transition: .3s all ease-in-out;
                  white-space: nowrap; }
        header nav .header-main-menu .header-top-menu .wrap-cart {
          position: relative; }
          @media (max-width: 1279.98px) {
            header nav .header-main-menu .header-top-menu .wrap-cart {
              margin-left: auto; } }
          header nav .header-main-menu .header-top-menu .wrap-cart .cart {
            padding: 0 20px; }
            @media (max-width: 575.98px) {
              header nav .header-main-menu .header-top-menu .wrap-cart .cart {
                padding: 0 10px; } }
            header nav .header-main-menu .header-top-menu .wrap-cart .cart .quantity {
              position: absolute;
              color: #ffffff;
              font-size: 0.75rem;
              font-weight: 400;
              text-align: center;
              width: 20px;
              height: 20px;
              background-color: #e30016;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 5px;
              right: 20px;
              line-height: 1; }
              @media (max-width: 575.98px) {
                header nav .header-main-menu .header-top-menu .wrap-cart .cart .quantity {
                  width: 16px;
                  height: 16px;
                  font-size: 0.625rem;
                  top: 3px;
                  right: 15px; } }
            header nav .header-main-menu .header-top-menu .wrap-cart .cart img {
              max-height: 24px; }
              @media (max-width: 575.98px) {
                header nav .header-main-menu .header-top-menu .wrap-cart .cart img {
                  max-height: 18px; } }
            header nav .header-main-menu .header-top-menu .wrap-cart .cart span {
              margin-top: 5px;
              display: inline-block;
              white-space: nowrap;
              font-size: 0.75rem; }
      header nav .header-main-menu .header-bottom-menu {
        display: flex;
        align-items: center;
        flex: 1; }
        @media (min-width: 1024px) {
          header nav .header-main-menu .header-bottom-menu {
            padding-left: 1.875rem; } }
        @media (min-width: 1280px) {
          header nav .header-main-menu .header-bottom-menu {
            padding-left: 2.8125rem; } }
        @media (max-width: 1023.98px) {
          header nav .header-main-menu .header-bottom-menu {
            display: none; } }
        header nav .header-main-menu .header-bottom-menu ul {
          display: flex;
          align-items: center; }
          header nav .header-main-menu .header-bottom-menu ul li + li {
            margin-left: 0.9375rem; }
            @media (min-width: 1280px) {
              header nav .header-main-menu .header-bottom-menu ul li + li {
                margin-left: 2.625rem; } }
          header nav .header-main-menu .header-bottom-menu ul li a {
            color: #111111;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            position: relative;
            transition: color 0.4s cubic-bezier(0.58, 0.3, 0.005, 1); }
            header nav .header-main-menu .header-bottom-menu ul li a:hover {
              color: #009035; }
              header nav .header-main-menu .header-bottom-menu ul li a:hover::after {
                transform: scale3d(1, 1, 1);
                transition: transform 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
                transform-origin: 0 50%; }
            header nav .header-main-menu .header-bottom-menu ul li a::after {
              content: "";
              height: 1px;
              width: 100%;
              position: absolute;
              bottom: -2px;
              left: 0;
              background-color: #009035;
              transform: scale3d(0, 1, 1);
              transition: transform 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
              transform-origin: 100% 50%; }
            @media (max-width: 1279.98px) {
              header nav .header-main-menu .header-bottom-menu ul li a {
                font-size: 0.8125rem; } }

.menu-mobile-main {
  position: fixed;
  top: 71px;
  right: 0;
  background-color: #fff;
  border-left: 1px solid #e5e5e5;
  height: 100vh;
  z-index: 9;
  width: 350px;
  padding: 15px;
  transition: .3s all ease-in-out;
  transform: translateX(100%);
  overflow: auto;
  will-change: transform; }
  @media (max-width: 575.98px) {
    .menu-mobile-main {
      width: 100vw;
      top: 61px; } }
  .menu-mobile-main.isOpen {
    transform: none; }
  @media (min-width: 1024px) {
    .menu-mobile-main {
      display: none; } }
  .menu-mobile-main .menu-product-main {
    display: flex;
    align-items: center;
    background-color: #009035;
    color: white;
    padding: 10px 15px;
    justify-content: space-between;
    transition: .3s all ease-in-out;
    border-radius: 4px;
    margin-bottom: 15px; }
    .menu-mobile-main .menu-product-main em {
      transition: .3s all ease-in-out; }
    .menu-mobile-main .menu-product-main.active {
      background-color: #047c30; }
      .menu-mobile-main .menu-product-main.active em {
        transform: rotate(45deg); }
    .menu-mobile-main .menu-product-main span {
      font-size: 16px;
      transition: .3s all ease-in-out; }
  .menu-mobile-main .list-main-menu [class*='Module'] {
    width: 100%; }
  .menu-mobile-main .list-main-menu.product-list {
    display: none; }
  .menu-mobile-main .list-main-menu ul {
    flex-direction: column;
    width: 100%; }
    .menu-mobile-main .list-main-menu ul li.active a {
      color: #009035; }
    .menu-mobile-main .list-main-menu ul li a {
      justify-content: start;
      width: 100%;
      padding: 15px 0px;
      border: 1px solid #e5e5e5;
      border-width: 1px 0px 0px 0px;
      flex-direction: row;
      font-size: 16px;
      display: flex; }
      .menu-mobile-main .list-main-menu ul li a span {
        font-size: 16px; }
      .menu-mobile-main .list-main-menu ul li a img {
        margin-right: 15px;
        display: none; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.3);
  transition: .3s all ease-in-out;
  opacity: 0;
  visibility: visible; }
  .overlay.active {
    opacity: 1;
    pointer-events: all;
    visibility: visible; }
