.wrap-form
	span
		&.fa-exclamation-triangle
			font-size: r(14px)
			color: red
			&::before
				margin-right: 5px
	.form-row
		display: flex
		margin: 0 -15px
		@media screen and ( max-width: 768px )
			flex-wrap: wrap
		.form-group
			flex: 0 0 50%
			padding: 0 15px
			@media screen and ( max-width: 768px )
				flex: 0 0 100%
			input[type="text"]
				width: 100%
span.fa-exclamation-triangle
	font-weight: 400!important
	font-style: italic
	color: red
	font-family: 'Philosopher', sans-serif!important
	font-size: .9rem
	&::before
		display: none!important
// form capcha
.frm-btn-reset
	display: none!important
.frm-captcha
	display: flex
	float: left
	width: calc(100% - 233px)
	// flex: 1
	padding-right: 20px
	@media screen and ( max-width: 1280px )
		width: calc(100% - 110px)
	@media (max-width: 767.98px)
		float: none
		padding-right: 0
		flex: 1
		width: 100%
	@media screen and ( max-width: 767.67px )
		flex-direction: column
	.label
		display: none!important
	.frm-captcha-input
		order: 2
		flex: 1
		@media screen and ( max-width: 767.67px )
			order: 1
			margin-bottom: 20px
	.RadCaptcha_Default
		position: relative
		width: auto !important
		@media screen and ( max-width: 767.67px )
			flex: 0 0 100%
			order: 2

	#ctl00_mainContent_ctl01_captcha_ctl00
		position: absolute
		top: 120%
		color: red
	#ctl00_mainContent_ctl01_txtCaptcha
		width: 100%
		height: 50px
		margin-top: 0
		max-width: 291px
		border: 1px solid rgba(227, 236, 240, 0.5)
		background-color: #F5F5F5
		transition: .3s all  ease-in-out
		&:hover, &:focus
			border: 1px solid $main

		@media screen and ( max-width: 767.67px )
			width: 100%

	#ctl00_mainContent_ctl01_captcha_ctl01
		display: flex
		align-items: center
		img
			width: 158px!important
			height: 50px!important
			border: 1px solid rgba(227, 236, 240, 0.5)
			box-sizing: border-box
			border-radius: 8px
		.rcRefreshImage
			font-size: 0
			margin-left: 10px
			height: auto!important
			margin-right: 10px
			&::after
				display: none!important
			&::before
				content: "replay"
				color: #0F8DC9
				font-weight: 400
				font-family: 'Material Icons'
				font-weight: normal
				font-style: normal
				font-size: 30px
				line-height: 1
.subscribefrm
	.fa-exclamation-triangle
		top: 110%!important
		right: 0!important
		width: auto!important
		text-indent: 0!important
		&::after
			display: none
